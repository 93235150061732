import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
   deleteKeyword,
   deleteRoom,
   updateNavigation,
} from "../../services/servicesCrud";
import {
   subscribeToCollection,
   subscribeToDocument,
   subscribeToRooms,
} from "../../services/subscriptions";
import { useRoomsStore } from "../../store/appStore";
import { EnumTarget } from "../../types/types";
import KeywordFormAdd from "./KeywordFormAdd";
import QuestionForm from "./QuestionForm";
import RoomFormAdd from "./RoomFormAdd";
import GateSuperAdminLogin from "../Gate/GateSuperAdminLogin";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";

export default function Rooms() {
   // handle users auth.
   const [localuser, setLocaluser] = useState<any>(null);
   const setSuperUser = useRoomsStore((state) => state.setSuperUser);
   onAuthStateChanged(auth, (user) => {
      console.log(user);
      if (user && !user.isAnonymous) setLocaluser(user);
      else setLocaluser(null);
   });

   const handleLogout = () => {
      signOut(auth)
         .then(() => {
            // Sign-out successful.
            console.log("Signed out successfully");
            setSuperUser(null);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const rooms = useRoomsStore((state) => state.rooms);
   const questions = useRoomsStore((state) => state.questions);
   const setQuestions = useRoomsStore((state) => state.setQuestions);
   const keywords = useRoomsStore((state) => state.keywords);
   const keywordsOper = useRoomsStore((state) => state.keywordsOper);
   const keywordsVisited = useRoomsStore((state) => state.keywordsVisited);
   const setRooms = useRoomsStore((state) => state.setRooms);
   const setKeywords = useRoomsStore((state) => state.setKeywords);
   const setKeywordsOper = useRoomsStore((state) => state.setKeywordsOper);
   const setKeywordsVisited = useRoomsStore(
      (state) => state.setKeywordsVisited
   );

   const handleQuestionsSave = (questions: any) => {
      if (questions && questions.feedback) {
         setQuestions(questions.feedback);
      }
   };

   const saveNavigationState = (navigation: any) => {
      if (navigation) {
         console.log("navigation: ", navigation);
      }
   };

   const changeNavigationState = (e: any) => {
      console.log(e.target.checked);
      updateNavigation(e.target.checked);
   };

   const handleDeleteRoom = (id: string) => {
      const confirmation = window.confirm("Are you sure you want to delete?");
      if (confirmation) deleteRoom(id);
   };

   useEffect(() => {
      subscribeToRooms({ callback: setRooms });
      subscribeToCollection({
         collectionName: "Answers",
         docId: EnumTarget.theather,
         subcollectionName: "Keywords",
         callback: setKeywords,
      });
      subscribeToCollection({
         collectionName: "Answers",
         docId: EnumTarget.oper,
         subcollectionName: "Keywords",
         callback: setKeywordsOper,
      });
      subscribeToCollection({
         collectionName: "Answers",
         docId: EnumTarget.visited,
         subcollectionName: "Keywords",
         callback: setKeywordsVisited,
      });

      subscribeToDocument({
         collectionName: "Questions",
         documentName: "Feedback",
         callback: handleQuestionsSave,
      });

      subscribeToDocument({
         collectionName: "settings",
         documentName: "navigation",
         callback: saveNavigationState,
      });
   }, []);

   if (!localuser) return <GateSuperAdminLogin />;

   return (
      <div className="bg-gradient pt-5 pb-5 mh-100vh">
         <div className="container">
            <div className="row">
               <div className="col-md-8">
                  <p className="mb-1">Eingeloggt als:</p>
                  <h1 className="mb-3">{localuser?.email}</h1>
               </div>
               <div className="col-md-4 text-right">
                  <button className="btn btn--2 mb-5" onClick={handleLogout}>
                     ausloggen
                  </button>
               </div>
            </div>

            <div className="bg-transparent p-5 border-rounded mb-5">
               <div className="row">
                  <div className="col-md-4">
                     <h2>Navigation Visibility</h2>
                     <div className="form-check ps-0">
                        <input
                           className="form-check-input"
                           type="checkbox"
                           id="check1"
                           onChange={changeNavigationState}
                        />
                        <label htmlFor="check1" className="form-check-label">
                           Show Navigation
                        </label>
                     </div>
                  </div>
               </div>
            </div>

            <div className="bg-transparent p-5 border-rounded mb-5">
               <div className="row">
                  <div className="col-md-4">
                     <h2>Add Room</h2>
                     <div className="pt-4">
                        <RoomFormAdd />
                     </div>
                  </div>
                  <div className="col-md-8 ps-5">
                     <h2>Existing Rooms</h2>
                     <div className="pt-4">
                        {rooms.map((room) => (
                           <div className="row" key={room.id}>
                              <div className="col-md-3">
                                 <button
                                    onClick={() => {
                                       handleDeleteRoom(room.id);
                                    }}
                                    className="btn btn--1 w-100 btn--delete"
                                 >
                                    {room.name}
                                 </button>
                              </div>
                              <div className="col-md-9">
                                 <Link to={`/student/${room.id}`}>
                                    <span className="btn btn--1 bg-primary me-2">
                                       login
                                    </span>
                                 </Link>
                                 <Link to={`/room/${room.id}`}>
                                    <span className="btn btn--1 bg-primary me-2">
                                       room
                                    </span>
                                 </Link>
                                 <Link to={`/gallery/${room.id}`}>
                                    <span className="btn btn--1 bg-primary">
                                       gallery
                                    </span>
                                 </Link>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
            <div className="bg-transparent p-5 border-rounded mb-5">
               <div className="row">
                  <div className="col-md-4">
                     <h2>Theater</h2>
                     <div className="pt-4">
                        <KeywordFormAdd target={EnumTarget.theather} />
                     </div>
                  </div>
                  <div className="col-md-8 ps-5">
                     <h2>
                        Woran denkst du, wenn du &bdquo;Theater&ldquo; hörst?
                     </h2>
                     <div className="pt-4">
                        {keywords.map((word, index) => (
                           <button
                              key={index}
                              className="btn btn--1 btn--delete_ bg-third me-3 mb-3"
                              type="button"
                              // onClick={() =>
                              //    deleteKeyword(EnumTarget.theather, word.id)
                              // }
                           >
                              {word.name} - {word.count}
                           </button>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
            <div className="bg-transparent p-5 border-rounded mb-5">
               <div className="row">
                  <div className="col-md-4">
                     <h2>Oper</h2>
                     <div className="pt-4">
                        <KeywordFormAdd target={EnumTarget.oper} />
                     </div>
                  </div>
                  <div className="col-md-8 ps-5">
                     <h2>Woran denkst du, wenn du &bdquo;Oper&ldquo; hörst?</h2>
                     <div className="pt-4">
                        {keywordsOper.map((word, index) => (
                           <button
                              key={index}
                              className="btn btn--1 btn--delete_ bg-third me-3 mb-3"
                              type="button"
                              // onClick={() =>
                              //    deleteKeyword(EnumTarget.oper, word.id)
                              // }
                           >
                              {word.name} - {word.count}
                           </button>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
            <div className="bg-transparent p-5 border-rounded">
               <div className="row">
                  <div className="col-md-4">
                     <h2>Wie oft?</h2>
                     <div className="pt-4">
                        <KeywordFormAdd target={EnumTarget.visited} />
                     </div>
                  </div>
                  <div className="col-md-8 ps-5">
                     <h2>
                        Und warst du denn schon im Theater oder in der Oper?
                        <br />
                        Wenn ja, wie oft?
                     </h2>
                     <div className="pt-4">
                        {keywordsVisited.map((word, index) => (
                           <button
                              key={index}
                              className="btn btn--1 btn--delete_ bg-third me-3 mb-3"
                              type="button"
                              // onClick={() =>
                              //    deleteKeyword(EnumTarget.visited, word.id)
                              // }
                           >
                              {word.name} - {word.count}
                           </button>
                        ))}
                     </div>
                  </div>
               </div>
            </div>

            <div className="bg-transparent p-5 border-rounded mt-5">
               <h2>Feedback Fragen</h2>
               <div className="pt-4">
                  {questions.map((question, index) => (
                     <QuestionForm question={question} questionIndex={index} />
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}
