import Modal from "../UI/Modal";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getCollection, getDocument } from "../../services/servicesRead";
import { subscribeToRooms } from "../../services/subscriptions";
import { StudentInterface } from "../../types/types";
import StickyNavigation from "../UI/StickyNavigation";
import PageServiceNav from "../Page/PageServiceNav";

export default function Gallery() {
   const [students, setStudents] = useState<StudentInterface[]>([]);
   const [room, setRoom] = useState<any>(null);
   const [activeImage, setActiveImage] = useState<string>("");
   const [modal, setModal] = useState(false);
   const roomId = useParams().roomId;

   useEffect(() => {
      console.log("tried to fetch roomID from params", roomId);
      if (roomId) {
         getDocument({ collectionName: "Rooms", docId: roomId }).then(
            (data: any) => {
               setRoom({
                  ...data,
                  id: roomId,
               });
            }
         );
      } else {
         //in case we are in the freelancers mode and not klassenraum
         getCollection({ collectionName: "Freelancers" }).then((data: any) => {
            setStudents([...data]);
         });
      }
   }, [roomId]);

   useEffect(() => {
      if (room && roomId) {
         subscribeToRooms({
            roomId: room.id,
            callback: setStudents,
         });
      }
   }, [room]);

   const closeModal = (modalClose: any) => {
      modalClose(false);
   };

   return (
      <>
         <div className="pb-5 mh-100vh gallery bg-white pt--100">
            <StickyNavigation />
            <div className="container">
               <h1 className="strong text-uppercase">
                  {room ? room.name : "Gallery"}
               </h1>
               <div className="row">
                  {students?.map((student) => {
                     if (!student.result) return null;
                     return (
                        <div
                           key={student?.id}
                           className="col-md-6 col-lg-4 mb-3"
                        >
                           <div className="d-flex flex-column justify-content-start">
                              <div
                                 className="d-flex justify-content-center my-3 cursor-pointer bg-seamless"
                                 onClick={() => {
                                    setModal(true);
                                    setActiveImage(
                                       student?.result ||
                                          "https://fakeimg.pl/1400x900"
                                    );
                                 }}
                              >
                                 {student?.result && (
                                    <video
                                       controls
                                       src={student.result}
                                    ></video>
                                 )}
                                 {/* <img
                                 className="gallery__image"
                                 src={
                                    student?.result
                                       ? student.result
                                       : "https://fakeimg.pl/1400x900"
                                 }
                                 alt={student?.name || ""}
                              /> */}
                              </div>
                              <h3 className="mb-2 text-uppercase strong d-flex align-content-center justify-content-between">
                                 {student.publish_with_name
                                    ? student?.name
                                    : "Anonymous"}

                                 {student.result && (
                                    <a
                                       target="_blank"
                                       href={student.result}
                                       download="my_black_magic_box.mp4"
                                       className="btn btn--3"
                                    >
                                       download
                                    </a>
                                 )}
                              </h3>
                              {/*<div className='text-uppercase'>TODO</div>*/}
                              {/*<div className='mb-5 text-uppercase'>TODO</div>*/}
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
            <div className="mt-5">
               <PageServiceNav />
            </div>
         </div>

         <Modal show={modal} onClose={() => closeModal(setModal)}>
            <video controls src={activeImage}></video>
         </Modal>
      </>
   );
}
