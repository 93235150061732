import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GUI } from "dat.gui";

export const addCamera = (state) => {
   // cam
   //--------------------------------------------------------
   state.camera = new THREE.PerspectiveCamera(
      45,
      state.wrapper.offsetWidth / state.wrapper.offsetHeight,
      0.1,
      1000
   );
   state.orbit = new OrbitControls(state.camera, state.renderer.domElement);
   state.orbit.maxDistance = 120;
   state.orbit.minDistance = 5;
   state.orbit.maxPolarAngle = Math.PI / 2.1;

   state.camera.position.set(0, 17, 27);
   // state.camera.lookAt(new THREE.Vector3(0, 20, 0));

   // const gui = new GUI();
   // gui.add(state.camera.position, "x", -100, 100).onChange(() => {
   //    // state.camera.lookAt(new THREE.Vector3(0, 0, 0));
   // });
   // gui.add(state.camera.position, "y", -100, 100).onChange(() => {
   //    // state.camera.lookAt(new THREE.Vector3(0, 0, 0));
   // });
   // gui.add(state.camera.position, "z", -100, 100).onChange(() => {
   //    // state.camera.lookAt(new THREE.Vector3(0, 0, 0));
   // });
   // gui.add(state.camera.lookAt, "z", -100, 100).onChange((value) => {
   //    // state.camera.lookAt(new THREE.Vector3(0, 20, 0));
   // });

   state.orbit.update();
};
