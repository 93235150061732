import { useStudentStore } from "../../store/appStore";

export default function SliderDevNav({ children }: any) {
   const setSliderIndex = useStudentStore((state) => state.setSliderIndex);
   const sliderIndex = useStudentStore((state) => state.sliderIndex);
   return (
      <div className="slider__nav">
         <ul className="list list--reset list--inline">
            {children.map((child: any, index: number) => (
               <li className="me-2" key={index}>
                  <button
                     onClick={() => setSliderIndex(index + 1)}
                     className={
                        "btn btn--4" +
                        (sliderIndex === index + 1 ? " bg-primary" : "")
                     }
                  >
                     {index}
                  </button>
               </li>
            ))}
         </ul>
      </div>
   );
}
