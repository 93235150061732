import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateStudent } from "../../services/servicesCrud";
import { getDocument } from "../../services/servicesRead";
import { useRoomsStore, useStudentStore } from "../../store/appStore";
import SliderStepNav from "../Slider/SliderStepNav";

export default function ArienConfigurator({ index }: any) {
   async function getQuestions() {
      const questions = await getDocument({
         collectionName: "Questions",
         docId: "Feedback",
      });
      setQuestions(questions?.feedback);
   }

   // cycle
   //--------------------------------------------------------
   useEffect(() => {
      getQuestions();
   }, []);

   // methods
   //--------------------------------------------------------
   const { roomId } = useParams();
   const student = useStudentStore((state) => state.student);
   const questions = useRoomsStore((state) => state.questions);
   const setQuestions = useRoomsStore((state) => state.setQuestions);
   const questionIndex = index;
   const [answer, setAnswer] = useState("");
   const scene = useStudentStore((state) => state.chosenScene);
   async function saveAnswer() {
      if (!answer) {
         alert("wir brauchen deine Antwort");
         return false;
      }

      const studentData = await getDocument({
         collectionName: roomId ? "Rooms" : "Freelancers",
         docId: roomId ? roomId : student?.id,
         subcollectionName: roomId ? "Students" : null,
         subdocId: roomId ? student?.id : null,
      });

      console.log(studentData);

      if (!studentData || !student) return;
      let newData: any;
      const payload = {
         question: questions[questionIndex],
         answer: answer,
         questionIndex: questionIndex,
         scene: scene?.title,
      };

      if (!studentData.questions || studentData.questions.length === 0) {
         newData = {
            ...studentData,
            questions: [payload],
         };
      } else if (studentData.questions.length >= questionIndex + 1) {
         newData = { ...studentData };
         newData.questions[questionIndex] = payload;
      } else {
         newData = {
            ...studentData,
            questions: [...studentData.questions, payload],
         };
      }
      console.log(newData);
      updateStudent(newData, roomId, student.id);
   }
   return (
      <div
         className={
            "text-white position-relative mw-100vw d-flex align-items-center justify-content-center " +
            scene?.background
         }
      >
         <div className="container">
            <div className="row align-items-center justify-content-center mh-100vh">
               <div className="col-3 text-center">
                  <img
                     className="mb-5 m-auto"
                     width={300}
                     src={scene?.image}
                     alt=""
                  />
               </div>
               <h4 className="h3 mb-3 mt-auto text-center">
                  {questions[questionIndex]}
               </h4>
               <div className="row mb-2 align-items-center justify-content-center">
                  <div className="col-md-8">
                     <textarea
                        className="text-base w-100"
                        placeholder="deine Antwort.."
                        value={answer}
                        id=""
                        onChange={(e) => setAnswer(e.target.value)}
                     />
                  </div>
               </div>
               <SliderStepNav onNext={saveAnswer} />
               <p className="mt-3 mb-4 text-center">{scene?.meta}</p>
            </div>
         </div>
      </div>
   );
}
