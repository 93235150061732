import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";

export default function Slide0End() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const setAnimationVolume = useStudentStore(
      (state) => state.setAnimationVolume
   );
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);

   useEffect(() => {
      setInProp(true);
      setAnimationVolume(0.2);

      setTimeout(() => {
         nextSlide();
      }, 7000);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="container">
               <div className="row">
                  <div className="col-lg-8 m-auto flex-wrap">
                     <h4 className="h2 text-white text-center">
                        „Black Box“ = ein multifunktionaler, schwarzer
                        Bühnenraum
                     </h4>
                     {/* <div className="text-white">
                        *Rene Thom - franzosischer Mathematiker und Philosph
                     </div> */}
                  </div>
               </div>
            </div>
         </div>
      </CSSTransition>
   );
}
