import gsap from "gsap";

export const moveClone = (direction, clone, state) => {
   if (!clone) return;
   let x = clone.position.x;
   if (direction === "left") x = clone.position.x - 1;
   if (direction === "right") x = clone.position.x + 1;

   let z = clone.position.z;
   if (direction === "front") z = clone.position.z + 1;
   if (direction === "back") z = clone.position.z - 1;

   //if we update the y we have to trick the bouncing animation
   // it takes place on the y axis ;) so first disable active clone
   let y = clone.position.y;
   state.activeClone = null;
   if (direction === "top") y = clone.position.y + 1;
   if (direction === "down") y = clone.position.y - 1;

   gsap.to(clone.position, {
      x: x,
      y: y,
      z: z,
      duration: 0.2,
   });

   setTimeout(() => {
      // update clones y
      clone.position.y = y;
      state.reference[clone.uuid].position.y = y;
      // set active clone again after gsap is finished
      state.activeClone = clone;
      console.log("updated position", clone.position);
      console.log(state.activeClone);
   }, 250);
};
