export const getClonesYPositionByName = (name, clone, reference) => {
   const { uuid } = clone;

   //already on scene
   const existsOnScene = reference[uuid];

   let y =
      existsOnScene && existsOnScene.position.y !== 0
         ? reference[uuid].position.y
         : 0;
   if (name === "tanne_mit_blumen" || name === "tanne")
      y =
         existsOnScene && existsOnScene.position.y !== 18
            ? existsOnScene.position.y
            : 18;
   if (name === "chameleon")
      y =
         existsOnScene && existsOnScene.position.y !== 5
            ? existsOnScene.position.y
            : 5;
   if (name === "chair")
      y =
         existsOnScene && existsOnScene.position.y !== 4.2
            ? existsOnScene.position.y
            : 4.2;
   if (name === "3d_teppich")
      y =
         existsOnScene && existsOnScene.position.y !== 0.3
            ? existsOnScene.position.y
            : 0.3;
   if (isSpecialCharacter(name)) {
      y =
         existsOnScene && existsOnScene.position.y !== 7.5
            ? existsOnScene.position.y
            : 7.5;
   }
   if (isInAlphabetArray(name)) {
      y =
         existsOnScene && existsOnScene.position.y !== 6.5
            ? existsOnScene.position.y
            : 6.5;
   }
   console.log(y);
   return y;
};

const isInAlphabetArray = (string) => {
   const alphabetArray = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "r",
      "s",
      "t",
      "u",
      "und",
      "v",
      "w",
      "x",
      "y",
      "z",
      "ausrufezeichen",
      "fragezeichen",
      "euro",
      "neon_a",
      "neon_b",
      "neon_c",
      "neon_d",
      "neon_e",
      "neon_f",
      "neon_g",
      "neon_h",
      "neon_i",
      "neon_j",
      "neon_k",
      "neon_l",
      "neon_m",
      "neon_n",
      "neon_o",
      "neon_p",
      "neon_r",
      "neon_s",
      "neon_t",
      "neon_u",
      "neon_v",
      "neon_w",
      "neon_x",
      "neon_y",
      "neon_z",
      "neon_ausrufezeichen",
      "neon_fragezeichen",
      "neon_punkt",
      "avantgarde_euro",
      "avantgarde_raute",
      "avantgarde_undzeichen",
      "avantgarde_sternchen",
      "avantgarde_fragezeichen",
      "avantgarde_euro",
      "avantgarde_aet",
   ];
   return alphabetArray.some((letter) => letter === string);
};

const isSpecialCharacter = (string) => {
   const specialCharacters = ["ae", "oe", "ue"];
   return specialCharacters.some((letter) => letter === string);
};
