import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import ReactSlider from "react-slider";

export default function Slide28fundusMood() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const scene = useStudentStore((state) => state.chosenScene);
   const setFundus = useStudentStore((state) => state.setFundus);
   const fundus = useStudentStore((state) => state.fundus);
   const maxLight = 9;
   const [slider, setSlider] = useState(50);

   useEffect(() => {
      setInProp(true);
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   function mapNumberToColor(num: any) {
      console.log(num);
      return colors[num];
   }

   const colors = [
      "#d5864d",
      "#d3ac43",
      "#b3933a",
      "#b29a59",
      "#b7a678",
      "#e6ddc4",
      "#7e786a",
      "#586974",
      "#5e90af",
      "#0d6ea9",
   ];

   const handleSliderChange = (value: any) => {
      setSlider(value);
      setFundus({
         ...fundus,
         mood: mapNumberToColor(value),
      });
   };

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div
            ref={nodeRef}
            className="mh-100vh d-flex justify-content-center flex-column text-white text-center"
         >
            <img
               className="mb-4 mt-auto m-auto mb-0"
               width={300}
               src={scene?.image}
               alt=""
            />
            <h2 className="h3 mt-5">
               Welche Farbe hat für dich die Musik bzw. der Text?
            </h2>
            <div className="mb-5 mt-5">
               <ReactSlider
                  className="customSlider"
                  thumbClassName="customSlider-thumb"
                  trackClassName="customSlider-track"
                  markClassName="customSlider-mark"
                  min={0}
                  max={maxLight}
                  value={slider}
                  step={1}
                  onChange={(value) => handleSliderChange(value)}
               />

               <div className="d-flex align-content-around justify-content-between mt-5 pt-3 h4 mb-0">
                  <span>Warm</span> <span></span> <span>Kalt</span>
               </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
               <button onClick={nextSlide} className="btn btn--circle">
                  Weiter
               </button>
            </div>
            <h4 className="h3 mb-2 mt-auto">{scene?.title}</h4>
            <p className="mb-5">{scene?.meta}</p>
         </div>
      </CSSTransition>
   );
}
