import { useState } from "react";
import Modal from "../UI/Modal";
import { pages } from "./pagesContent";

const PageServiceNavModal = () => {
   const [modal, setModal] = useState(false);
   const [content, setContent] = useState(null);
   const [headline, setHeadline] = useState(null);
   const handleClick = (page: any) => {
      setModal(true);
      setContent(page.content);
      setHeadline(page.title);
   };
   return (
      <>
         <div className="service hmicro">
            {Object.keys(pages).map((keyName, i) => {
               return (
                  <button
                     onClick={() => handleClick(pages[keyName])}
                     type="button"
                     className="me-2"
                  >
                     {pages[keyName].title}
                  </button>
               );
            })}
         </div>
         <Modal
            type="modal--narrow"
            show={modal}
            onClose={() => setModal(false)}
         >
            <div>
               <h2>{headline}</h2>
               {content && (
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
               )}
            </div>
         </Modal>
      </>
   );
};

export default PageServiceNavModal;
