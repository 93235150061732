import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import * as THREE from "three";
import { addCamera } from "../helpers/camera";
import { DragMode } from "../helpers/dragMode";
import { EditMode } from "../helpers/editMode";
import { addDirectionalLight, addSpotLight } from "../helpers/light";
import { createHighlightMash, createPlaneMash } from "../helpers/plane";
import { addListeners } from "../listeners/listeners";
import gsap from "gsap";
import { handleTheathre } from "../theatre/handleTheatre";
import { loadAssets } from "./loadAssets";
import { roofToggle, sceneSneakModeToggle } from "../scene/views";
import { LIGHT_TYPES } from "../helpers/enums";
import { animateActiveClone } from "../helpers/animations";

const afterModelsLoaded = new Event("afterModelsLoaded");

export async function init(BEStage) {
   const { state } = BEStage;

   console.dir(state.wrapper);
   console.log(state.wrapper.offsetHeight);
   state.renderer.setSize(
      state.wrapper.offsetHeight,
      state.wrapper.offsetWidth
   );
   state.wrapper.appendChild(state.renderer.domElement);
   state.renderer.setClearColor(0x2a2a2a);

   //*******************************************************
   // Camera
   //*******************************************************

   addCamera(state);

   //*******************************************************
   // load objects
   //*******************************************************

   await loadAssets(state);

   // const spot = await loadMesh({
   //    url: `${path}/assets/box_panel_spot_light/scene.gltf`,
   //    meshName: "spotlight_lamp",
   //    destination: "library",
   //    state: state,
   // });

   BEStage.dispatchEvent(afterModelsLoaded);

   addListeners(state);

   //*******************************************************
   // THEATHRE
   //*******************************************************

   handleTheathre(state);

   //*******************************************************
   // Light
   //*******************************************************

   addSpotLight({
      lamp: false,
      helper: false,
      position: { x: -6, y: 56, z: 23 },
      target: { x: 0, y: -7, z: -7 },
      intensity: 13,
      color: "#ffffff",
      angle: 0.17,
      state: state,
      name: LIGHT_TYPES.SPOTLIGHT_TOP_LIGHT,
      visible: false,
      label: "SpotLight Top Right",
   });

   addSpotLight({
      lamp: false,
      helper: false,
      position: { x: -6, y: 36, z: 60 },
      target: { x: 0, y: 0, z: -29 },
      intensity: 10,
      color: "#ffffff",
      angle: 0.16,
      state: state,
      name: LIGHT_TYPES.SPOTLIGHT_CENTER_LIGHT,
      visible: false,
      label: "SpotLight Center Right",
   });

   addDirectionalLight(state, {
      enableHelper: false,
      x: 0,
      y: 33,
      z: 0,
      intensity: 7.5,
      color: "0xffffff",
      name: LIGHT_TYPES.TOP_LIGHT,
      visible: false,
      label: "Top Light",
   });

   addDirectionalLight(state, {
      enableHelper: false,
      x: 40.5,
      y: -48,
      z: 4,
      intensity: 4,
      color: "0xffffff",
      name: LIGHT_TYPES.RIGHT_LIGHT,
      visible: false,
      label: "Right Light",
   });

   addDirectionalLight(state, {
      enableHelper: false,
      x: -15.9,
      y: 30,
      z: -9,
      intensity: 4,
      color: "0xffffff",
      name: LIGHT_TYPES.LEFT_LIGHT,
      visible: false,
      label: "Left Light",
   });

   addDirectionalLight(state, {
      enableHelper: false,
      x: -6,
      y: 47,
      z: 101.4,
      intensity: 1,
      color: "0xffffff",
      name: LIGHT_TYPES.FRONT_LIGHT,
      visible: true,
      label: "Front Light",
   });

   addDirectionalLight(state, {
      enableHelper: false,
      x: 40.5,
      y: 47,
      z: -548,
      intensity: 4,
      color: "0xffffff",
      name: LIGHT_TYPES.BACK_LIGHT,
      visible: false,
      label: "Back Light",
   });

   // shadow
   //--------------------------------------------------------
   state.renderer.shadowMap.enabled = true;
   state.renderer.shadowMap.type = THREE.PCFShadowMap; // default THREE.PCFShadowMap

   // Objects
   //--------------------------------------------------------

   const planeMesh = createPlaneMash(state);
   const highlightMesh = createHighlightMash(state);

   // modes
   //--------------------------------------------------------

   // const editMode = new EditMode(state);
   // const dragMode = new DragMode(planeMesh, state);

   // Mouse
   //--------------------------------------------------------

   // const objects = [];
   // const mousePosition = new THREE.Vector2();
   // const raycaster = new THREE.Raycaster();
   // let intersects;

   // window.addEventListener("mousemove", function (e) {
   //    const y = e.clientY - state.wrapper.offsetTop + window.pageYOffset;
   //    mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
   //    mousePosition.y = -(y / window.innerHeight) * 2 + 1;

   //    raycaster.setFromCamera(mousePosition, state.camera);
   //    intersects = raycaster.intersectObject(planeMesh);
   //    if (intersects.length > 0) {
   //       intersects.forEach((element) => {
   //          console.log(element.object.name);
   //       });
   //       const intersect = intersects[0];
   //       const highlightPos = new THREE.Vector3()
   //          .copy(intersect.point)
   //          .floor()
   //          .addScalar(0.5);
   //       highlightMesh.position.set(highlightPos.x, 0, highlightPos.z);

   //       const objectExist = objects.find(function (object) {
   //          return (
   //             object.position.x === highlightMesh.position.x &&
   //             object.position.z === highlightMesh.position.z
   //          );
   //       });

   //       if (!objectExist) highlightMesh.material.color.setHex(0x000000);
   //       else highlightMesh.material.color.setHex(0xff0000);
   //    }
   // });

   // state.wrapper.onpointerdown = function (e) {
   //    console.log(state.mode);
   //    state.mode = getCurrentMode(e, state);

   //    if (state.mode === "DRAG") {
   //       const selectedMesh = pickObject(e);
   //       dragMode.setDraggable(selectedMesh);
   //       console.log(selectedMesh);
   //    }

   //    if (state.mode === "CLONE") {
   //       // addClone();
   //    }
   // };

   // state.wrapper.onpointerup = function (e) {
   //    console.log(state.mode);
   //    if (state.mode === "DRAGGED") {
   //       dragMode.unsetDraggable();
   //    }
   //    if (state.mode === "DRAG") {
   //       const selectedMesh = pickObject(e);
   //       editMode.show(selectedMesh);
   //    }
   //    state.mode = "NONE";
   // };

   function animate(time) {
      const { x, y } = state.camera.position;
      const x_limit = y < 15 ? 22 : 30;

      if (state.view.walls_right && x > x_limit) {
         sceneSneakModeToggle(state, { enable: false, wallsRight: true });
         state.view.walls_right = false;
      } else if (!state.view.walls_right && x <= x_limit) {
         sceneSneakModeToggle(state, { enable: true, wallsRight: true });
         state.view.walls_right = true;
      }

      if (state.view.walls_left && x < -x_limit) {
         sceneSneakModeToggle(state, { enable: false, wallsLeft: true });
         state.view.walls_left = false;
      } else if (!state.view.walls_left && x >= -x_limit) {
         sceneSneakModeToggle(state, { enable: true, wallsLeft: true });
         state.view.walls_left = true;
      }

      if (y > 40 && state.view.roof_visible) {
         roofToggle(false, state);
      } else {
         roofToggle(true, state);
      }

      if (state.activeClone)
         animateActiveClone(
            state.activeClone,
            time,
            state.reference[state.activeClone.uuid].position
         );
      state.renderer.render(state.scene, state.camera);
   }
   state.renderer.setAnimationLoop(animate);

   //*******************************************************
   //
   //*******************************************************

   // const addClone = () => {
   //    const objectExist = objects.find((object) => {
   //       return (
   //          object.position.x === highlightMesh.position.x &&
   //          object.position.z === highlightMesh.position.z
   //       );
   //    });

   //    if (!objectExist) {
   //       if (intersects.length > 0) {
   //          const clone = SkeletonUtils.clone(getCurrentClone(state));
   //          clone.position.copy({
   //             ...highlightMesh.position,
   //             y: 20,
   //          });
   //          state.scene.add(clone);
   //          objects.push(clone);
   //          gsap.to(clone.position, { ...highlightMesh.position, y: 18 });
   //          highlightMesh.material.color.setHex(0xff0000);
   //       }
   //    }
   //    // console.log(scene.children.length);
   // };

   // let pickerPosition = new THREE.Vector2();
   // const pickerRaycaster = new THREE.Raycaster();
   // let pickerIntersections;
   // const pickObject = (e) => {
   //    pickerPosition.x = (e.clientX / window.innerWidth) * 2 - 1;
   //    pickerPosition.y = -(e.clientY / window.innerHeight) * 2 + 1;
   //    pickerRaycaster.setFromCamera(pickerPosition, state.camera);
   //    pickerIntersections = pickerRaycaster.intersectObjects(
   //       state.scene.children
   //    );

   //    for (let i = 0; i < pickerIntersections.length; i++) {
   //       const intersection = pickerIntersections[i];
   //       console.log(intersection);
   //    }

   //    return null;
   // };

   // const getDraggableGroup = (object) => {
   //    if (object.userData.draggable) {
   //       return true;
   //    }
   //    if (!object.parent || object.parent.type === "Scene") return false;
   //    else return isGroupDraggable(object.parent);
   // };
}
