import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { scenes } from "./scenes";
import Modal from "../UI/Modal";
import { useStudentStore } from "../../store/appStore";

export default function Configurator() {
   // Audio
   //--------------------------------------------------------
   let audio: any = useRef();
   const updateCurrentAudio = () => {
      if (swiper && !swiper.destroyed) {
         audio?.current?.pause();
         audio.current = new Audio(scenes[swiper.realIndex].audioPreview);
         audio.current.onended = () => {
            setAudioButtonLabel("Reinhören");
         };
      }
   };
   const [audioButtonLabel, setAudioButtonLabel] =
      useState<string>("Reinhören");

   // swiper
   //--------------------------------------------------------
   const [swiper, setSwiper] = useState<any>(null);
   const [background, setBackground] = useState<any>("bg-gradient-purple");
   useEffect(() => {
      if (swiper) updateCurrentAudio();
   }, [swiper]);

   // methods
   //--------------------------------------------------------
   const [modal, setModal] = useState(false);
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const setChosenScene = useStudentStore((state) => state.setChosenScene);
   const setScene = () => {
      nextSlide();
      setChosenScene(scenes[swiper.realIndex]);
   };
   const handleSwiperSlideChange = (swiper: any) => {
      if (!swiper) return;
      setBackground(scenes[swiper.realIndex].background);
      updateCurrentAudio();
      setAudioButtonLabel("Reinhören");
   };

   const handleAudioState = () => {
      console.log(audio.current.duration);
      // audio.current.play();
      if (audio.current.duration === 0 || audio.current.paused) {
         audio.current.play();
         setAudioButtonLabel("PAUSE");
      } else {
         audio.current.pause();
         setAudioButtonLabel("Reinhören");
      }
   };

   return (
      <div
         className={
            "mh-100vh position-relative mw-100vw d-flex flex-column transition " +
            background
         }
      >
         <div className="mt-auto text-white">
            <Swiper
               keyboard
               loop
               spaceBetween={50}
               slidesPerView={2}
               centeredSlides={true}
               onSlideChange={(swiper) => {
                  handleSwiperSlideChange(swiper);
               }}
               onSwiper={(swiper) => setSwiper(swiper)}
            >
               {scenes.map((scene, index) => (
                  <SwiperSlide key={index}>
                     <div className="d-flex align-items-center flex-column">
                        <img
                           className="mb-4"
                           width={300}
                           src={scene.image}
                           alt=""
                        />
                        <p className="hsmall mb-0">{scene.type}</p>
                        <h1 className="h1 mb-2 text-center text-uppercase">
                           {scene.title}
                        </h1>
                        <p className="text-uppercase">{scene.author}</p>
                     </div>
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
         <div className="mt-auto mb-5 d-flex justify-content-center align-items-center">
            <div className="row mb-5 align-items-center">
               <button
                  type="button"
                  onClick={() => swiper.slidePrev()}
                  className="btn btn--circle btn--circle-micro me-4"
               >
                  prev
               </button>

               <button
                  onClick={setScene}
                  type="button"
                  className="btn btn--circle btn--circle-small me-4"
               >
                  Wählen
               </button>

               <button
                  onClick={() => setModal(true)}
                  type="button"
                  className="btn btn--circle me-4"
               >
                  INHALT
               </button>

               <button
                  type="button"
                  onClick={() => handleAudioState()}
                  className="btn btn--circle btn--circle-small me-4"
               >
                  {audioButtonLabel}
               </button>

               <button
                  type="button"
                  onClick={() => swiper.slideNext()}
                  className="btn btn--circle btn--circle-micro"
               >
                  next
               </button>
            </div>
         </div>
         <Modal
            type="modal--narrow"
            show={modal}
            onClose={() => setModal(false)}
         >
            {swiper && scenes && scenes[swiper.realIndex] && (
               <div>
                  <div className="d-flex justify-content-center">
                     <img
                        className="mb-4"
                        width={200}
                        src={scenes[swiper.realIndex].image}
                        alt=""
                     />
                  </div>
                  <h2
                     className={
                        "h1 mb-2 text-center text-" +
                        scenes[swiper.realIndex].color
                     }
                  >
                     {scenes[swiper.realIndex].title}
                  </h2>
                  <h2
                     className={
                        "h4 mb-5 text-center text-" +
                        scenes[swiper.realIndex].color
                     }
                  >
                     {scenes[swiper.realIndex].author}
                  </h2>
                  <p className="mb-5">{scenes[swiper.realIndex].description}</p>
                  <p className="d-flex ">
                     <button
                        onClick={() => setModal(false)}
                        className={
                           "btn btn--circle btn--circle-small bg-transparent me-auto ms-auto text-" +
                           scenes[swiper.realIndex].color +
                           " border-" +
                           scenes[swiper.realIndex].color
                        }
                     >
                        zurück
                     </button>
                     <button
                        onClick={setScene}
                        className={
                           "btn btn--circle btn--circle-small bg-transparent me-auto text-" +
                           scenes[swiper.realIndex].color +
                           " border-" +
                           scenes[swiper.realIndex].color
                        }
                     >
                        Wählen
                     </button>
                  </p>
               </div>
            )}
         </Modal>
      </div>
   );
}
