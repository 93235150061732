import { sceneEditModeToggle, sceneSneakModeToggle } from "./scene/views";
import { init } from "./setup/init";
import * as THREE from "three";
import { addDirectionalLight, addSpotLight } from "./helpers/light";
import { createColor } from "./helpers/utils";
import { production } from "./production";
import { createScenePart } from "./scene/parts";
import { setStage } from "./stage/setStage";
import { setEffect } from "./stage/setEffect";
import { createFog } from "./assets_dynamic/createFog";
import { fetchAssets } from "./api/fetchAssets";
import { moveClone } from "./clones/moveClones";
import { throwClone } from "./control/clones";
import gsap from "gsap";
import { applyTextures } from "./helpers/applyTextures";

// web component
class BEStage extends HTMLElement {
   constructor() {
      super();
      this.state = {
         wrapper: document.querySelector("be-stage"),
         renderer: new THREE.WebGLRenderer(),
         scene: new THREE.Scene(),
         camera: null,
         clones: {},
         textures: {},
         activeClone: null,
         library: {},
         orbit: null,
         reference: {}, // we will use this for caching positions in case some objects need to toggle positions
         view: {
            walls_right: true,
            walls_left: true,
            roof_visible: true,
         },
      };
   }

   editModeEnable(enable) {
      sceneEditModeToggle(enable, this.state);
   }

   sneakModeToggle(enable) {
      console.log("sneakModeToggle!");
      sceneSneakModeToggle(enable, this.state);
   }

   addSpotLight() {
      addSpotLight({
         position: { x: -14, y: 36, z: 60 },
         target: { x: 0, y: 0, z: 0 },
         intensity: 10,
         helper: true,
         lamp: false,
         state: this.state,
      });
   }

   addDirectionalLight() {
      addDirectionalLight(this.state, {
         enableHelper: true,
         x: -3,
         y: 71,
         z: 4,
         intensity: 1,
         color: "0xffffff",
      });
   }

   zoomOut() {
      if (!this.state.theatreSheets) return;
      console.log(this.state.theatreSheets.cameraZoom.sequence);
      this.state.theatreSheets.cameraZoom.sequence.play({
         range: [0, 6],
         iterationCount: 1,
      });
   }

   zoomIn() {
      if (!this.state.theatreSheets) return;
      this.state.theatreSheets.cameraZoom.sequence.play({
         range: [0, 0.1],
         iterationCount: 1,
      });
   }

   handleFog(enable) {
      createFog(this.state, enable);
   }

   // lights
   //--------------------------------------------------------

   turnLightOnByUuid(uuid) {
      const currentLight = this.state.scene.getObjectByProperty("uuid", uuid);
      currentLight.visible = true;
      return currentLight.visible;
   }

   turnLightOffByUuid(uuid) {
      const currentLight = this.state.scene.getObjectByProperty("uuid", uuid);
      currentLight.visible = false;
      return currentLight.visible;
   }

   toggleLightByUuid(uuid) {
      const currentLight = this.state.scene.getObjectByProperty("uuid", uuid);
      currentLight.visible = !currentLight.visible;
      return currentLight.visible;
   }

   setLightIntensityByUuid(uuid, intensity) {
      const currentLight = this.state.scene.getObjectByProperty("uuid", uuid);
      if (!currentLight) return false;
      currentLight.intensity = intensity;
   }

   setLightColorByUuid(uuid, color) {
      const light = this.state.scene.getObjectByProperty("uuid", uuid);
      if (light) {
         console.log(color);
         const color_three = createColor(color);
         console.log(color_three);
         light.color = color_three;
      }
   }

   directionalLightIntensity(intensity) {
      const light = this.state.scene.getObjectByName("DirectionalLight");
      if (light) light.intensity = intensity;
   }

   setAllEnabledLightsColor(color) {
      const lights = this.state.scene.children.filter((child) => {
         return (
            (child.isDirectionalLight && child.visible) ||
            (child.isSpotLight && child.visible)
         );
      });
      console.log(lights);
      if (lights && lights.length > 0) {
         lights.forEach((light) => {
            const color_three = createColor(color);
            console.log(color);
            light.color = color_three;
         });
      }
   }

   // build scenes
   //--------------------------------------------------------
   createScenePart(scene, where, question, variation) {
      createScenePart(this.state, scene, where, question, variation);
   }

   setStage(stage) {
      setStage(this.state, stage);
   }

   // build effects
   //--------------------------------------------------------
   setEffect(effect, enable) {
      setEffect(this.state, effect, enable);
   }

   // fetch assets
   //--------------------------------------------------------
   fetchAssets() {
      console.log("fetched assets");
      return fetchAssets(this.state);
   }

   //*******************************************************
   // OBJECTS / CLONES
   //*******************************************************

   // grab Object
   //--------------------------------------------------------
   grabObjectByUuid(uuid) {
      const object = this.state.scene.getObjectByProperty("uuid", uuid);
      this.state.activeClone = object;
   }

   throwClone(cloneName) {
      throwClone({
         state: this.state,
         name: cloneName,
         x: 0,
         y_from: 20,
         z: 0,
      });
   }

   moveClone(direction, clone) {
      moveClone(direction, clone, this.state);
   }

   moveCloneByUuid(uuid, direction) {
      const activeClone = this.state.scene.getObjectByProperty("uuid", uuid);

      if (activeClone) moveClone(direction, activeClone, this.state);
   }

   getVideoTexture(name, target = "walls") {
      console.log(name);
      const video = this.state.textures[name];
      if (!video) return false;
      console.log(video);
      const file = video.userData.path;
      console.log(file);
      if (!file) return false;
      let videoElement = document.getElementById(name);
      if (!videoElement) {
         const video = document.createElement("video");
         video.setAttribute("id", name);
         video.setAttribute("playsInline", true);
         video.setAttribute("autoplay", true);
         video.setAttribute("loop", true);
         video.setAttribute("style", "display:none");
         video.setAttribute("src", file);
         document.body.appendChild(video);
         videoElement = document.getElementById(name);
      }
      const texture = new THREE.VideoTexture(videoElement);
      console.log("texture: ", texture);
      texture.flipY = false;
      return texture;
   }

   applyTexture(textureName, target = "walls") {
      let texture = this.state.textures[textureName];
      console.log(texture);
      const isVideoTexture = texture.userData.isVideoTexture;
      texture = isVideoTexture ? this.getVideoTexture(textureName) : texture;

      if (!texture) return false;
      if (target === "walls")
         applyTextures(this.state, texture, [
            "rueckwand_buehne001",
            "wand_rechts",
            "wand_rechts_aussen",
            "wand_links",
         ]);
      else applyTextures(this.state, texture, ["boden_buehne001"]);
   }

   resetTexture(target = "walls") {
      if (target === "walls") {
         let texture = this.state.textures["texture_reset"];
         applyTextures(this.state, texture, [
            "wand_rechts",
            "wand_rechts_aussen",
            "wand_links",
         ]);
         applyTextures(
            this.state,
            this.state.textures["texture_back_wall_reset"],
            ["rueckwand_buehne001"]
         );
      } else {
         applyTextures(this.state, this.state.textures["texture_floor_reset"], [
            "boden_buehne001",
         ]);
      }
   }

   rotateClone(clone) {
      const initRotation = clone.rotation.y;
      clone.rotation.y = initRotation + 0.2;
      // animation conflict with active clone
      // gsap.to(activeClone.rotation, {
      //    ...activeClone.rotation,
      //    y: initRotation + 0.2,
      //    duration: 1,
      // });
   }

   rotateCloneByUuid(uuid) {
      const currentClone = this.state.scene.getObjectByProperty("uuid", uuid);
      if (currentClone) this.rotateClone(currentClone);
   }

   resetActiveClone() {
      const activeClone = this.state.activeClone;
      this.state.activeClone = null;
      // becouse of the active clone animation we need to reset the y position
      activeClone.position.y =
         this.state.reference[activeClone.uuid].position.y;
   }

   deleteClone(clone) {
      clone.removeFromParent();
   }

   deleteCloneByUuid(uuid) {
      const currentClone = this.state.scene.getObjectByProperty("uuid", uuid);

      if (currentClone) currentClone.removeFromParent();
   }

   scaleClone(direction, clone) {
      const initScale = clone.scale;
      gsap.to(clone.scale, {
         x: direction === "up" ? initScale.x + 0.2 : initScale.x - 0.2,
         y: direction === "up" ? initScale.y + 0.2 : initScale.y - 0.2,
         z: direction === "up" ? initScale.z + 0.2 : initScale.z - 0.2,
      });
   }

   scaleCloneByUuid(uuid, direction) {
      const currentClone = this.state.scene.getObjectByProperty("uuid", uuid);

      if (currentClone) this.scaleClone(direction, currentClone);
   }

   // RESETS
   //--------------------------------------------------------

   async cleanStage() {
      return new Promise((resolve) => {
         console.log(this.state.scene.children);
         let toRemove = [];
         this.state.scene.children.forEach((child) => {
            console.log(child.name, child.userData.draggable);
            if (child.userData.draggable === true) toRemove.push(child.uuid);
         });
         toRemove.forEach((uuid) => {
            const object = this.state.scene.getObjectByProperty("uuid", uuid);
            object.removeFromParent();
         });
         resolve(toRemove);
      });
   }

   // SCREENSHOT
   //--------------------------------------------------------
   fetchScreenshot() {
      return new Promise((resolve, reject) => {
         this.zoomOut();
         this.state.renderer.domElement.width = this.state.wrapper.offsetWidth;
         this.state.renderer.domElement.height =
            this.state.wrapper.offsetHeight;

         const canvas = document.querySelector("be-stage canvas");
         const videoStream = canvas.captureStream(25); // the parameter is the desired framerate
         var mediaRecorder = new MediaRecorder(videoStream, {
            mimeType: "video/webm;codecs=h264",
         });
         var chunks = [];
         mediaRecorder.ondataavailable = function (e) {
            console.log(e.data);
            chunks.push(e.data);
         };
         mediaRecorder.onstop = function (e) {
            console.log(chunks);
            var blob = new Blob(chunks, { type: "video/webm" }); // other types are available such as 'video/webm' for instance, see the doc for more info
            console.log(blob);
            chunks = [];
            resolve(blob);
            // blob.arrayBuffer().then((data) => {
            //    console.log(data);
            //    const buffer = Buffer.from(data);
            //    resolve(buffer);
            // });
            // const myFile = new File([blob], "demo.mp4", { type: "video/mp4" });
            // const src = URL.createObjectURL(myFile);
            // resolve(src);
         };

         mediaRecorder.start();

         setTimeout(() => {
            mediaRecorder.stop();
            // this.state.renderer.render(this.state.scene, this.state.camera);
            // const src = this.state.renderer.domElement.toDataURL();

            // resolve(src);
         }, 6000);
      });
   }

   // connect component
   connectedCallback() {
      this.state.wrapper = document.querySelector("be-stage");
      init(this);

      this.addEventListener("afterModelsLoaded", () => {
         console.log("EVENT afterModelsLoaded emited");

         //*******************************************************
         // Only for click dummy
         //*******************************************************

         if (process.env.NODE_ENV !== "production") {
            this.state.wrapper.classList.remove("isLoading");
            setTimeout(() => {
               production(this.state);
            }, 1000);
         }
      });
   }
}

// register component
customElements.define("be-stage", BEStage);

module.exports = {
   BEStage,
};
