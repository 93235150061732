import videoBeleuchtung from "../../videos/interviews/MBB_Int_Jens_1080p.mp4";
import videoFundus from "../../videos/interviews/MBB_Int_SabineS_1080p.mp4";
import videoSchnuerboden from "../../videos/interviews/MBB_Int_Andre_1080p.mp4";
import videoKonstruktion from "../../videos/interviews/MBB_Int_Theresia_1080p.mp4";
import videoUntermaschinerie from "../../videos/interviews/MBB_Int_Benedikt_1080p.mp4";
import videoDrehbuehne from "../../videos/interviews/MBB_Int_Mirko_1080p.mp4";
import videoInspizienz from "../../videos/interviews/MBB_Int_SabineF_1080p.mp4";
import videoMaske from "../../videos/interviews/MBB_Int_Tobias_1080p.mp4";

export const videos = [
   {
      top: 10,
      left: 25,
      label: "Beleuchtung",
      videoUrl: videoBeleuchtung,
      fontSize: 0.8,
      visited: false,
   },
   {
      bottom: 25,
      right: 15,
      label: "Fundus",
      videoUrl: videoFundus,
      fontSize: 1,
      visited: false,
   },
   {
      top: 5,
      right: 25,
      label: "Schnürboden",
      videoUrl: videoSchnuerboden,
      fontSize: 0.8,
      visited: false,
   },
   {
      top: 30,
      right: 5,
      label: "Konstruktion",
      videoUrl: videoKonstruktion,
      fontSize: 0.8,
      visited: false,
   },
   {
      bottom: 7,
      right: 35,
      label: "Untermaschinerie",
      videoUrl: videoUntermaschinerie,
      fontSize: 0.6,
      visited: false,
   },
   {
      bottom: 5,
      left: 10,
      label: "Drehbühne",
      videoUrl: videoDrehbuehne,
      fontSize: 1,
      visited: false,
   },
   {
      bottom: 25,
      left: 15,
      label: "Inspizienz",
      videoUrl: videoInspizienz,
      fontSize: 1,
      visited: false,
   },
   {
      top: 35,
      left: 5,
      label: "Maske",
      videoUrl: videoMaske,
      fontSize: 1,
      visited: false,
   },
];
