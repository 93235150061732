import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import SliderStepNav from "../SliderStepNav";
import { useRoomsStore } from "../../../store/appStore";
import { subscribeToCollection } from "../../../services/subscriptions";
import { EnumTarget, KeywordInterface } from "../../../types/types";
import { updateKeywordCount } from "../../../services/servicesCrud";

export default function Slide7CloudTheater() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const keywords = useRoomsStore((state) => state.keywords);
   const setKeywords = useRoomsStore((state) => state.setKeywords);

   const [activeKeywords, setActiveKeywords] = useState<KeywordInterface[]>([]);
   const handleClick = (keyword: KeywordInterface) => {
      if (isActiveKeyword(keyword)) {
         const reducedActiveKeywords = activeKeywords.filter(
            (activeKeyword) => activeKeyword.id !== keyword.id
         );
         setActiveKeywords([...reducedActiveKeywords]);
         return;
      }
      setActiveKeywords([...activeKeywords, keyword]);
   };
   const isActiveKeyword = (keyword: KeywordInterface) => {
      return activeKeywords.some(
         (activeKeyword) => keyword.id === activeKeyword.id
      );
   };
   const saveKeywords = () => {
      activeKeywords.forEach((keyword) => {
         updateKeywordCount(EnumTarget.theather, keyword);
      });
   };

   // cycle
   //--------------------------------------------------------

   useEffect(() => {
      setInProp(true);
      subscribeToCollection({
         collectionName: "Answers",
         docId: EnumTarget.theather,
         subcollectionName: "Keywords",
         callback: setKeywords,
      });
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="container">
               <div className="row">
                  <div className="col-lg-10 m-auto d-flex align-items-center justify-content-center flex-wrap">
                     {keywords.map((keyword, index) => (
                        <button
                           key={index}
                           className={
                              "btn btn--1 m-2" +
                              (isActiveKeyword(keyword) ? " bg-purple" : "")
                           }
                           type="button"
                           onClick={() => handleClick(keyword)}
                        >
                           {keyword.name}
                        </button>
                     ))}
                  </div>
               </div>
            </div>
            <SliderStepNav
               onNext={saveKeywords}
               disabled={activeKeywords.length === 0}
            />
         </div>
      </CSSTransition>
   );
}
