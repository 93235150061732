import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import audio_end from "../../../audio/intro/09_MBB_super_jetzt_ueberlegen.mp3";
import { useStudentStore } from "../../../store/appStore";

export default function Slide24AudioEnd() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   let audio: any = useRef();

   useEffect(() => {
      setInProp(true);
      audio.current = new Audio(audio_end);
      audio.current.play();
      setBeStage(true);
      audio.current.onended = () => {
         nextSlide();
      };

      return () => {
         audio.current.pause();
      };
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const setBeStage = useStudentStore((state) => state.setBeStage);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef} className="mh-100vh d-flex">
            {/* <div className="d-flex mt-auto mb-5 ms-auto">
               <button
                  className="btn btn--circle btn--circle-small me-3"
                  onClick={() => audio.current.play()}
               >
                  play
               </button>
               <button
                  className="btn btn--circle btn--circle-small"
                  onClick={() => audio.current.pause()}
               >
                  stop
               </button>
            </div> */}
         </div>
      </CSSTransition>
   );
}
