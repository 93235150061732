import { createFog } from "../assets_dynamic/createFog";
import { createGrass } from "../assets_dynamic/createGrass";
import { createSnow } from "../assets_dynamic/createSnow";
import { throwClone, throwClonesOnRandomPosition } from "../control/clones";
import { part_lenz } from "./part/part_lenz";
import { loadMesh } from "../helpers/loading";
import * as THREE from "three";
import { part_wir_ihr } from "./part/part_wir_ihr";
import { part_love_song } from "./part/part_love_song";
import { part_don_giovanni } from "./part/part_don_giovanni";
import { part_feischuetz } from "./part/part_freischuetz";
import { part_cage } from "./part/part_cage";

export const createScenePart = (
   state,
   scene,
   where = "nature",
   question,
   variation
) => {
   // define all methods here
   const methods_lenz = part_lenz(state);
   const methods_wir_ihr = part_wir_ihr(state);
   const methods_love_song = part_love_song(state);
   const methods_don_giovanni = part_don_giovanni(state);
   const methods_freischuetz = part_feischuetz(state);
   const methods_cage = part_cage(state);
   const methods_global = {
      // ALL scenes
      //--------------------------------------------------------
      snow() {
         createSnow(state);
      },

      showMoon() {
         throwClone({
            state: state,
            name: "moon",
            x: 0,
            y_from: 20,
            y_to: 1,
            z: 0,
         });
      },

      showAvatars(count) {
         throwClonesOnRandomPosition(state, count, "avatar");
      },

      fog(enable) {
         createFog(state, enable);
      },
   };

   const methods = Object.assign(methods_global , methods_lenz, methods_wir_ihr, methods_love_song, methods_don_giovanni, methods_freischuetz, methods_cage);

   // call the method here dynamically
   const whereString = where ? where + "_" : "";
   const callbackName = `${scene}_${whereString}${question}_${variation}`;
   if (variation === "snow") {
      methods.snow();
      return false;
   }
   if (variation === "fog") {
      methods.fog(true);
      return false;
   }
   // if (callbackName.includes("moon_full")) {
   //    methods.showMoon();
   //    return false;
   // }
   // if (callbackName.includes("people")) {
   //    methods.showAvatars(variation);
   //    return false;
   // }
   console.log(callbackName);
   console.log(methods);
   const callback = methods[callbackName];
   if (!callback) {
      console.warn(
         scene + "_" + question + "_" + variation + "() not defined in methods"
      );
      return false;
   }
   callback();
};
