import { Dispatch, SetStateAction, useEffect, useState } from "react";
import IconArrow from "../../Icons/IconArrow";
import IconMove from "../../Icons/IconMove";
import IconRotate from "../../Icons/IconRotate";
import IconScale from "../../Icons/IconScale";
import { EnumDirection, EnumScale } from "../../../types/types";
import IconCheckmark from "../../Icons/IconCheckmark";

interface Props {
   cloneUuid: string | null;
   onMoveObject: (uuid: string | null, direction: string) => void;
   onRotateObject: (uuid: string | null) => void;
   onScaleObject: (uuid: string | null, direction: string) => void;
   onResetObject: () => void;
   onHideCloneSettingsUi: () => void;
   moveEnabled: boolean;
   setMoveEnabled: Dispatch<SetStateAction<boolean>>;
   scaleEnabled: boolean;
   setScaleEnabled: Dispatch<SetStateAction<boolean>>;
   setSelectedItem: Dispatch<SetStateAction<string | null>>;
}

export default function CloneSettingsUI({
   cloneUuid,
   onMoveObject,
   onRotateObject,
   onScaleObject,
   onResetObject,
   onHideCloneSettingsUi,
   moveEnabled,
   setMoveEnabled,
   scaleEnabled,
   setScaleEnabled,
   setSelectedItem,
}: Props) {
   const handleMoveToggle = () => {
      if (scaleEnabled) setScaleEnabled(!scaleEnabled);
      setMoveEnabled(!moveEnabled);
   };

   const handleScaleToggle = () => {
      if (moveEnabled) setMoveEnabled(!moveEnabled);
      setScaleEnabled(!scaleEnabled);
   };

   const handleRotate = (uuid: string | null) => {
      if (moveEnabled) setMoveEnabled(!moveEnabled);
      if (scaleEnabled) setScaleEnabled(!scaleEnabled);

      handleRotateObject(uuid);
   };

   const handleMoveObject = (uuid: string | null, direction: string) => {
      if (uuid && uuid !== "" && direction && direction !== "")
         onMoveObject(uuid, direction);
   };

   const handleRotateObject = (uuid: string | null) => {
      if (uuid && uuid !== "") onRotateObject(uuid);
   };

   const handleScaleObject = (uuid: string | null, direction: string) => {
      if (uuid && uuid !== "" && direction && direction !== "")
         onScaleObject(uuid, direction);
   };
   const handleResetObject = (uuid: string | null) => {
      onResetObject();
      onHideCloneSettingsUi();
      setSelectedItem(null);
   };

   return (
      <div
         className={
            "slideEditor__cloneSettings" +
            (moveEnabled ? " slideEditor__cloneSettings--moveActive" : "") +
            (scaleEnabled ? " slideEditor__cloneSettings--scaleActive" : "")
         }
      >
         <button
            className="btn btn--icon me-3"
            onClick={() => handleRotate(cloneUuid)}
         >
            <IconRotate />
            <span>rotate</span>
         </button>
         <div className="buttons__wrapper--scale">
            <button
               className={
                  "btn btn--icon me-3" +
                  (scaleEnabled ? " bg-primary border-none" : "")
               }
               onClick={handleScaleToggle}
            >
               <IconScale />
               <span>scale</span>
            </button>
            <div className="slideEditor__cloneSettings-scale">
               <button
                  id={EnumScale.up}
                  className="btn btn--icon rotate-260"
                  onClick={() => handleScaleObject(cloneUuid, EnumScale.up)}
               >
                  <IconArrow />
               </button>
               <button className="btn--icon btn" onClick={handleScaleToggle}>
                  <IconScale />
               </button>
               <button
                  id={EnumScale.down}
                  className="btn btn--icon rotate-90"
                  onClick={() => handleScaleObject(cloneUuid, EnumScale.down)}
               >
                  <IconArrow />
               </button>
            </div>
         </div>
         <div className="buttons__wrapper--move">
            <button
               className={
                  "btn btn--icon" +
                  (moveEnabled ? " bg-primary border-none" : "")
               }
               onClick={handleMoveToggle}
            >
               <IconMove />
               <span>move</span>
            </button>
            <div className="slideEditor__cloneSettings-move">
               <span className="btn--icon btn visibility-hidden">1</span>
               <button
                  id={EnumDirection.up}
                  className="btn btn--icon rotate-260"
                  onClick={() => handleMoveObject(cloneUuid, EnumDirection.up)}
               >
                  <IconArrow />
               </button>
               <button
                  id={EnumDirection.back}
                  className="btn btn--icon rotate-305"
                  onClick={() =>
                     handleMoveObject(cloneUuid, EnumDirection.back)
                  }
               >
                  <IconArrow />
               </button>
               <button
                  id={EnumDirection.left}
                  className="btn btn--icon rotate-180"
                  onClick={() =>
                     handleMoveObject(cloneUuid, EnumDirection.left)
                  }
               >
                  <IconArrow />
               </button>
               <span className="btn--icon btn" onClick={handleMoveToggle}>
                  <IconMove />
               </span>
               <button
                  id={EnumDirection.right}
                  className="btn btn--icon"
                  onClick={() =>
                     handleMoveObject(cloneUuid, EnumDirection.right)
                  }
               >
                  <IconArrow />
               </button>
               <button
                  id={EnumDirection.front}
                  className="btn btn--icon rotate-135"
                  onClick={() =>
                     handleMoveObject(cloneUuid, EnumDirection.front)
                  }
               >
                  <IconArrow />
               </button>
               <button
                  id={EnumDirection.down}
                  className="btn btn--icon rotate-90"
                  onClick={() =>
                     handleMoveObject(cloneUuid, EnumDirection.down)
                  }
               >
                  <IconArrow />
               </button>
               <span className="btn--icon btn visibility-hidden">1</span>
            </div>
         </div>
         <button
            className="btn btn--icon ms-3"
            onClick={() => handleResetObject(cloneUuid)}
         >
            <IconCheckmark />
            <span>save</span>
         </button>
      </div>
   );
}
