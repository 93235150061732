import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import hi from "../../../audio/intro/01_MBB_hi.mp3";
import { useStudentStore } from "../../../store/appStore";

export default function Slide6AudioIntro() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const setAnimationVolume = useStudentStore(
      (state) => state.setAnimationVolume
   );
   let audio: any = useRef();

   useEffect(() => {
      setInProp(true);
      audio.current = new Audio(hi);
      audio.current.play();
      audio.current.onended = () => {
         nextSlide();
      };
      setAnimationVolume(0.1);

      return () => {
         audio.current.pause();
      };
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef} className="mh-100vh d-flex">
            {/* <div className="d-flex mt-auto mb-5 ms-auto">
               <button
                  className="btn btn--circle btn--circle-small me-3"
                  onClick={() => audio.current.play()}
               >
                  play
               </button>
               <button
                  className="btn btn--circle btn--circle-small"
                  onClick={() => audio.current.pause()}
               >
                  stop
               </button>
            </div> */}
         </div>
      </CSSTransition>
   );
}
