import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import stage from "../../../images/backgrounds/stage_front.jpg";
import { useStudentStore } from "../../../store/appStore";
import willkommen_zurueck from "../../../audio/intro/04_MBB_willkommen_zurueck.mp3";

export default function Slide12() {
   // transition effect
   //--------------------------------------------------------
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   let audio: any = useRef();
   const [showButton, setshowButton] = useState(false);
   useEffect(() => {
      setInProp(true);
      setTimeout(() => {
         audio.current = new Audio(willkommen_zurueck);
         audio.current.play();
         audio.current.onended = () => {
            setshowButton(true);
         };
      }, 7000);

      return () => {
         audio?.current?.pause();
      };
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={7000}
         classNames="slow"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="mh-100vh position-relative mw-100vw">
               <button
                  onClick={nextSlide}
                  className={
                     "btn btn--1 btn--onTop" +
                     (showButton ? " item-enter-active" : " item-enter")
                  }
                  style={{ top: "50%", left: "50%" }}
               >
                  Backstage
               </button>
               <img src={stage} className="full" />
            </div>
         </div>
      </CSSTransition>
   );
}
