import { useState } from "react";
import { addRoom } from "../../services/servicesCrud";
import { useRoomsStore, useTeacherStore } from "../../store/appStore";
import { auth } from "../../firebase/firebaseConfig";
import { signInAnonymously } from "firebase/auth";

export default function RoomFormAdd() {
   const [name, setName] = useState("");
   const setTeachersRoom = useTeacherStore((state) => state.setRoom);
   const superUser = useRoomsStore((state) => state.superUser);

   async function handleSubmit(e: any) {
      e.preventDefault();
      if (!superUser) await signInAnonymously(auth);
      addRoom({ name }).then((room: any) => {
         setTeachersRoom(room);
      });
   }
   return (
      <form onSubmit={handleSubmit}>
         <input
            type="text"
            name="name"
            id="name"
            className="me-2 w-100 mb-3"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="zb: 9b"
         />
         <input
            type="submit"
            value="Klassenraum hinzufügen"
            className="btn btn--1 w-100 bg-primary"
         />
      </form>
   );
}
