import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import Modal from "./Modal";

export default function ColorPicker({ color, onChange, show, onClose }: any) {
   useEffect(() => {
      console.log("mounted picker");
   }, []);

   return (
      <Modal type="modal--small" show={show} onClose={onClose}>
         {color && (
            <HexColorPicker
               color={color}
               onChange={(color) => onChange(color)}
            />
         )}
      </Modal>
   );
}
