import { loadMesh, loadTexture, loadVideoTexture } from "../helpers/loading";
import { getAssets } from "./assetsList";

export async function loadAssets(state) {
   const assets = getAssets();
   const path = document.location.origin;
   return new Promise((resolve, reject) => {
      (async () => {
         try {
            for (const asset of assets) {
               if (
                  (asset.userData && asset.userData.isTexture) ||
                  (asset.userData && asset.userData.isTextureReset)
               ) {
                  console.log("loadingTexture: ", asset.name);
                  await loadTexture(state, asset);
               } else if (asset.userData && asset.userData.isVideoTexture) {
                  console.log("loadingVideoTexture: ", asset.name);
                  await loadVideoTexture(state, asset);
               } else {
                  console.log("loading Mesh: ", asset.name);
                  await loadMesh({
                     url: asset.path,
                     meshName: asset.name,
                     destination: "clones",
                     state: state,
                     label: asset.label,
                     isGroup: asset.isGroup,
                     scale: asset.scale,
                     userData: asset.userData,
                  });
               }
            }

            await loadMesh({
               url: `${path}/assets/230607_mbb_buehne_V32_hwmd.glb`,
               meshName: "StageModel",
               destination: "scene",
               state: state,
            });

            return resolve();
         } catch (error) {
            return reject(error);
         }
      })();
   });
}
