import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import snow from "../../../images/scenes/preview/snow.jpg";
import fog from "../../../images/scenes/preview/fog.jpg";
import { EnumFundusSpecial } from "../../../types/types";

export default function Slide30fundusSpecial() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const scene = useStudentStore((state) => state.chosenScene);
   const setFundus = useStudentStore((state) => state.setFundus);
   const fundus = useStudentStore((state) => state.fundus);

   useEffect(() => {
      setInProp(true);
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const handleClick = (item: EnumFundusSpecial) => {
      setFundus({
         ...fundus,
         special: item,
      });
      nextSlide();
   };

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div
            ref={nodeRef}
            className="mh-100vh d-flex justify-content-center flex-column text-white text-center"
         >
            <img
               className="mb-4 mt-auto m-auto mb-0"
               width={300}
               src={scene?.image}
               alt=""
            />
            <h2 className="h3 mt-5">Gibt es Special Effekte beim Wetter?</h2>
            <div className="mb-5 mt-5 d-flex ">
               <div className="ms-auto me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusSpecial.snow);
                     }}
                     type="button"
                     className="btn btn--circle mb-3"
                  >
                     <img src={snow} />
                  </button>
                  <p className="text-uppercase">Schnee</p>
               </div>
               <div className="ms-5 me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusSpecial.fog);
                     }}
                     type="button"
                     className="btn btn--circle mb-3 m-auto"
                  >
                     <img src={fog} />
                  </button>
                  <p className="text-uppercase">Nebel</p>
               </div>
               <div className="ms-5 me-auto">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusSpecial.none);
                     }}
                     type="button"
                     className="btn btn--circle bg-transparent mb-3"
                  ></button>
                  <p className="text-uppercase">Keine</p>
               </div>
            </div>
            <h4 className="h3 mb-2 mt-auto">{scene?.title}</h4>
            <p className="mb-5">{scene?.meta}</p>
         </div>
      </CSSTransition>
   );
}
