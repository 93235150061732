import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";

export default function Slide5() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);

   useEffect(() => {
      setInProp(true);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <h2 className="h2 text-white text-center">
               Bitte wende dich an das Lehrpersonal
            </h2>
         </div>
      </CSSTransition>
   );
}
