import { useEffect, useState } from "react";
import { useStudentStore } from "../../store/appStore";
import { updateStudent } from "../../services/servicesCrud";
import { useParams } from "react-router-dom";
import { EnumAnimationState, EnumFundusCamera } from "../../types/types";
import SliderDevNav from "./SliderDevNav";
import ProgressBar from "../UI/ProgressBar";
import PageServiceNavModal from "../Page/PageServiceNavModal";
import { subscribeToDocument } from "../../services/subscriptions";

export default function Slider({ children }: any) {
   const fundus = useStudentStore((state) => state.fundus);
   const setFundus = useStudentStore((state) => state.setFundus);
   const setBeStageMenu = useStudentStore((state) => state.setBeStageMenu);
   const sliderIndex = useStudentStore((state) => state.sliderIndex);
   const slidesCount = children.length;
   const isEditor = sliderIndex === slidesCount - 1;
   const { roomId } = useParams();
   const student = useStudentStore((state) => state.student);
   const setProgress = (progress: number) => {
      setProgressLocal(progress);
      if (student) updateStudent({ progress }, roomId, student.id);
   };
   const [progressLocal, setProgressLocal] = useState<number>(0);
   const setAnimationState = useStudentStore(
      (state) => state.setAnimationState
   );
   const isModalActive = useStudentStore((state) => state.isModalActive);
   const isProgressBarVisible = sliderIndex < slidesCount - 1 && !isModalActive;
   const isFooterMenuVisible =
      sliderIndex !== slidesCount - 1 && !isModalActive;

   const [navigation, setNavigation] = useState(false);
   const modals = useStudentStore((state) => state.modalsInEditor);

   useEffect(() => {
      subscribeToDocument({
         collectionName: "settings",
         documentName: "navigation",
         callback: (data: any) => {
            setNavigation(data.show);
         },
      });
   }, []);

   useEffect(() => {
      setProgress((sliderIndex * 100) / slidesCount);
      if (isEditor) {
         setFundus({
            ...fundus,
            camera: EnumFundusCamera.zoomOut,
         });
      } else {
         setFundus({
            ...fundus,
            camera: EnumFundusCamera.zoomIn,
         });
      }
      if (isEditor) setBeStageMenu(true);
      else setBeStageMenu(false);

      if (sliderIndex >= 16 && sliderIndex < 26)
         setAnimationState(EnumAnimationState.end);

      if (sliderIndex < 16) setAnimationState(EnumAnimationState.default);
      if (isEditor) setAnimationState(EnumAnimationState.end);

      return () => {};
   }, [sliderIndex]);
   return (
      <div
         className={
            "slider" +
            (sliderIndex === slidesCount ? " slider--onTop" : "") +
            (modals.final || modals.description || modals.objects3d
               ? " overflow-visible"
               : "")
         }
      >
         <div className="slider__item">{children[sliderIndex - 1]}</div>
         {navigation && <SliderDevNav children={children} />}
         {isProgressBarVisible && (
            <ProgressBar styling="progress--slider" progress={progressLocal} />
         )}
         {isFooterMenuVisible && <PageServiceNavModal />}
      </div>
   );
}
