export const fetchAssets = (state) => {
   const path = document.location.origin;
   // clones
   //--------------------------------------------------------
   const clones = [];
   Object.keys(state.clones).forEach((key) => {
      const clone = state.clones[key];
      const clone_obj = {
         label: clone.userData.label,
         name: clone.name,
         uuid: clone.uuid,
         thumbnail: `${path}/assets/thumbnails/${clone.name}.png`,
      };
      if (clone.userData.isAlphabet) clone_obj.group = "alphabet";
      clones.push(clone_obj);
   });

   // Textures
   //--------------------------------------------------------
   const textures = [];
   Object.keys(state.textures).forEach((key) => {
      if (state.textures[key].userData.isTextureReset) return false;
      const texture = state.textures[key];
      const texture_obj = {
         label: texture.userData.label,
         name: texture.name,
         uuid: texture.uuid,
         thumbnail: `${path}/assets/thumbnails/textures/${texture.name}.png`,
         path: texture.userData.path ? texture.userData.path : null,
         isVideo: texture.userData.isVideoTexture
            ? texture.userData.isVideoTexture
            : false,
      };
      textures.push(texture_obj);
   });

   // lights
   //--------------------------------------------------------
   const lights = [];
   const directionalLights = state.scene.children.filter((child) => {
      return child.isDirectionalLight || child.isSpotLight;
   });

   directionalLights.forEach((light) => {
      const light_obj = {
         label: light.userData.label,
         name: light.name,
         uuid: light.uuid,
         visible: light.visible,
         intensity: light.intensity,
         color: light.color.getStyle(),
      };
      lights.push(light_obj);
   });

   // objects
   //--------------------------------------------------------
   const objects = [];
   const currentObjects = state.scene.children.filter((child) => {
      return child.userData.draggable;
   });

   currentObjects.forEach((object) => {
      const object_obj = {
         label: object.userData.label,
         name: object.name,
         uuid: object.uuid,
         thumbnail: `${path}/assets/thumbnails/${object.name}.png`,
      };
      objects.push(object_obj);
   });

   // effects
   //--------------------------------------------------------
   let effects = null;

   const current_snow = state.scene.children.filter((child) => {
      return child?.userData?.isSnow;
   });

   if (current_snow && current_snow?.length > 0) effects = "snow";
   else if (state?.scene?.fog?.isFog) effects = "fog";
   else effects = "none";

   return {
      clones: clones,
      lights: lights,
      objects: objects,
      textures: textures,
      effects: effects,
   };
};
