export default function IconPlus() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="12"
         height="12"
         viewBox="0 0 12 12"
      >
         <g
            id="Gruppe_152"
            data-name="Gruppe 152"
            transform="translate(-276.5 -160.5)"
         >
            <line
               id="Linie_2"
               data-name="Linie 2"
               x2="12"
               transform="translate(276.5 166.5)"
               fill="none"
               stroke="#707070"
               strokeWidth="1"
            />
            <line
               id="Linie_3"
               data-name="Linie 3"
               x2="12"
               transform="translate(282.5 160.5) rotate(90)"
               fill="none"
               stroke="#707070"
               strokeWidth="1"
            />
         </g>
      </svg>
   );
}
