export default function IconArrow({ color }: any) {
   return (
      <svg
         height="512px"
         id="Layer_1"
         version="1.1"
         viewBox="0 0 512 512"
         width="512px"
         xmlns="http://www.w3.org/2000/svg"
      >
         <polygon
            points="448.5,256.5 256.5,64.5 256.5,176.5 64.5,176.5 64.5,336.5 256.5,336.5 256.5,448.5 "
            fill={color ? color : "black"}
         />
      </svg>
   );
}
