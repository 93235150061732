import * as THREE from "three";
import { GUI } from "dat.gui";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import gsap from "gsap";

const params = {
   color: "#ffffff",
};

export const addDirectionalLight = (
   state,
   args = {
      enableHelper: false,
      x: -3,
      y: 71,
      z: 4,
      intensity: 1,
      color: "0xffffff",
      name: null,
      visible: false,
      label: null,
   }
) => {
   const { enableHelper, x, y, z, intensity, color, name, visible, label } =
      args;
   let light = new THREE.DirectionalLight(color, intensity);

   light.position.set(x, y, z);
   light.name = name ? name : "DirectionalLight";
   light.castShadow = true;
   light.shadow.bias = -0.001;
   light.shadow.mapSize.width = 2048;
   light.shadow.mapSize.height = 2048;
   light.shadow.camera.near = 0.1;
   light.shadow.camera.far = 500.0;
   light.shadow.camera.near = 0.5;
   light.shadow.camera.far = 500.0;
   light.shadow.camera.left = 100;
   light.shadow.camera.right = -100;
   light.shadow.camera.top = 100;
   light.shadow.camera.bottom = -100;
   state.scene.add(light);
   light.visible = visible ? true : false;
   if (label) light.userData.label = label;
   if (!enableHelper) return false;
   //Target
   const targetObject = new THREE.Object3D();
   state.scene.add(targetObject);
   light.target = targetObject;

   //Helper
   const helper = new THREE.DirectionalLightHelper(light, 5);
   state.scene.add(helper);

   //GUI
   const gui = new GUI();
   gui.add(light.position, "x", -100, 100).onChange(() => {
      helper.update();
   });
   gui.add(light.position, "y", -100, 100).onChange(() => {
      helper.update();
   });
   gui.add(light.position, "z", -1000, 1000).onChange(() => {
      helper.update();
   });
   gui.add(light, "intensity", 0, 20).onChange(() => {
      helper.update();
   });

   gui.addColor(params, "color").onChange(function (value) {
      const color = new THREE.Color(value);
      light.color = color;
   });

   gui.add(helper, "visible", true, false).onChange(() => {
      helper.update();
   });
};

export const ambientLight = (state) => {
   const light = new THREE.AmbientLight(0x404040); // soft white light

   // light.position.set(0, 100, 100);
   state.scene.add(light);

   const gui = new GUI();
   gui.add(light, "intensity", 1, 1000).onChange(() => {
      console.log(light);
   });
};

export const pointLight = () => {
   const light = new THREE.PointLight(0xffffff, 1.0, 100);
   light.position.set(0, 10, 0);
   light.castShadow = true;
   state.scene.add(light);

   const sphereSize = 10;
   const pointLightHelper = new THREE.PointLightHelper(light, sphereSize);
   state.scene.add(pointLightHelper);

   light.shadow.mapSize.width = 20; // default
   light.shadow.mapSize.height = 20; // default
   light.shadow.camera.near = 0.5; // default
   light.shadow.camera.far = 20; // default
   const helper = new THREE.CameraHelper(light.shadow.camera);
   state.scene.add(helper);
   return light;
};

export const addSpotLight = (options) => {
   const newSpotLight = new SpotLight(options);
};

class SpotLight {
   constructor(options) {
      this.init(options);
   }

   init(options) {
      const defaultOptions = {
         lamp: true,
         position: {
            x: 3.6,
            y: 20,
            z: 10,
         },
         target: {
            x: 0,
            y: 0,
            z: 0,
         },
         intensity: 2,
         helper: true,
         color: "#ffffff",
         angle: Math.PI / 15,
         state: null,
         label: null,
         name: null,
         visible: false,
      };
      const args = {
         ...defaultOptions,
         ...options,
      };
      const { state } = args;
      const light = new THREE.SpotLight(0xffffff, args.intensity);
      light.position.x = args.position.x;
      light.position.y = args.position.y;
      light.position.z = args.position.z;
      light.target.position.x = args.target.x;
      light.target.position.y = args.target.y;
      light.target.position.z = args.target.z;
      light.userData.isSpotLight = true;
      state.scene.add(light);
      state.scene.add(light.target); // add target to the scene
      light.angle = args.angle;
      light.penumbra = 0.05;
      light.decay = 2;
      light.distance = 300;

      light.castShadow = true;
      light.shadow.mapSize.width = 4096;
      light.shadow.mapSize.height = 4096;
      light.shadow.camera.near = 10;
      light.shadow.camera.far = 300;
      light.shadow.camera.fov = 30;
      light.shadow.focus = 0.5;
      const color = new THREE.Color(args.color);
      light.color = color;

      if (args.label) light.userData.label = args.label;
      if (args.name) light.name = args.name;
      light.visible = args.visible;

      // Add the lamp
      //--------------------------------------------------------

      if (args.lamp) {
         const clone = SkeletonUtils.clone(state.library.spotlight_lamp);
         // set position
         clone.position.x = args.position.x;
         clone.position.y = args.position.y - 1;
         clone.position.z = args.position.z;
         //set rotation
         const rotationMatrix = new THREE.Matrix4();
         const targetQuaternion = new THREE.Quaternion();
         // console.log("target: ", args.target);
         // console.log("position: ", args.position);
         // console.log("clone.up: ", clone.up);
         // console.log("---");
         state.scene.add(clone);
         rotationMatrix.lookAt(args.target, args.position, clone.up);
         targetQuaternion.setFromRotationMatrix(rotationMatrix);
         clone.quaternion.rotateTowards(targetQuaternion, 1);
      }

      if (!args.helper) return false;
      const helper = new THREE.CameraHelper(light.shadow.camera);
      state.scene.add(helper);
      const spotLightHelper = new THREE.SpotLightHelper(light);
      state.scene.add(spotLightHelper);

      const gui = new GUI();
      // const spotlightFolder = gui.addFolder("Spotlight");
      gui.add(light, "distance", 20, 500).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });

      gui.add(light, "intensity", 1, 20).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });

      gui.add(light.position, "x", -100, 100).onChange(function (x) {
         spotLightHelper.update();
         helper.update();
         if (!args.lamp) return false;
         const mesh = state.scene.getObjectById(clone.id);
         gsap.to(mesh.position, { ...mesh.position, x: x });
      });
      gui.add(light.position, "y", -100, 100).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });
      gui.add(light.position, "z", -100, 100).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });

      gui.add(light.target.position, "x", -1000, 1000).onChange((x) => {
         spotLightHelper.update();
         helper.update();
         if (!args.lamp) return false;
         const mesh = state.scene.getObjectById(clone.id);
         rotationMatrix.lookAt(light.target.position, mesh.position, mesh.up);
         targetQuaternion.setFromRotationMatrix(rotationMatrix);
         clone.quaternion.rotateTowards(targetQuaternion, 1);
      });
      gui.add(light.target.position, "y", -1000, 1000).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });
      gui.add(light.target.position, "z", -1000, 1000).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });
      gui.addColor(params, "color").onChange(function (value) {
         const color = new THREE.Color(value);
         light.color = color;
      });
      gui.add(
         light,
         "angle",
         (light.angle = Math.PI / 20),
         (light.angle = Math.PI / 5)
      ).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });
      gui.add(spotLightHelper, "visible", false, true).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });
      gui.add(helper, "visible", true, false).onChange(() => {
         spotLightHelper.update();
         helper.update();
      });
   }
}
