import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import Configurator from "../../Configurator/Configurator";
import audio_szenen from "../../../audio/intro/07_MBB_buehne.mp3";

export default function Slide15Configurator() {
   // transition effect
   //--------------------------------------------------------
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   let audio: any = useRef();

   // cycle
   //--------------------------------------------------------
   useEffect(() => {
      setInProp(true);
      audio.current = new Audio(audio_szenen);
      audio.current.play();

      return () => {
         audio.current.pause();
      };
   }, []);

   // methods
   //--------------------------------------------------------
   const [modal, setModal] = useState(false);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <Configurator />
         </div>
      </CSSTransition>
   );
}
