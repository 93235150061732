import { getProject, types } from "@theatre/core";
import * as THREE from "three";
import theatreState from "./state.json";
import studio from "@theatre/studio";
studio.initialize();
studio.ui.hide();

export const handleTheathre = (state) => {
   console.log(process.env);

   state.theatreProject = getProject("THREE.js x Theatre.js", {
      state: theatreState,
   });
   state.theatreSheets = {};
   state.theatreObjects = {};
   // const project = getProject("THREE.js x Theatre.js", {
   //    state: theatreState,
   // });
   state.theatreSheets.cameraZoom = state.theatreProject.sheet("Camera Zoom");
   state.theatreObjects.cameraZoom = state.theatreSheets.cameraZoom.object(
      "Camera",
      {
         position: types.compound({
            x: types.number(state.camera.position.x, { range: [-100, 100] }),
            y: types.number(state.camera.position.y, { range: [-100, 100] }),
            z: types.number(state.camera.position.z, { range: [-100, 100] }),
         }),
      }
   );

   state.theatreObjects.cameraZoom.onValuesChange((values) => {
      if (!values) return;
      const { x, y, z } = values.position;

      state.camera.position.set(x, y, z);
      state.camera.lookAt(new THREE.Vector3(0, 12, 0));
   });

   // project.ready.then(() => cameraAnimationSheet.sequence.play());
   // state.theatreProject.ready.then(() => {
   //    console.log(state.theatreSheets.cameraZoom.sequence.play());
   //    state.theatreSheets.cameraZoom.sequence.play();
   // });
};
