// @ts-nocheck
import backgroundAudio from "../../audio/animationBox/background.mp3";
import audio_hey_sehen_wir_uns_wieder_file from "../../audio/animationBox/hey_sehen_wir_uns_ wieder.mp3";
import intro_2 from "../../videos/bmb/intro/230601HWMD1410AE_MagicBlackbox_Intro_Explosion_h264.mp4";
import intro_1 from "../../videos/bmb/intro/230601HWMD1415AE_MagicBlackbox_Intro_Loop_h264.mp4";
import loopOutro from "../../videos/bmb/outro/230601HWMD1425AE_MagicBlackbox_Outro_Loop_h264.mp4";
import implosion from "../../videos/bmb/outro/230601HWMD1420AE_MagicBlackbox_Outro_Explosion_h264.mp4";
import trailer_url from "../../videos/MBB_BoxOpening_Trailer_forTablets.mp4";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../store/appStore";
import { EnumAnimationState } from "../../types/types";
import { isDevelopment } from "../../helpers/utils";
export default function AnimationBox() {
   const [hide, setHide] = useState<"fadeOut" | "fadeIn" | "fadeInSoft">(
      "fadeOut"
   );

   const animation = useStudentStore((state) => state.animation);
   const animationAudioVolume = useStudentStore(
      (state) => state.animation.audioVolume
   );
   const animationState = useStudentStore((state) => state.animation.state);
   const setAnimationState = useStudentStore(
      (state) => state.setAnimationState
   );

   const goToSlide = useStudentStore((state) => state.setSliderIndex);
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const refLoop = useRef(null);
   const refLoopOutro = useRef(null);
   const refLoopEndTime = 20;
   const refExplosion = useRef(null);
   const refImplosion = useRef(null);
   const refExplosionEndTime = 2.52;
   const refTrailer = useRef(null);
   const trailerEndTime = isDevelopment() ? 10 : 70;
   const audio: any = useRef();
   const audio_hey_sehen_wir_uns_wieder: any = useRef();

   //*******************************************************
   // Cycle
   //*******************************************************
   useEffect(() => {
      audio.current = new Audio(backgroundAudio);
      audio.current.loop = true;
      audio.current.volume = animationAudioVolume;
      audio.current.play();

      audio_hey_sehen_wir_uns_wieder.current = new Audio(
         audio_hey_sehen_wir_uns_wieder_file
      );
      // audio_hey_sehen_wir_uns_wieder.current.volume = animationAudioVolume;

      return () => {};
   }, []);

   useEffect(() => {
      if (animationState === EnumAnimationState.explosion) {
         checkTimeToExplosion();
      }

      if (animationState === EnumAnimationState.default) {
         resetAnimation();
      }

      if (animationState === EnumAnimationState.implosion) {
         handleOutro();
      }

      if (animationState === EnumAnimationState.end) {
         setHide("fadeOut");
         cleanUp();
      }

      audio.current.volume = animationAudioVolume;

      return () => {
         console.log("clean up");
      };
   }, [animation]);

   //*******************************************************
   // Methods
   //*******************************************************

   const checkTimeToExplosion = () => {
      if (!refLoop || !refLoop.current) return false;
      refLoop.current.playbackRate = 7;
      refLoop.current.loop = false;
      if (refLoop.current.currentTime >= refLoopEndTime - 0.5) {
         refExplosion.current.play();
      }
      if (refLoop.current.currentTime >= refLoopEndTime) {
         showExplosionVideo();
         checkTimeToTrailer();
      } else {
         setTimeout(checkTimeToExplosion, 100);
      }
   };

   const checkTimeToTrailer = () => {
      if (refExplosion.current.currentTime >= refExplosionEndTime) {
         showTrailerVideo();
         refTrailer.current.play();
         checkTimeToTrailerEnd();
      } else {
         setTimeout(checkTimeToTrailer, 100);
      }
   };

   const checkTimeToTrailerEnd = () => {
      if (refTrailer.current.currentTime >= trailerEndTime) {
         refTrailer.current.pause();
         refTrailer.current.currentTime = 0;
         goToSlide(16);
         fadeOut();
      } else {
         if (!refTrailer.current.paused && !refTrailer.current.ended)
            setTimeout(checkTimeToTrailerEnd, 100);
      }
   };

   const playMovie = (args: any) => {
      const { movie, time } = args;
      return new Promise((resolve, reject) => {
         movie.current.play();
         setTimeout(() => {
            resolve();
         }, time);
      });
   };

   const showExplosionVideo = () => {
      refLoop.current.classList.remove("top");
      refExplosion.current.classList.add("top");
   };

   const showImplosionVideo = () => {
      refLoop.current.classList.remove("top");
      refExplosion.current.classList.remove("top");
      refImplosion.current.classList.add("top");
   };

   const showLoopOutroVideo = () => {
      refLoopOutro.current.classList.add("top");
      refExplosion.current.classList.remove("top");
      refImplosion.current.classList.remove("top");
      refLoop.current.classList.remove("top");
   };

   const showTrailerVideo = () => {
      audio.current.pause();
      refExplosion.current.classList.remove("top");
      refTrailer.current.classList.add("top");
   };

   //debugging
   const handleClick1 = () => {
      setAnimationState(EnumAnimationState.explosion);
   };
   const handleClick2 = () => {
      setAnimationState(EnumAnimationState.default);
   };

   const resetAnimation = () => {
      fadeInSoft();
      refLoop.current.playbackRate = 1;
      refLoop.current.loop = true;
      refLoop.currentTime = 0;
      refLoop.current.play();

      refExplosion.currentTime = 0;
      refExplosion.current.pause();

      refLoop.current.classList.add("top");
      refExplosion.current.classList.remove("top");
      refTrailer.current.classList.remove("top");
      refTrailer.current.pause();
      refTrailer.currentTime = 0;
      audio.current.play();
   };

   const cleanUp = () => {
      refTrailer.current.pause();
      refLoop.current.pause();
      refExplosion.current.pause();
      refLoop.current.classList.remove("top");
      refLoopOutro.current.classList.remove("top");
      refLoopOutro.current.currentTime = 0;
      refLoopOutro.current.pause();
      refExplosion.current.classList.remove("top");
      refTrailer.current.classList.remove("top");
      audio.current.pause();
      audio_hey_sehen_wir_uns_wieder.current.pause();
   };

   async function handleOutro() {
      refImplosion.current.currentTime = 0;
      showImplosionVideo();
      audio_hey_sehen_wir_uns_wieder.current.play();
      audio.current.play();
      await fadeIn();
      await playMovie({ movie: refImplosion, time: 2800 });
      showLoopOutroVideo();
      refLoopOutro.current.play();
      goToSlide(37);
   }

   const fadeIn = () => {
      return new Promise((resolve, reject) => {
         setHide("fadeIn");
         setTimeout(() => {
            resolve();
         }, 12000); //check animations.scss transition time of fadeIn
      });
   };

   const fadeInSoft = () => {
      return new Promise((resolve, reject) => {
         setHide("fadeInSoft");
         setTimeout(() => {
            resolve();
         }, 3000); //check animations.scss transition time of fadeIn
      });
   };

   const fadeOut = () => {
      return new Promise((resolve, reject) => {
         setHide("fadeOut");
         setTimeout(() => {
            resolve();
         }, 500); //check animations.scss transition time of fadeIn
      });
   };

   //*******************************************************
   // Render
   //*******************************************************
   return (
      <div className={"bg-black fade " + hide} id="animation-box">
         <video
            ref={refLoop}
            className="mbb top object-fit-cover d-inline"
            autoPlay
            loop
         >
            <source src={intro_1} type="video/mp4" />
         </video>
         <video ref={refExplosion} className="mbb object-fit-cover d-inline">
            <source src={intro_2} type="video/mp4" />
         </video>
         <video
            ref={refTrailer}
            className="mbb bg-black d-inline object-fit-cover"
         >
            <source src={trailer_url} type="video/mp4" />
         </video>
         <video
            ref={refImplosion}
            className="mbb object-fit-cover bg-black d-inline"
         >
            <source src={implosion} type="video/mp4" />
         </video>
         <video
            ref={refLoopOutro}
            className="mbb object-fit-cover bg-black d-inline"
         >
            <source src={loopOutro} type="video/mp4" />
         </video>
         {/* <button className="position-absolute btn" onClick={handleClick1}>
            explode
         </button>
         <button className="position-absolute btn mt-5" onClick={handleClick2}>
            reset
         </button> */}
      </div>
   );
}
