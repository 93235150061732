import * as THREE from "three";
import { GUI } from "dat.gui";

export const removeSnow = (state) => {
    const current_snow = state?.scene?.children?.filter((child) => {
        return child?.userData?.isSnow
    });

    if (current_snow && current_snow?.length > 0){
        current_snow.forEach((point) => {
            point.removeFromParent();
        });
    }
};
