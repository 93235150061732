import { useStudentStore } from "../../store/appStore";
import { useState } from "react";
import { addStudentToRoom } from "../../services/servicesCrud";
import { auth } from "../../firebase/firebaseConfig";
import { signInAnonymously } from "firebase/auth";

export default function StudentLogin({ roomId }: { roomId: string }) {
   const [name, setName] = useState("");
   const [loading, setLoading] = useState(false);
   const student = useStudentStore((state) => state.student);
   const setStudent = useStudentStore((state) => state.setStudent);
   const goToSlide = useStudentStore((state) => state.setSliderIndex);

   async function handleSubmit(e: any) {
      e.preventDefault();
      console.log(name);
      if (!name) {
         console.warn("Bitte verrate uns deinen Namen");
         return false;
      }
      setLoading(true);
      await signInAnonymously(auth);
      addStudentToRoom(name, roomId).then((studentId) => {
         setStudent({
            id: studentId,
            name: name,
            questions: [],
            publish_with_name: null,
            result: "",
            progress: 0,
         });
         goToSlide(4);
         setLoading(false);
      });
   }

   return (
      <form onSubmit={handleSubmit}>
         <h2 className="h1 text-white text-center">Wie heißt du?</h2>
         <input
            type="text"
            name="name"
            id="name"
            value={name}
            className="me-2 w-100 text-center"
            onChange={(e: any) => setName(e.target.value)}
         />
         <input
            type="submit"
            value="Los geht´s!"
            className={
               "btn btn--1  w-100 mt-3" + (loading ? " btn--loading" : "")
            }
         />
      </form>
   );
}
