import { mountStoreDevtool } from "simple-zustand-devtools";
import create from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";
import {
   AppStore,
   EnumAnimationState,
   InterfaceFundus,
   InterfaceScene,
   RoomInterface,
   StudentInterface,
   StudentStore,
   TeacherStore,
} from "../types/types";

export const useRoomsStore = create<AppStore>()(
   devtools(
      persist(
         (set, get) => ({
            superUser: null,
            questions: [],
            rooms: [],
            keywords: [],
            keywordsOper: [],
            keywordsVisited: [],
            setQuestions: (questions) => {
               return set((state) => ({
                  questions: [...questions],
               }));
            },
            setRooms: (rooms) => {
               return set((state) => ({
                  rooms: [...rooms],
               }));
            },
            setKeywords: (keywords) => {
               return set((state) => ({
                  keywords: [...keywords],
               }));
            },
            setKeywordsOper: (keywords) => {
               return set((state) => ({
                  keywordsOper: [...keywords],
               }));
            },
            setKeywordsVisited: (keywords) => {
               return set((state) => ({
                  keywordsVisited: [...keywords],
               }));
            },
            setSuperUser(user) {
               return set(() => ({
                  superUser: { ...user },
               }));
            },
         }),
         {
            name: "appStore",
         }
      )
   )
);

if (process.env.NODE_ENV === "development") {
   mountStoreDevtool("Store", useRoomsStore);
}

export const useStudentStore = create<StudentStore>()(
   subscribeWithSelector(
      devtools(
         //if store should be persisted
         persist(
            (set, get) => ({
               animation: {
                  state: EnumAnimationState.default,
                  audioVolume: 0.2,
               },
               beStage: false,
               beStageMenu: false,
               assets: {
                  clones: [],
                  lights: [],
                  objects: [],
                  textures: [],
                  effects: "none",
               },
               fundus: null,
               student: null,
               chosenScene: null,
               sliderIndex: 1,
               consent: false,
               isModalActive: false,
               modalsInEditor: {
                  description: true,
                  objects3d: false,
                  final: false,
               },
               setModalsInEditor: (state: any) => {
                  set(() => ({
                     modalsInEditor: state,
                  }));
               },
               setIsModalActive: (state) => {
                  set(() => ({
                     isModalActive: state,
                  }));
               },
               setConsent: (consent) => {
                  set(() => ({
                     consent: consent,
                  }));
               },
               setBeStage: (state) => {
                  set(() => ({
                     beStage: state,
                  }));
               },
               setBeStageMenu: (state) => {
                  set(() => ({
                     beStageMenu: state,
                  }));
               },
               setAnimationState: (animationstate) => {
                  set((state) => ({
                     animation: {
                        ...state.animation,
                        state: animationstate,
                     },
                  }));
               },
               setAnimationVolume: (volume) => {
                  set((state) => ({
                     animation: {
                        ...state.animation,
                        audioVolume: volume,
                     },
                  }));
               },
               setFundus: (fundus: InterfaceFundus | null) => {
                  set(() => ({
                     fundus: fundus ? { ...fundus } : null,
                  }));
               },
               setAssets: (assets: any) => {
                  set(() => ({
                     assets: assets ? assets : null,
                  }));
               },
               setStudent: (student: StudentInterface) => {
                  set(() => ({
                     student: student ? { ...student } : null,
                  }));
               },
               setChosenScene: (scene: InterfaceScene) => {
                  set(() => ({
                     chosenScene: scene ? { ...scene } : null,
                  }));
               },
               setSliderIndex: (index: number) => {
                  set((state) => {
                     return {
                        sliderIndex: index,
                     };
                  });
               },
               incrementSliderIndex: () => {
                  set((state) => {
                     console.log(state.sliderIndex, state.sliderIndex + 1);
                     return {
                        sliderIndex: state.sliderIndex + 1,
                     };
                  });
               },
               decrementSliderIndex: () => {
                  set((state) => {
                     return {
                        sliderIndex: state.sliderIndex - 1,
                     };
                  });
               },
            }),
            {
               name: "studentStore",
            }
         )
      )
   )
);

if (process.env.NODE_ENV === "development") {
   mountStoreDevtool("Store", useStudentStore);
}

export const useTeacherStore = create<TeacherStore>()(
   devtools(
      persist(
         (set, get) => ({
            room: null,
            setRoom: (room: RoomInterface) => {
               set(() => ({
                  room: room ? { ...room } : null,
               }));
            },
         }),
         {
            name: "teacherStore",
         }
      )
   )
);

if (process.env.NODE_ENV === "development") {
   mountStoreDevtool("Store", useTeacherStore);
}
