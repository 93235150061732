const path = document.location.origin;
export const getAssets = () => {
   const assets = [
      {
         name: "tree_dead",
         path: `${path}/assets/230510_mbb_asset_Toter_Baum_v2_cs_hwmd.glb`,
         label: "Toter Baum",
      },
      {
         name: "tanne_mit_blumen",
         path: `${path}/assets/230531_mbb_tanne_schwarze_Blumen_01_hwmd.glb`,
         label: "Tanne mit Blumen",
      },
      {
         name: "tanne",
         path: `${path}/assets/230531_mbb_tanne_ohne_Blumen_01_hwmd.glb`,
         label: "Tanne",
      },
      {
         name: "moon",
         path: `${path}/assets/moon.gltf`,
         label: "Mond",
      },
      {
         name: "half_moon",
         path: `${path}/assets/230531_mbb_halbmond_hwmd.glb`,
         label: "Halbmond",
      },
      {
         name: "chair",
         path: `${path}/assets/sessel.glb`,
         label: "Sessel",
         scale: 2,
      },
      {
         name: "avatar",
         path: `${path}/assets/avatar.gltf`,
         label: "Avatar",
      },
      {
         name: "avatar_sitzend",
         path: `${path}/assets/230531_mbb_avatar_sitzend_05_hwmd.glb`,
         label: "Avatar sitzend",
      },
      {
         name: "bus_stop",
         path: `${path}/assets/230510_mbb_bushaltestelle_cs_V2_HWMD.glb`,
         label: "Bushaltestelle",
      },
      {
         name: "chameleon",
         path: `${path}/assets/230530_mbb_chameleon_cs_05_hwmd.glb`,
         label: "Kameleon",
         // scale: 2,
      },
      {
         name: "anger_emoji",
         path: `${path}/assets/230517_mbb_wutemoji_cs_hwmd.glb`,
         label: "Wutemoji",
      },
      {
         name: "neutral_emoji",
         path: `${path}/assets/230517_mbb_neutralemoji_cs_hwmd.glb`,
         label: "Neutralemoji",
      },
      {
         name: "kiss_emoji",
         path: `${path}/assets/230517_mbb_kissemoji_cs_hwmd.glb`,
         label: "Kissemoji",
      },
      {
         name: "cube",
         path: `${path}/assets/230517_mbb_lacage_cube_cs_hwmd.glb`,
         label: "Cube",
      },
      {
         name: "lantern",
         path: `${path}/assets/230517_mbb_laterne_cs_hwmd.glb`,
         label: "Laterne",
      },
      {
         name: "pfau",
         path: `${path}/assets/230517_mbb_pfau_cs_V13_hwmd.glb`,
         label: "Pfau",
      },

      {
         name: "smartphone",
         path: `${path}/assets/230517_mbb_smartphone_cs_V1_hwmd.glb`,
         label: "Smartphone",
      },
      {
         name: "unicorn",
         path: `${path}/assets/230517_mbb_unicorn_cs_03_hwmd.glb`,
         label: "Unicorn",
      },
      {
         name: "fox",
         path: `${path}/assets/230525_mbb_fuchs_cs_V7_hwmd.glb`,
         label: "Fuchs",
      },
      {
         name: "hochhaus",
         path: `${path}/assets/230607_mbb_buehne_lovesong_house_only_05_HW.glb`,
         label: "Hochhaus",
      },
      {
         name: "city",
         path: `${path}/assets/230531_mbb_buehne_lovesong_01_hwmd.glb`,
         label: "City",
      },

      // Alphabet Balon
      //--------------------------------------------------------
      {
         name: "und",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_und_hwmd.glb`,
         label: "&",
         userData: { isAlphabet: true },
      },
      {
         name: "fragezeichen",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_fragezeichen_hwmd.glb`,
         label: "?",
      },
      {
         name: "euro",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_euro_hwmd.glb`,
         label: "€",
      },
      {
         name: "ausrufezeichen",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_ausrufezeichen_hwmd.glb`,
         label: "!",
      },
      {
         name: "a",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_a_hwmd.glb`,
         label: "A",
         userData: { isAlphabet: true },
      },
      {
         name: "ae",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_ae_hwmd.glb`,
         label: "Ä",
         userData: { isAlphabet: true },
      },
      {
         name: "b",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_b_hwmd.glb`,
         label: "B",
         userData: { isAlphabet: true },
      },
      {
         name: "c",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_c_hwmd.glb`,
         label: "C",
         userData: { isAlphabet: true },
      },
      {
         name: "d",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_d_hwmd.glb`,
         label: "D",
         userData: { isAlphabet: true },
      },
      {
         name: "e",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_e_hwmd.glb`,
         label: "E",
         userData: { isAlphabet: true },
      },
      {
         name: "f",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_f_hwmd.glb`,
         label: "F",
         userData: { isAlphabet: true },
      },
      {
         name: "g",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_g_hwmd.glb`,
         label: "G",
         userData: { isAlphabet: true },
      },
      {
         name: "h",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_h_hwmd.glb`,
         label: "H",
         userData: { isAlphabet: true },
      },
      {
         name: "i",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_i_hwmd.glb`,
         label: "I",
         userData: { isAlphabet: true },
      },
      {
         name: "j",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_j_hwmd.glb`,
         label: "J",
         userData: { isAlphabet: true },
      },
      {
         name: "k",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_k_hwmd.glb`,
         label: "K",
         userData: { isAlphabet: true },
      },
      {
         name: "l",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_l_hwmd.glb`,
         label: "L",
         userData: { isAlphabet: true },
      },
      {
         name: "m",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_m_hwmd.glb`,
         label: "M",
         userData: { isAlphabet: true },
      },
      {
         name: "n",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_n_hwmd.glb`,
         label: "N",
         userData: { isAlphabet: true },
      },
      {
         name: "o",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_o_hwmd.glb`,
         label: "O",
         userData: { isAlphabet: true },
      },
      {
         name: "oe",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_oe_hwmd.glb`,
         label: "Ö",
         userData: { isAlphabet: true },
      },
      {
         name: "p",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_p_hwmd.glb`,
         label: "P",
         userData: { isAlphabet: true },
      },
      {
         name: "r",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_r_hwmd.glb`,
         label: "R",
         userData: { isAlphabet: true },
      },
      {
         name: "s",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_s_hwmd.glb`,
         label: "S",
         userData: { isAlphabet: true },
      },
      {
         name: "t",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_t_hwmd.glb`,
         label: "T",
         userData: { isAlphabet: true },
      },
      {
         name: "u",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_u_hwmd.glb`,
         label: "U",
         userData: { isAlphabet: true },
      },
      {
         name: "ue",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_ue_hwmd.glb`,
         label: "Ü",
         userData: { isAlphabet: true },
      },
      {
         name: "v",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_v_hwmd.glb`,
         label: "V",
         userData: { isAlphabet: true },
      },
      {
         name: "w",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_w_hwmd.glb`,
         label: "W",
         userData: { isAlphabet: true },
      },
      {
         name: "x",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_x_hwmd.glb`,
         label: "X",
         userData: { isAlphabet: true },
      },
      {
         name: "y",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_y_hwmd.glb`,
         label: "Y",
         userData: { isAlphabet: true },
      },
      {
         name: "z",
         path: `${path}/assets/alphabet/230530_ballon_alphabet_z_hwmd.glb`,
         label: "Z",
         userData: { isAlphabet: true },
      },
      // NEON ALPHABET
      //--------------------------------------------------------
      {
         name: "neon_fragezeichen",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_fragezeichen_hwmd.glb`,
         label: "Neon ?",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_ausrufezeichen",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_ausrufezeichen_hwmd.glb`,
         label: "Neon !",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_punkt",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_punkt_hwmd.glb`,
         label: "Neon .",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_a",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_a_hwmd.glb`,
         label: "Neon A",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_b",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_b_hwmd.glb`,
         label: "Neon B",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_c",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_c_hwmd.glb`,
         label: "Neon C",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_d",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_d_hwmd.glb`,
         label: "Neon D",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_e",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_e_hwmd.glb`,
         label: "Neon E",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_f",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_f_hwmd.glb`,
         label: "Neon F",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_g",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_g_hwmd.glb`,
         label: "Neon G",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_h",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_h_hwmd.glb`,
         label: "Neon H",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_i",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_i_hwmd.glb`,
         label: "Neon I",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_j",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_j_hwmd.glb`,
         label: "Neon J",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_k",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_k_hwmd.glb`,
         label: "Neon K",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_l",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_l_hwmd.glb`,
         label: "Neon L",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_m",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_m_hwmd.glb`,
         label: "Neon M",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_n",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_n_hwmd.glb`,
         label: "Neon N",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_o",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_o_hwmd.glb`,
         label: "Neon O",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_p",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_p_hwmd.glb`,
         label: "Neon P",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_r",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_r_hwmd.glb`,
         label: "Neon R",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_s",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_s_hwmd.glb`,
         label: "Neon S",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_t",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_t_hwmd.glb`,
         label: "Neon T",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_u",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_u_hwmd.glb`,
         label: "Neon U",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_v",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_v_hwmd.glb`,
         label: "Neon V",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_w",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_w_hwmd.glb`,
         label: "Neon W",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_x",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_x_hwmd.glb`,
         label: "Neon X",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_y",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_y_hwmd.glb`,
         label: "Neon Y",
         userData: { isAlphabetNeon: true },
      },
      {
         name: "neon_z",
         path: `${path}/assets/alphabet_neon/230530_neon_alphabet_z_hwmd.glb`,
         label: "Neon Z",
         userData: { isAlphabetNeon: true },
      },

      // AVANTGARDE
      //--------------------------------------------------------

      {
         name: "avantgarde_raute",
         path: `${path}/assets/alphabet_avantgarde/230531_mbb_avantgarde_alphabet_raute_01_hwmd.glb`,
         label: "#",
         userData: { isAlphabetAvantgarde: true },
      },
      {
         name: "avantgarde_undzeichen",
         path: `${path}/assets/alphabet_avantgarde/230531_mbb_avantgarde_alphabet_und_01_hwmd.glb`,
         label: "&",
         userData: { isAlphabetAvantgarde: true },
      },
      {
         name: "avantgarde_sternchen",
         path: `${path}/assets/alphabet_avantgarde/230531_mbb_avantgarde_alphabet_sternchen_01_hwmd.glb`,
         label: "*",
         userData: { isAlphabetAvantgarde: true },
      },
      {
         name: "avantgarde_fragezeichen",
         path: `${path}/assets/alphabet_avantgarde/230531_mbb_avantgarde_alphabet_fragezeichen_01_hwmd.glb`,
         label: "?",
         userData: { isAlphabetAvantgarde: true },
      },
      {
         name: "avantgarde_euro",
         path: `${path}/assets/alphabet_avantgarde/230531_mbb_avantgarde_alphabet_euro_01_hwmd.glb`,
         label: "€",
         userData: { isAlphabetAvantgarde: true },
      },
      {
         name: "avantgarde_aet",
         path: `${path}/assets/alphabet_avantgarde/230531_mbb_avantgarde_alphabet_aet_01_hwmd.glb`,
         label: "@",
         userData: { isAlphabetAvantgarde: true },
      },

      // TEXTURES
      //--------------------------------------------------------
      {
         name: "texture_wir_ihr_cyan_grey",
         path: `${path}/assets/textures/textures_wir_ihr/230513_wirihr_cyan_grey_hwmd.png`,
         label: "Wir Ihr Cyan Grey",
         userData: { isTexture: true },
      },
      {
         name: "texture_wir_ihr_orange_anthrazit",
         path: `${path}/assets/textures/textures_wir_ihr/230513_wirihr_orange_anthrazit_hwmd.png`,
         label: "Wir Ihr Orange Anthrazit",
         userData: { isTexture: true },
      },
      {
         name: "texture_wir_ihr_pink_grey",
         path: `${path}/assets/textures/textures_wir_ihr/230513_wirihr_pink_grey_hwmd.png`,
         label: "Wir Ihr Orange Anthrazit",
         userData: { isTexture: true },
      },
      {
         name: "texture_wir_ihr_orange_blue",
         path: `${path}/assets/textures/textures_wir_ihr/230513_wirihr_orange_blue_hwmd.png`,
         label: "Wir Ihr Orange Anthrazit",
         userData: { isTexture: true },
      },
      {
         name: "texture_bricks",
         path: `${path}/assets/textures/brick_wall/230601_mbb_textur_brickwall_graffiti_seite_links_hwmd.jpg`,
         label: "Backstein",
         userData: { isTexture: true },
      },
      {
         name: "texture_bricks_dark",
         path: `${path}/assets/textures/brick_wall/brick_wall_back_graffiti_12.jpg`,
         label: "Backstein dunkel",
         userData: { isTexture: true },
      },
      {
         name: "texture_asphalt",
         path: `${path}/assets/textures/asphalt.jpg`,
         label: "Asphalt",
         userData: { isTexture: true },
      },
      {
         name: "texture_wasser_1",
         path: `${path}/assets/textures/textures_wasser/230513_wasser_v1.jpg`,
         label: "Wasser",
         userData: { isTexture: true },
      },
      {
         name: "texture_wasser_2",
         path: `${path}/assets/textures/textures_wasser/230513_wasser_V3B_dark.jpg`,
         label: "Wasser Dunkel",
         userData: { isTexture: true },
      },
      {
         name: "texture_reset",
         path: `${path}/assets/textures/reset.png`,
         label: "Reset",
         userData: { isTextureReset: true },
      },
      {
         name: "texture_back_wall_reset",
         path: `${path}/assets/textures/reset_back_wall.jpg`,
         label: "Reset Back Wall",
         userData: { isTextureReset: true },
      },
      {
         name: "texture_floor_reset",
         path: `${path}/assets/textures/reset_floor.jpg`,
         label: "Reset Floor",
         userData: { isTextureReset: true },
      },

      // Videos
      //--------------------------------------------------------

      {
         name: "texture_video_pexels",
         path: `${path}/assets/textures/textures_video/230530HWMD1645AE_pexels-mart-production-7565427_422.mp4`,
         label: "Pexels",
         userData: { isVideoTexture: true },
      },
      {
         name: "texture_video_pexels_film_composite",
         path: `${path}/assets/textures/textures_video/230530HWMD1700AE_pexels-film-composite-16392052_loop_422.mp4`,
         label: "Composite",
         userData: { isVideoTexture: true },
      },
      {
         name: "texture_video_stripes_flat_transition",
         path: `${path}/assets/textures/textures_video/230530HWMD1730AE_stripes_flat_transition_wwwcutestockfootagecom.mp4`,
         label: "Flat Transition",
         userData: { isVideoTexture: true },
      },
      {
         name: "texture_video_don_giovani",
         path: `${path}/assets/textures/textures_video/230627HWMD1855AE_mbb_don-gio_videotextur_letterbox_V1.mp4`,
         label: "Don Giovani",
         userData: { isVideoTexture: true },
      },
   ];

   // if (process.env.NODE_ENV == "production") {
   if (true) {
      const big_assets = [
         {
            name: "grass",
            path: `${path}/assets/200523_mbb_rasen_V1_hwmd.glb`,
            label: "Rasen",
         },
         {
            name: "rocks",
            path: `${path}/assets/230531_mbb_freischuetz_felsen_V2_hwmd.glb`,
            label: "Freischützfelsen",
         },
         {
            name: "3d_teppich",
            path: `${path}/assets/230704_mbb_flocati_01_hwmd.glb`,
            label: "Teppich",
         },
      ];

      return [...big_assets, ...assets];
   }

   return assets;
};
