import { Link, useParams } from "react-router-dom";
import IconLogo from "../Icons/IconLogo";
import PageServiceNav from "./PageServiceNav";
import { pages } from "./pagesContent";

export default function Page() {
   const { slug } = useParams();

   const title = slug ? pages[slug].title : null;
   const content = slug ? pages[slug].content : null;

   if (!title) return null;

   return (
      <div className="bg-gradient mh-100vh">
         <div className="logo">
            <div className="container">
               <IconLogo color={"#fff"} />
            </div>
         </div>
         <div className="container pt--200 pb-5">
            <div className="row">
               <div className="col-lg-8 m-auto bg-transparent p-5 border-rounded">
                  <Link to="/" className="mb-4">
                     &#8592; zurück
                  </Link>
                  <h2 className="h1 mb-5">{title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                  <Link to="/" className="mb-4">
                     &#8592; zurück
                  </Link>
               </div>
            </div>

            <div className="mt-5">
               <PageServiceNav />
            </div>
         </div>
      </div>
   );
}
