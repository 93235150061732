import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import hoere_dir_das from "../../../audio/scenes/hoere_dir_das_ganze_beispiel.mp3";

export default function Slide16ArieEdit() {
   // transition effect
   //--------------------------------------------------------
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);

   // methods
   //--------------------------------------------------------
   const scene = useStudentStore((state) => state.chosenScene);
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const prevSlide = useStudentStore((state) => state.decrementSliderIndex);

   // audio
   //--------------------------------------------------------
   let audio: any = useRef();
   let audioIntro: any = useRef();

   // cycle
   //--------------------------------------------------------
   useEffect(() => {
      setInProp(true);

      return () => {
         audio?.current?.pause();
         audioIntro?.current?.pause();
      };
   }, []);

   useEffect(() => {
      if (scene) {
         audioIntro.current = new Audio(hoere_dir_das);
         audio.current = scene ? new Audio(scene.audioFull) : null;

         audioIntro.current.play();
         audioIntro.current.onended = () => {
            audio.current.play();
            audio.current.onended = () => {
               nextSlide();
            };
         };
      }

      return () => {
         audio?.current?.pause();
         audioIntro?.current?.pause();
      };
   }, [scene]);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div
               className={
                  "text-white mh-100vh position-relative container d-flex align-items-center justify-content-center flex-column pb-3"
               }
            >
               <img
                  className="mb-4 mt-5"
                  width={300}
                  src={scene?.image}
                  alt=""
               />

               {scene && scene.subtitles && (
                  <div className="col-md-8 text-center subtitles mb-4">
                     <div
                        className="mb-2"
                        dangerouslySetInnerHTML={{ __html: scene.subtitles }}
                     />
                  </div>
               )}

               <div className="container mt-auto">
                  <div className="row">
                     <div className="col-3 offset-1 d-flex align-items-end justify-content-center flex-column">
                        <button
                           type="button"
                           onClick={() => prevSlide()}
                           className="btn btn--circle btn--circle-micro"
                        >
                           Zurück
                        </button>
                     </div>
                     <div className="col-4 d-flex align-items-center flex-column">
                        <h4 className="h3 mb-2 mt-auto text-center">
                           {scene?.title}
                        </h4>
                        <p className="mb-0">{scene?.meta}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </CSSTransition>
   );
}
