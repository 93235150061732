import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import StudentLogin from "../../Student/StudentLogin";
import { useParams } from "react-router-dom";
import { getDocument } from "../../../services/servicesRead";
import { useStudentStore } from "../../../store/appStore";

export default function Slide1() {
   const [room, setRoom] = useState<any>(null);
   const { roomId } = useParams();
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const setAnimationVolume = useStudentStore(
      (state) => state.setAnimationVolume
   );

   useEffect(() => {
      setInProp(true);
      setAnimationVolume(0.2);
   }, []);

   useEffect(() => {
      if (roomId)
         getDocument({ collectionName: "Rooms", docId: roomId }).then(
            (data: any) => {
               setRoom(data);
            }
         );
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="container">
               <div className="row">
                  <div className="col-md-6 m-auto">
                     <StudentLogin roomId={roomId ? roomId : ""} />
                  </div>
               </div>
               {/* <div className="row mt-5 text-white">
                  <div className="col-3">Klassenraum:</div>
                  <div className="col-8">{room?.name}</div>
               </div>
               <div className="row mb-2 text-white">
                  <div className="col-3">RoomId:</div>
                  <div className="col-8">{roomId}</div>
               </div> */}
            </div>
         </div>
      </CSSTransition>
   );
}
