import { throwClone, throwClonesOnRandomPosition } from "../../control/clones";

export const lenz = (state) => {
  const scenePositionsNature = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0b8271c2-ec92-4d3c-9223-467770489b73",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: 13,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "6e20eafe-36b6-4337-8fa9-384ee0a3a289",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "bb99f93d-da4f-48e2-8115-385fd0b894d5",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "643e187e-91d6-46d9-b896-7afb373b98be",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "f0b12885-eaa0-4f47-9ede-1eedffa5bbaa",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "f46c5aaa-2cf4-42ab-b25b-059877de1808",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "4c682d74-dc3e-4414-a84d-88b423df6131",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "tanne",
      uuid: "d8862232-067a-439d-a2d4-219e3823079f",
      position: { x: 6, y: 18.45293948185346, z: -6 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne",
      uuid: "57f56c1f-f562-4446-849e-893b658b39ce",
      position: { x: -11, y: 21.34469950442938, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne",
      uuid: "15fbe842-a1d7-496d-afc0-6057339c6023",
      position: { x: -5, y: 15.578058933455239, z: -11 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne",
      uuid: "55e31e8a-1517-4dd0-a6f9-dbc9b163bac0",
      position: { x: 0, y: 14.837232887382154, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne",
      uuid: "b7c365f1-264f-4a0d-9d58-17d3c8c3fb3a",
      position: { x: 15, y: 13.959472027685944, z: 6 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "b0e4437d-e876-4e4a-aec1-d5e0574b4801",
      position: { x: -4, y: -1.5976828835240617, z: -5 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "c6b1816b-97d1-4e43-93dc-6b01c74936a6",
      position: { x: -1, y: -0.055661745905732196, z: 4 },
      rotation: 0.4,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "7ac6cd81-9270-495a-91e9-767279026c14",
      position: { x: 13, y: 0.04780685596356171, z: 7 },
      rotation: 2.1999999999999997,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "8395a852-0172-471f-a659-7efe17ae00ed",
      position: { x: -5, y: -0.3414339062953913, z: 9 },
      rotation: 2.6,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne_mit_blumen",
      uuid: "387203b6-ac42-4d4b-b787-e706ab51c09b",
      position: { x: 13, y: 18.086783569350363, z: -1 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne_mit_blumen",
      uuid: "69d55104-5182-4ec3-9b72-72a9443efd48",
      position: { x: -12, y: 17.468261434315384, z: -7 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsNature = [];
  scenePositionsNature.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsNature.push(avatar);
  });

  let moonsNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "moon") moonsNature.push(elem);
  });

  let moonsHalfNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "half_moon") moonsHalfNature.push(elem);
  });

  const scenePositionsPhantasy = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "6",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "grass",
      uuid: "9e85571e-81ba-4743-860d-22deb22c50c4",
      position: { x: 0, y: 0, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tree_dead",
      uuid: "12118874-0578-4228-89c9-12f97a713a6c",
      position: { x: 10, y: -4.899765715147184, z: 0 },
      rotation: 0,
      scale: 2.125456,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "Unicorn",
      uuid: "9b15dcd8-da22-4f72-89c2-efaff2b6f4a4",
      position: { x: -5, y: -5.787548337774982, z: 17 },
      rotation: 0,
      scale: 2.309358,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "e0ba9f67-3b02-49d8-aa06-83ea56399999",
      position: { x: 4, y: 6.680811841438457, z: -10 },
      rotation: 0,
      scale: 0.6,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "c234e080-7035-46cf-9ce3-cda1d9f10c83",
      position: { x: -7, y: -0.26922792150785513, z: 3 },
      rotation: 3.800000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "a31e401d-c072-48ce-93a4-88f97827908b",
      position: { x: 5, y: 10.64067687987165, z: 10 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "3c60cc00-d715-492a-ad94-ab1c3e263729",
      position: { x: 13, y: 8.432683856131876, z: 6 },
      rotation: 3.0000000000000004,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsPhantasy = [];
  scenePositionsPhantasy.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsPhantasy.push(avatar);
  });

  let moonsPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "moon") moonsPhantasy.push(elem);
  });

  let moonsHalfPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "half_moon") moonsHalfPhantasy.push(elem);
  });

  const scenePositionsCity = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "10",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "7",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Front_Light",
      uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "hochhaus",
      uuid: "400dd904-152c-46ac-b950-39f5c482ba3b",
      position: { x: -5, y: 3.6545244503182044, z: -5 },
      rotation: 15.799999999999976,
      scale: 1.4,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "hochhaus",
      uuid: "befbd118-9ed0-4c56-a69a-d37d50ee3f03",
      position: { x: 12, y: 4.475160382848722, z: 6 },
      rotation: 0,
      scale: 1.463727,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "e8cb33f0-255f-41c0-b52d-06aab1be0932",
      position: { x: 9, y: 2.05863102471412, z: -8 },
      rotation: 0,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "3e639455-d997-4670-b6a8-3c3e93787307",
      position: { x: -2, y: -0.5743843727240577, z: -2 },
      rotation: 0.4,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "c1556023-453e-45dc-b79f-f624d554a393",
      position: { x: 5, y: -0.19545360031720524, z: 8 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "d638358e-3052-47a2-986b-bac0610d83d3",
      position: { x: 14, y: 0.45341849938009027, z: 9 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsCity = [];
  scenePositionsCity.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsCity.push(avatar);
  });

  let moonsCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "moon") moonsCity.push(elem);
  });

  let moonsHalfCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "half_moon") moonsHalfCity.push(elem);
  });

  return {
    lenz_nature() {
      scenePositionsNature.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            if (currentLight) {
              currentLight.visible = position.visible;
              currentLight.intensity = position.intensity;
            }
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    lenz_nature_moon_full() {
      moonsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_nature_moon_half() {
      moonsHalfNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_nature_moon_new() {
      // ToDo
      console.log("lenz_nature_moon_new");
    },

    lenz_nature_people_1() {
      const avatars1 = avatarsNature.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_nature_people_2() {
      const avatars2 = avatarsNature.slice(0, 2);
      avatars2.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_nature_people_3() {
      avatarsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_nature_people_4() {
      // ToDo
      console.log("lenz_nature_people_4");
    },

    lenz_phantasy() {
      scenePositionsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            if (currentLight) {
              currentLight.visible = position.visible;
              currentLight.intensity = position.intensity;
            }
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    lenz_phantasy_moon_full() {
      moonsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_phantasy_moon_half() {
      moonsHalfPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_phantasy_moon_new() {
      // Too
      console.log("lenz_phantasy_moon_new");
    },

    lenz_phantasy_people_1() {
      const avatars1 = avatarsPhantasy.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_phantasy_people_2() {
      const avatars2 = avatarsPhantasy.slice(0, 2);
      avatars2.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_phantasy_people_3() {
      avatarsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },
    lenz_phantasy_people_4() {
      throwClonesOnRandomPosition(state, 4, "avatar");
    },

    lenz_city() {
      scenePositionsCity.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            if (currentLight) {
              currentLight.visible = position.visible;
              currentLight.intensity = position.intensity;
            }
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    lenz_city_moon_full() {
      moonsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_city_moon_half() {
      moonsHalfCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_city_moon_new() {
      // ToDo
      console.log("lenz_city_moon_new");
    },

    lenz_city_people_1() {
      const avatars1 = avatarsCity.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_city_people_2() {
      const avatars1 = avatarsCity.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_city_people_3() {
      avatarsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    lenz_city_people_4() {
      // ToDo
      console.log("lenz_city_people_4");
    },
  };
};
