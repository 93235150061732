import { throwClone, throwClonesOnRandomPosition } from "../../control/clones";

export const part_wir_ihr = (state) => {
  const scenePositionsNature = [
    {
      name: "Spotlight_Top_Light",
      uuid: "c1dff20c-cb9c-4916-bbf5-15c436da24c9",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: 13,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "eb3c66e3-a888-4d76-abd4-dda068253162",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "5b529bf1-f2d8-4f18-bbed-8b7744b52fcd",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: 7.5,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "b9a79946-346d-4c2d-91e3-59e2ff099ccf",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "43a09001-b5f5-4141-a01f-cdf042145790",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "29dfdaf0-d207-49cd-aeb1-7aaa9ccff4a9",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "d7e50c40-9854-4af7-a4f2-01c57007278a",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "grass",
      uuid: "72356084-0653-4435-8943-1915fbea655d",
      position: { x: 0, y: 0, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neon_w",
      uuid: "4c0b9575-c08c-45d5-a9ab-0e3459eddf4d",
      position: { x: -3, y: 4.842044592401227, z: 0 },
      rotation: 0,
      scale: 0.6,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neon_h",
      uuid: "85ccc687-f180-4dc3-bcb2-1d1a13be8bd3",
      position: { x: 4, y: 12.682520030232492, z: -18 },
      rotation: 0,
      scale: 0.8,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neon_r",
      uuid: "2b33e000-fb96-45d6-a0e1-ce26115feb79",
      position: { x: 7, y: 11.9118463175913, z: -17 },
      rotation: 0,
      scale: 0.8,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "1debd63b-0282-4bb3-aefa-f79faa4366f9",
      position: { x: 0, y: -13.265401338124397, z: 0 },
      rotation: 0,
      scale: 2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "3031530f-1f7c-411c-a033-05c3189626d3",
      position: { x: -3, y: 0.19966561569509442, z: -2 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "d060c877-e230-4b56-8c69-1fc7e5f9ce63",
      position: { x: -2, y: 0.11259790527681629, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "a6923277-748c-4191-9aa0-bcabb825cee6",
      position: { x: 5, y: 0.1778302211112076, z: -9 },
      rotation: 0.4,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsNature = [];
  scenePositionsNature.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsNature.push(avatar);
  });

  let moonsNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "moon") moonsNature.push(elem);
  });

  let moonsHalfNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "half_moon") moonsHalfNature.push(elem);
  });

  const scenePositionsPhantasy = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0cedede5-879d-4fff-b215-2ec3a5438fee",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: 13,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "bf37ab21-b7ce-4c1e-ada9-60e8919f403e",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "46e12acb-7528-483b-bfa5-a7f06e4f8f7c",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "600b6e28-a262-4e87-b9b5-3e3391130f7c",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Left_Light",
      uuid: "19a3be81-f245-40cc-80e1-d1cd5c71a8af",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "99fb26f7-dcd8-41b0-a7ea-7c545c07f44a",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "91230c72-dece-48cd-ba37-3802226cadde",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "w",
      uuid: "4e821a86-7d95-4086-9bd7-2cce660f81fb",
      position: { x: -11, y: 15.49494322730252, z: 0 },
      rotation: 5.600000000000002,
      scale: 1.513589,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "i",
      uuid: "204e8348-75c7-4e39-bbe9-afac4d3fd333",
      position: { x: 0, y: 12.826322912505136, z: -11 },
      rotation: 0,
      scale: 1.895654,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "r",
      uuid: "b44a1354-796e-4d26-8175-39d2ab9e39a1",
      position: { x: 12, y: 14.785942628875251, z: -5 },
      rotation: 2.1999999999999997,
      scale: 1.683217,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "h",
      uuid: "08f207eb-256a-4787-9e97-b283be28485b",
      position: { x: 0, y: 18.616266685369474, z: -1 },
      rotation: 0,
      scale: 2.948014,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "r",
      uuid: "cf35ec60-7c03-46ab-bf16-03ab2a66d815",
      position: { x: -2, y: 6.692928123588431, z: 5 },
      rotation: 0.6000000000000001,
      scale: 1.154303,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "fragezeichen",
      uuid: "d112b402-7c4d-45fa-af95-f34fc048b3d5",
      position: { x: 12, y: 11.48964231983978, z: 6 },
      rotation: 1.5999999999999999,
      scale: 0.616981,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "a5ef691a-92ad-4599-8e4b-c844f903fb20",
      position: { x: -8, y: -3.875316688432831, z: -12 },
      rotation: 0,
      scale: 0.8,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "affab1b2-94b5-49d6-b60f-0ddf8ba55acf",
      position: { x: 11, y: 15.7776782496553, z: -4 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "bc4ce2f8-ecef-4910-8757-d40b20759e8b",
      position: { x: -8, y: 14.200102476808615, z: 3 },
      rotation: 6.200000000000003,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "bc831b4e-b08f-4fd4-ba99-8967266ec5a0",
      position: { x: -2, y: 0.9496366694472378, z: 9 },
      rotation: 2.6,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsPhantasy = [];
  scenePositionsPhantasy.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsPhantasy.push(avatar);
  });

  let moonsPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "moon") moonsPhantasy.push(elem);
  });

  let moonsHalfPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "half_moon") moonsHalfPhantasy.push(elem);
  });

  const scenePositionsCity = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "6",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "lantern",
      uuid: "3620df79-ef1e-457e-9141-87ef88755a55",
      position: { x: -15, y: -0.5459060120606078, z: 8 },
      rotation: 1.5999999999999999,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "lantern",
      uuid: "cc8747d3-5c53-4307-aefb-f563e90b8291",
      position: { x: -11, y: -0.5964684486861169, z: -3 },
      rotation: 1.5999999999999999,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "lantern",
      uuid: "b1684911-bd80-43fb-ab34-a3fc419122a6",
      position: { x: -7, y: -0.5175900379737007, z: -13 },
      rotation: 1.5999999999999999,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "lantern",
      uuid: "e0e22822-0359-446e-bd27-a3f3d9e3cb71",
      position: { x: 14, y: -1.3518036827330715, z: 8 },
      rotation: 10.999999999999993,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "lantern",
      uuid: "2dd59097-7f5b-4d0b-984b-3c30f87582c7",
      position: { x: 10, y: -0.685807130874688, z: -2 },
      rotation: 4.600000000000001,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "lantern",
      uuid: "587a3d05-aab9-4c0a-b498-f05543489e7d",
      position: { x: 7, y: -1.1269673046234872, z: -13 },
      rotation: 4.800000000000002,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "d62d624d-80c6-482a-bff3-dec76fac4cd5",
      position: { x: 0, y: 14.807922148345567, z: -16 },
      rotation: 0,
      scale: 0.528267,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "994c863a-514f-45a7-9321-7b744f3e2bfb",
      position: { x: 8, y: 0.21216391444078023, z: -4 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "170dd25f-ddfa-4e90-9ac8-f1a293508678",
      position: { x: -11, y: -0.5537973247432263, z: -14 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "08e199f8-61fe-4308-b5fb-532af9353adf",
      position: { x: 9, y: 0.13578283610441877, z: -3 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsCity = [];
  scenePositionsCity.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsCity.push(avatar);
  });

  let moonsCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "moon") moonsCity.push(elem);
  });

  let moonsHalfCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "half_moon") moonsHalfCity.push(elem);
  });

  return {
    wir_ihr_where_nature() {
      scenePositionsNature.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    wir_ihr_nature_moon_full() {
      moonsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_nature_moon_half() {
      moonsHalfNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_nature_moon_new() {
      // ToDo
      console.log("wir_ihr_nature_moon_new");
    },

    wir_ihr_nature_people_1() {
      const avatars1 = avatarsNature.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_nature_people_2() {
      const avatars1 = avatarsNature.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_nature_people_3() {
      avatarsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_nature_people_4() {
      // ToDo
      console.log("wir_ihr_nature_people_4");
    },

    wir_ihr_where_phantasy() {
      scenePositionsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    wir_ihr_phantasy_moon_full() {
      moonsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_phantasy_moon_half() {
      moonsHalfPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_phantasy_moon_new() {
      // ToDo
      console.log("wir_ihr_phantasy_moon_new");
    },

    wir_ihr_phantasy_people_1() {
      const avatars1 = avatarsPhantasy.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_phantasy_people_2() {
      const avatars1 = avatarsPhantasy.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_phantasy_people_3() {
      avatarsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_phantasy_people_4() {
      // ToDo
      console.log("wir_ihr_phantasy_people_4");
    },

    wir_ihr_where_city() {
      scenePositionsCity.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    wir_ihr_city_moon_full() {
      moonsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_city_moon_half() {
      moonsHalfCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_city_moon_new() {
      // ToDo
      console.log("wir_ihr_city_moon_new");
    },

    wir_ihr_city_people_1() {
      const avatars1 = avatarsCity.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_city_people_2() {
      const avatars1 = avatarsCity.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_city_people_3() {
      avatarsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    wir_ihr_city_people_4() {
      // ToDo
      console.log("wir_ihr_city_people_4");
    },
  };
};
