import { throwClone, throwClonesOnRandomPosition } from "../../control/clones";

export const part_don_giovanni = (state) => {
  const scenePositionsNature = [
    {
      name: "Spotlight_Top_Light",
      uuid: "553d6e28-ab50-42ac-b81b-69386eb09aa3",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: 13,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "096330d7-93ad-457e-abea-44dc2ba35e37",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: "5",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "fee205ee-69c0-43f0-829c-837f5f0673bf",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "8cd5569b-66ef-4a30-9b7d-6866ac9f0a80",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Left_Light",
      uuid: "fc73060a-2d7d-49d6-8967-7c1882c6d48f",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "dcccb24a-b737-4ade-bfb5-910b39dbb249",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "39fe40d1-9287-48c5-9b8f-77fa97b0a474",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "tree_dead",
      uuid: "fb2b8d04-b48d-4518-ad41-93eb2fcfdb6b",
      position: { x: 2, y: -2.4009896844837106, z: 7 },
      rotation: 0,
      scale: 1.562675,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tree_dead",
      uuid: "4ae3c1f5-b3f2-4f62-a0dc-a88726c90976",
      position: { x: 11, y: -1.6828316813104849, z: -8 },
      rotation: 0,
      scale: 1.450421,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tree_dead",
      uuid: "59ca0464-2e68-4457-8d35-f495f97482bf",
      position: { x: 27, y: -4.864299107154042, z: 5 },
      rotation: 0,
      scale: 2.215223,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "3e2b03d0-5dd9-449b-8cad-f7ed01422576",
      position: { x: 14, y: -0.6842315089408377, z: -8 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "3fca283a-d666-4445-bd68-a441a77ce650",
      position: { x: 4, y: -1.5784023312024036, z: 2 },
      rotation: 5.400000000000002,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "32e82a93-9ed4-48af-ae3a-a16b74f3d07e",
      position: { x: 13, y: 15.117948590398587, z: -7 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "fc96d3c1-32d6-4531-b2cb-36292860960c",
      position: { x: 11, y: 16.172557444305998, z: -7 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsNature = [];
  scenePositionsNature.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsNature.push(avatar);
  });

  let moonsNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "moon") moonsNature.push(elem);
  });

  let moonsHalfNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "half_moon") moonsHalfNature.push(elem);
  });

  const scenePositionsPhantasy = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "6",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Left_Light",
      uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "5",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Front_Light",
      uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "smartphone",
      uuid: "909e8a49-ff6c-4349-b6bc-357b70a6440a",
      position: { x: 0, y: 1.1681449429169775, z: -5 },
      rotation: 0.6000000000000001,
      scale: 0.8,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "fb72581c-ec7f-416c-b3f0-5b499e5f5cea",
      position: { x: 14, y: 5.850617391059448, z: -10 },
      rotation: 0,
      scale: 0.8,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "d11e2d15-1fdf-4770-97e2-f10e5aa83553",
      position: { x: -7, y: 0.40512692908702197, z: -4 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "cff7c4aa-9f8f-4317-88b2-0fd9ea55733b",
      position: { x: -2, y: 0.03848028970517686, z: 1 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "b50be0f2-23d6-49db-acec-a51f478c0ce0",
      position: { x: 6, y: 0.21899146009345824, z: -7 },
      rotation: 2.8000000000000003,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsPhantasy = [];
  scenePositionsPhantasy.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsPhantasy.push(avatar);
  });

  let moonsPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "moon") moonsPhantasy.push(elem);
  });

  let moonsHalfPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "half_moon") moonsHalfPhantasy.push(elem);
  });

  let scenePositionsCity = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "10",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "neon_c",
      uuid: "0018ef00-f6e9-4f77-b9e9-14eaa4dd4a4f",
      position: { x: -9, y: 12.504545945443605, z: -8 },
      rotation: 5.8000000000000025,
      scale: 2.780919,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neon_i",
      uuid: "51e31315-bc28-405b-834e-b94cc3761075",
      position: { x: -3, y: 8.876571035482872, z: -4 },
      rotation: 5.8000000000000025,
      scale: 1.734284,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neon_t",
      uuid: "418e322a-7696-4a86-8a18-3789f259ecf6",
      position: { x: 3, y: 10.737699384133874, z: -2 },
      rotation: 12.199999999999989,
      scale: 3.178859,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neon_y",
      uuid: "c38971c4-f7b2-4494-9ff7-84a0588e2518",
      position: { x: 10, y: 8.495574714522117, z: 0 },
      rotation: 12.199999999999989,
      scale: 1.941588,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "abaf4554-6d8e-408a-9141-6b57516a51f4",
      position: { x: 12, y: 6.7929337982602505, z: -12 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "c690b1d7-842e-4d91-8b2a-e803cd697075",
      position: { x: 0, y: 10.003571958819057, z: -3 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "4e3c325b-7ecf-46d0-a435-7239562b2442",
      position: { x: -8, y: 0.7907467060641271, z: -11 },
      rotation: 9.799999999999997,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "158c111a-1c7a-4ad1-bc4a-4cd53419509e",
      position: { x: 2, y: 0.1467459491529139, z: -2 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsCity = [];
  scenePositionsCity.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsCity.push(avatar);
  });

  let moonsCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "moon") moonsCity.push(elem);
  });

  let moonsHalfCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "half_moon") moonsHalfCity.push(elem);
  });

  return {
    don_giovanni_where_nature() {
      scenePositionsNature.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    don_giovanni_nature_moon_full() {
      moonsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_nature_moon_half() {
      moonsHalfNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_nature_moon_new() {
      // ToDo
      console.log("don_giovanni_nature_moon_new");
    },

    don_giovanni_nature_people_1() {
      const avatars1 = avatarsNature.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_nature_people_2() {
      const avatars2 = avatarsNature.slice(0, 2);
      avatars2.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_nature_people_3() {
      avatarsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_nature_people_4() {
      // ToDo
      console.log("don_giovanni_nature_people_4");
    },

    don_giovanni_where_phantasy() {
      scenePositionsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    don_giovanni_phantasy_moon_full() {
      moonsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_phantasy_moon_half() {
      moonsHalfPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_phantasy_moon_new() {
      // ToDo
      console.log("don_giovanni_phantasy_moon_new");
    },

    don_giovanni_phantasy_people_1() {
      const avatars1 = avatarsPhantasy.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_phantasy_people_2() {
      const avatars2 = avatarsPhantasy.slice(0, 2);
      avatars2.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_phantasy_people_3() {
      avatarsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_phantasy_people_4() {
      // ToDo
      console.log("don_giovanni_phantasy_people_4");
    },

    don_giovanni_where_city() {
      scenePositionsCity.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    don_giovanni_city_moon_full() {
      moonsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_city_moon_half() {
      moonsHalfCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_city_moon_new() {
      // ToDo
      console.log("don_giovanni_city_moon_new");
    },

    don_giovanni_city_people_1() {
      const avatars1 = avatarsCity.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_city_people_2() {
      const avatars1 = avatarsCity.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_city_people_3() {
      avatarsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    don_giovanni_city_people_4() {
      // ToDo
      console.log("don_giovanni_city_people_4");
    },
  };
};
