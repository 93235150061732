import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";

export default function Slide4() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const nextSlide = useStudentStore((state) => state.setSliderIndex);

   useEffect(() => {
      setInProp(true);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <h2 className="h2 text-white text-center">
               Sind die Kopfhörer eingesteckt?
            </h2>
            <div className="d-flex align-items-center justify-content-center mt-5">
               <button
                  className="btn btn--circle me-5"
                  onClick={() => nextSlide(5)}
               >
                  ja
               </button>
               <button className="btn btn--circle" onClick={() => nextSlide(7)}>
                  nein
               </button>
            </div>
         </div>
      </CSSTransition>
   );
}
