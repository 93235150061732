import flirrend from "./music_flirrend.mp3";
import gefaehrlich from "./music_gefaehrlich.mp3";
import mystisch from "./music_mystisch.mp3";
import spannend from "./music_spannend.mp3";
import sphaerisch from "./music_sphaerisch.mp3";
import treibend from "./music_treibend.mp3";
import wartend from "./music_wartend.mp3";
import crowd from "./sound_crowd.mp3";
import knistern from "./sound_knistern.mp3";
import regen from "./sound_regen.mp3";
import stadt from "./sound_stadt.mp3";
import vogelstimmen from "./sound_vogelstimmen.mp3";
import wind from "./sound_wind.mp3";

export const soundList = [
   {
      label: "flirrend",
      file: flirrend,
   },
   {
      label: "gefährlich",
      file: gefaehrlich,
   },
   {
      label: "mystisch",
      file: mystisch,
   },
   {
      label: "spannend",
      file: spannend,
   },
   {
      label: "sphärisch",
      file: sphaerisch,
   },
   {
      label: "treibend",
      file: treibend,
   },
   {
      label: "wartend",
      file: wartend,
   },

   {
      label: "crowd",
      file: crowd,
   },
   {
      label: "knistern",
      file: knistern,
   },
   {
      label: "regen",
      file: regen,
   },
   {
      label: "stadt",
      file: stadt,
   },
   {
      label: "vogelstimmen",
      file: vogelstimmen,
   },
   {
      label: "wind",
      file: wind,
   },
];
