import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import cloneDeep from "lodash/cloneDeep";
import * as THREE from "three";

const assetLoader = new GLTFLoader();

export const loadMesh = (
   args = {
      url: null,
      meshName: null,
      destination: null,
      state: null,
      label: null,
      isGroup: null,
      userData: null,
   }
) => {
   const {
      url,
      meshName,
      destination,
      state,
      label,
      isGroup,
      scale,
      userData,
   } = args;
   console.log(meshName, url, isGroup);
   return new Promise((resolve, reject) => {
      let model;
      assetLoader.load(
         url,
         function (gltf) {
            model = gltf.scene;
            model.name = meshName;
            model.castShadow = true;
            model.receiveShadow = true;
            if (scale) {
               model.scale.x = model.scale.x + scale;
               model.scale.y = model.scale.y + scale;
               model.scale.z = model.scale.z + scale;
               console.log("scaled model:", model);
            }

            if (label) model.userData.label = label;
            if (userData) model.userData = { ...model.userData, ...userData };

            if (destination === "scene") {
               state.scene.add(model);
               model.position.set(0, 0, 0);
               //cache positions
               state.reference = state.reference
                  ? cloneDeep(state.reference)
                  : {};
               model.children.forEach((element) => {
                  state.reference[element.name] = cloneDeep(element);
                  element.receiveShadow = true;
               });
            } else if (destination === "clones") {
               model.userData.draggable = true;
               model.castShadow = true;
               if (model.children.length > 0) {
                  model.children.forEach((element) => {
                     element.castShadow = true;
                  });
               }
               if (isGroup) {
                  console.log(model);
                  model.children.forEach((child) => {
                     state.clones[child.name] = {
                        ...child,
                        userData: { ...child.userData, label: child.name },
                        castShadow: true,
                     };
                  });
               } else {
                  state.clones[meshName] = model;
               }
            } else if (destination === "library") {
               state.library[meshName] = model;
            }
            resolve(model);
         },
         undefined,
         function (error) {
            console.log(error);
            reject(error);
         }
      );
   });
};

export const loadTexture = (state, asset) => {
   console.log("loading Texture: ", asset.name);
   return new Promise((resolve, reject) => {
      new THREE.TextureLoader().load(
         asset.path,
         (texture) => {
            texture.wrapS = THREE.RepeatWrapping;
            texture.repeat.x = -1;
            texture.flipY = false;
            texture.name = asset.name;
            texture.userData = {
               ...texture.userData,
               ...asset.userData,
               label: asset.label,
            };
            state.textures[asset.name] = texture;
            resolve(texture);
         },
         (xhr) => {
            //Download Progress
            console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
         },
         (error) => {
            console.log(error);
            console.log("An error happened" + error);
         }
      );
   });
};

export const loadVideoTexture = (state, asset) => {
   console.log(asset);
   const texture = {};
   return new Promise((resolve, reject) => {
      texture.name = asset.name;
      texture.userData = {
         ...texture.userData,
         ...asset.userData,
         label: asset.label,
         path: asset.path,
      };
      state.textures[asset.name] = texture;
      resolve(texture);
   });
};
