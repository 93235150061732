import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import { Link, useParams } from "react-router-dom";
import { EnumAnimationState } from "../../../types/types";

export default function SlideGameOver() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const { roomId } = useParams();
   const setAnimationState = useStudentStore(
      (state) => state.setAnimationState
   );

   useEffect(() => {
      setInProp(true);
      return () => setAnimationState(EnumAnimationState.end);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div
            ref={nodeRef}
            className="mh-100vh d-flex justify-content-center flex-column text-white text-center"
         >
            <div className="m-auto d-flex">
               <div className="m-auto">
                  <Link to={roomId ? `/gallery/${roomId}` : "/gallery"}>
                     <span className="btn btn--circle me-3">Gallery</span>
                  </Link>
               </div>
               <div className="m-auto">
                  <Link to={roomId ? `/videos/${roomId}` : "/videos"}>
                     <span className="btn btn--circle ms-3">
                        Backstage <br /> Videos
                     </span>
                  </Link>
               </div>
            </div>
         </div>
      </CSSTransition>
   );
}
