import { lenz } from "./scenes/lenz";
import { cage } from "./scenes/cage";
import { don_giovanni } from "./scenes/don_giovanni";
import { feischuetz } from "./scenes/freischuetz";
import { love_song } from "./scenes/love_song";
import { wir_ihr } from "./scenes/wir_ihr";

export const setStage = (
   state,
   stage = { scene: null, place: null, moon: null, people: null }
) => {
   const { scene, place, moon, people } = stage;

   console.log("SetStage: ", stage);

   const methods_lenz = lenz(state);
   const methods_cage = cage(state);
   const methods_don_giovanni = don_giovanni(state);
   const methods_feischuetz = feischuetz(state);
   const methods_love_song = love_song(state);
   const methods_wir_ihr = wir_ihr(state);

   const methods = Object.assign(
      methods_lenz,
      methods_cage,
      methods_don_giovanni,
      methods_feischuetz,
      methods_love_song,
      methods_wir_ihr
   );

   const methodToCall = [];

   if (!stage?.scene) return console.warn("NO SCENE PROVIDED!");

   if (stage?.scene) methodToCall?.push(stage?.scene);
   if (stage?.place) methodToCall?.push(stage?.place);
   if (stage?.moon) methodToCall?.push(stage?.moon);
   if (stage?.people) methodToCall?.push("people_" + stage?.people);

   console.log("NAME", methodToCall.join("_"));

   const callback = methods[methodToCall.join("_")];

   if (!callback) {
      console.warn(stage + "() not defined in methods");
      return false;
   }
   callback();
};
