import {
   doc,
   getDoc,
   collection,
   getDocs,
   query,
   orderBy,
   limit,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

export async function getDocument(
   args: any = {
      collectionName: "Rooms",
      docId: false,
      subcollectionName: false,
      subdocId: false,
   }
) {
   const { collectionName, docId, subcollectionName, subdocId } = args;
   console.log(
      "collectionName:",
      collectionName,
      " docId:",
      docId,
      " subcollectionName:",
      subcollectionName,
      " subdocId:",
      subdocId
   );
   if (!docId) return null;
   let reference;
   if (collectionName && docId && subcollectionName && subdocId) {
      reference = doc(db, collectionName, docId, subcollectionName, subdocId);
   } else {
      reference = doc(db, collectionName, docId);
   }
   const snapshot = await getDoc(reference);
   console.log("getDOcument: ", snapshot.data());
   console.log("----");
   console.log("");
   return snapshot.data();
}

export async function getCollection(
   args: any = {
      collectionName: "Rooms",
      count: 40,
   }
) {
   const { collectionName, count } = args;

   if (!collectionName) return null;
   const reference = collection(db, collectionName);
   const docs = query(reference, limit(count));
   let items: any = [];
   const snapshots = await getDocs(docs);
   snapshots.forEach((snapshot) => {
      console.log(snapshot.data());
      items.push({
         id: snapshot.id,
         ...snapshot.data(),
      });
   });
   console.log(items);
   return items;
   // return snapshot.data();
}
