import gsap from "gsap";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { randomIntFromInterval } from "../helpers/utils";
import { getClonesYPositionByName } from "../helpers/positions";

const defaultArgs = {
   state: null,
   name: null,
   x: null,
   y_to: null,
   y_from: null,
   z: null,
   scale: 1,
   rotation: null,
};
export const throwClone = (argsCustom) => {
   const args = {
      ...defaultArgs,
      ...argsCustom,
   };
   // console.log(args);
   if (!args.state.clones[args.name]) return false;
   console.log(args.state.clones[args.name]);
   const clone = SkeletonUtils.clone(args.state.clones[args.name]);
   clone.position.copy({
      x: args.x,
      y: args.y_from,
      z: args.z,
   });

   clone.scale.copy({
      x: args.scale,
      y: args.scale,
      z: args.scale,
   });

   if (args.rotation) {
      clone.rotation.y = args.rotation;
   }

   args.state.scene.add(clone);
   gsap.to(clone.position, {
      x: args.x,
      y: args.y_to
         ? args.y_to
         : getClonesYPositionByName(
              args.name,
              clone.uuid,
              args.state.reference
           ),
      z: args.z,
      duration: 1,
   });
   cachePosition(args.state, clone.uuid, clone.name, {
      x: args.x,
      y: args.y_to
         ? args.y_to
         : getClonesYPositionByName(
              args.name,
              clone.uuid,
              args.state.reference
           ),
      z: args.z,
   });
};

export const throwClonesOnRandomPosition = (state, count, cloneName) => {
   for (let i = 0; i < count; i++) {
      setTimeout(() => {
         throwClone({
            state: state,
            name: cloneName,
            x: randomIntFromInterval(-16, 17),
            y_from: 20,
            y_to: 4,
            z: randomIntFromInterval(-13, 14),
         });
      }, (i + 1) * 200);
   }
};

const cachePosition = (state, uuid, name, position) => {
   // console.log(state);
   state.reference[uuid] = {
      name: name,
      position: position,
   };
};
