import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";

export default function Slide2() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const nextSlide = useStudentStore((state) => state.setSliderIndex);
   const setAnimationVolume = useStudentStore(
      (state) => state.setAnimationVolume
   );

   useEffect(() => {
      setInProp(true);
      setAnimationVolume(0.6);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <h2 className="h2 text-white text-center">
               Kannst du die Musik hören?
            </h2>

            <div className="d-flex align-items-center justify-content-center mt-5">
               <button
                  className="btn btn--circle me-5"
                  onClick={() => nextSlide(8)}
               >
                  ja
               </button>
               <button className="btn btn--circle" onClick={() => nextSlide(5)}>
                  nein
               </button>
            </div>
         </div>
      </CSSTransition>
   );
}
