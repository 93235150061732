import * as THREE from "three";
import { GUI } from "dat.gui";
const materials = [];

export const createSnow = (state) => {
   const path = document.location.origin;
   let parameters;
   function init() {
      console.log("createSnow");
      const geometry = new THREE.BufferGeometry();
      const vertices = [];

      const textureLoader = new THREE.TextureLoader();

      const assignSRGB = (texture) => {
         texture.colorSpace = THREE.SRGBColorSpace;
      };

      console.log(`${path}/assets/textures/snow/snowflake1.png`);

      const sprite1 = textureLoader.load(
         `${path}/assets/textures/snow/snowflake1.png`,
         assignSRGB
      );
      const sprite2 = textureLoader.load(
         `${path}/assets/textures/snow/snowflake2.png`,
         assignSRGB
      );
      const sprite3 = textureLoader.load(
         `${path}/assets/textures/snow/snowflake3.png`,
         assignSRGB
      );
      const sprite4 = textureLoader.load(
         `${path}/assets/textures/snow/snowflake4.png`,
         assignSRGB
      );
      const sprite5 = textureLoader.load(
         `${path}/assets/textures/snow/snowflake5.png`,
         assignSRGB
      );

      console.log(sprite1);

      for (let i = 0; i < 5000; i++) {
         const x = Math.random() * 2000 - 1000;
         const y = Math.random() * 2000 - 1000;
         const z = Math.random() * 2000 - 1000;

         vertices.push(x, y, z);
      }
      geometry.setAttribute(
         "position",
         new THREE.Float32BufferAttribute(vertices, 3)
      );

      parameters = [
         [[1.0, 0.2, 0.5], sprite2, 20],
         [[0.95, 0.1, 0.5], sprite3, 15],
         [[0.9, 0.05, 0.5], sprite1, 10],
         [[0.85, 0, 0.5], sprite5, 8],
         [[0.8, 0, 0.5], sprite4, 5],
      ];

      for (let i = 0; i < parameters.length; i++) {
         const color = parameters[i][0];
         const sprite = parameters[i][1];
         const size = parameters[i][2];

         materials[i] = new THREE.PointsMaterial({
            size: size,
            map: sprite,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true,
         });
         materials[i].color.setHSL(
            color[0],
            color[1],
            color[2],
            THREE.SRGBColorSpace
         );

         const particles = new THREE.Points(geometry, materials[i]);

         particles.rotation.x = Math.random() * 6;
         particles.rotation.y = Math.random() * 6;
         particles.rotation.z = Math.random() * 6;

         particles.userData = {isSnow: true};

         state.scene.add(particles);
      }

      // const gui = new GUI();

      // const params = {
      //    texture: true,
      // };

      // gui.add(params, "texture").onChange(function (value) {
      //    for (let i = 0; i < materials.length; i++) {
      //       materials[i].map = value === true ? parameters[i][1] : null;
      //       materials[i].needsUpdate = true;
      //    }
      // });

      // gui.open();
   }

   function render() {
      if (!state || !state.renderer || !state.camera) return false;
      const time = Date.now() * 0.00001;

      for (let i = 0; i < state.scene.children.length; i++) {
         const object = state.scene.children[i];

         if (object instanceof THREE.Points) {
            object.rotation.y = time * (i < 4 ? i + 1 : -(i + 1));
         }
      }

      for (let i = 0; i < materials.length; i++) {
         const color = parameters[i][0];

         const h = ((360 * (color[0] + time)) % 360) / 360;
         materials[i].color.setHSL(h, color[1], color[2], THREE.SRGBColorSpace);
      }

      state.renderer.render(state.scene, state.camera);
   }

   function animate() {
      requestAnimationFrame(animate);
      render();
      // stats.update();
   }

   init();
   animate();
};
