import { throwClone, throwClonesOnRandomPosition } from "../../control/clones";

export const part_cage = (state) => {
  const scenePositionsNature = [
    {
      name: "Spotlight_Top_Light",
      uuid: "eb43983e-c4da-4bdb-8fdb-a24d9f17be46",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "7",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "28b3d3bd-e0b1-46bc-85e5-a4e2a6595e8d",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "f0c463d5-ddb1-412a-bd52-36e02c850c5c",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "81f750b9-7715-458c-9d92-cd1b8b412352",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Left_Light",
      uuid: "cadf80a5-668d-467b-8acc-2fdb74bca19a",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "8b7b17b1-1ed3-43c8-b161-ae815f55c9f9",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "29ccbb82-0c5b-4c48-86cf-7c693dfbdbbe",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "n",
      uuid: "5df0884f-21e5-49b3-8e5b-693bc04bbb68",
      position: { x: -11, y: 15.667316722922298, z: -4 },
      rotation: 0,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "a",
      uuid: "f284937a-b754-4a84-839f-4b5fd4aa93b1",
      position: { x: -6, y: 16.839163442273858, z: -2 },
      rotation: 0,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "tanne",
      uuid: "6b97a62a-d67e-41c9-9ff3-dd53e815ef6e",
      position: { x: 0, y: 14.172267902255397, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "u",
      uuid: "bc61a4a3-65d1-41b2-8b6b-2194a1e44077",
      position: { x: 3, y: 17.60383363164215, z: -2 },
      rotation: 0,
      scale: 1.297469,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "r",
      uuid: "251415db-5a21-41ef-ab10-cb01e08e629e",
      position: { x: 9, y: 15.414735747723235, z: 0 },
      rotation: 5.600000000000002,
      scale: 1.284152,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "half_moon",
      uuid: "9814d06e-3317-4723-b9de-8451713d04cb",
      position: { x: 7, y: -6.381053899442534, z: -7 },
      rotation: 0.6000000000000001,
      scale: 0.999327,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "c6eee969-1f7d-4ef1-98f7-58141c5467eb",
      position: { x: 0, y: -0.24449313035743642, z: 7 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "88681889-56bb-46f4-b9b1-85a17541a0ee",
      position: { x: -11, y: -0.03766540817884449, z: 0 },
      rotation: 1.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "0100502d-8f5c-4fcf-b4c1-e338d8875f1b",
      position: { x: 8, y: 0.008577592458801738, z: 0 },
      rotation: 1.9999999999999998,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsNature = [];
  scenePositionsNature.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsNature.push(avatar);
  });

  let moonsNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "moon") moonsNature.push(elem);
  });

  let moonsHalfNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "half_moon") moonsHalfNature.push(elem);
  });

  const scenePositionsPhantasy = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0b8271c2-ec92-4d3c-9223-467770489b73",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "6e20eafe-36b6-4337-8fa9-384ee0a3a289",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "bb99f93d-da4f-48e2-8115-385fd0b894d5",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "643e187e-91d6-46d9-b896-7afb373b98be",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "1",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "f0b12885-eaa0-4f47-9ede-1eedffa5bbaa",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "f46c5aaa-2cf4-42ab-b25b-059877de1808",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Back_Light",
      uuid: "4c682d74-dc3e-4414-a84d-88b423df6131",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "cube",
      uuid: "c99ed49b-69cb-4f2a-b9b1-c42d500e5f2f",
      position: { x: 2, y: 0.07526063256001667, z: -3 },
      rotation: 0,
      scale: 0.868211,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "chair",
      uuid: "39443e1f-cdbf-4190-b9e0-1c93670a3930",
      position: { x: -3, y: 4.269785910109806, z: 0 },
      rotation: 5.600000000000002,
      scale: 2.693602,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "chair",
      uuid: "e90be08b-931f-4b64-ab12-6b99e5f2c98e",
      position: { x: 4, y: 4.361153762724364, z: -4 },
      rotation: 3.800000000000001,
      scale: 3.180722,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "pfau",
      uuid: "e79e2e5d-2f6f-4974-8e9c-380e841c4738",
      position: { x: 13, y: -4.727155963499021, z: 0 },
      rotation: 5.8000000000000025,
      scale: 2.19861,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "afa2a7b2-0903-4187-bd54-650ba339c45d",
      position: { x: 1, y: 0.09809733656626561, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "f427cf86-8089-4aa7-badf-f9fcb1d2e303",
      position: { x: 2, y: 0.8668282355350192, z: -3 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "3d9a68ab-351d-4b5e-99cc-0798ed2639b1",
      position: { x: 1, y: 11.863956789396179, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "e4fa263c-3b71-41e9-92bc-4a9c8d04a233",
      position: { x: -11, y: -0.10841847779181729, z: 0 },
      rotation: 0.4,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsPhantasy = [];
  scenePositionsPhantasy.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsPhantasy.push(avatar);
  });

  let moonsPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "moon") moonsPhantasy.push(elem);
  });

  let moonsHalfPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "half_moon") moonsHalfPhantasy.push(elem);
  });

  const scenePositionsCity = [
    {
      name: "Spotlight_Top_Light",
      uuid: "eb43983e-c4da-4bdb-8fdb-a24d9f17be46",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "7",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "28b3d3bd-e0b1-46bc-85e5-a4e2a6595e8d",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "f0c463d5-ddb1-412a-bd52-36e02c850c5c",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "81f750b9-7715-458c-9d92-cd1b8b412352",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "6",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "cadf80a5-668d-467b-8acc-2fdb74bca19a",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "8b7b17b1-1ed3-43c8-b161-ae815f55c9f9",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "29ccbb82-0c5b-4c48-86cf-7c693dfbdbbe",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "fox",
      uuid: "a2e9ac5a-68ef-4d7c-9fc5-3189264ecce6",
      position: { x: 0, y: -21.922971404208923, z: 7 },
      rotation: 5.400000000000002,
      scale: 6.245037,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "half_moon",
      uuid: "cd4b623f-baa4-48b4-8de0-edcc5d4f3cd7",
      position: { x: 8, y: 11.242035278601477, z: 0 },
      rotation: 0,
      scale: 0.579609,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "4ee774cb-ac73-404b-ac10-a596ba8ffb00",
      position: { x: -7, y: 0.019792065902318856, z: 8 },
      rotation: 0.8,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "b6f5fd51-9449-49cc-826b-6e3c4e4fa457",
      position: { x: -13, y: 0.19620621894616058, z: -4 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "0700ee10-7aee-4b2c-854c-f27eb6efbe89",
      position: { x: -12, y: 0.1055356020571311, z: -6 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "city",
      uuid: "4af32255-a1ac-4e01-8f23-b7bcf2b39d44",
      position: { x: 0, y: 0, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsCity = [];
  scenePositionsCity.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsCity.push(avatar);
  });

  let moonsCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "moon") moonsCity.push(elem);
  });

  let moonsHalfCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "half_moon") moonsHalfCity.push(elem);
  });

  return {
    la_cage_aux_folles_where_nature() {
      scenePositionsNature.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_nature_moon_full() {
      moonsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_nature_moon_half() {
      moonsHalfNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_nature_people_1() {
      const avatars1 = avatarsNature.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_nature_people_2() {
      const avatars2 = avatarsNature.slice(0, 2);
      avatars2.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_nature_people_3() {
      avatarsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_where_phantasy() {
      scenePositionsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_phantasy_moon_full() {
      moonsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_phantasy_moon_half() {
      moonsHalfPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_phantasy_moon_new() {
      // ToDo
      console.log("la_cage_aux_folles_phantasy_moon_new");
    },

    la_cage_aux_folles_phantasy_people_1() {
      const avatars1 = avatarsPhantasy.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_phantasy_people_2() {
      const avatars1 = avatarsPhantasy.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_phantasy_people_3() {
      avatarsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_phantasy_people_4() {
      // ToDo
      console.log("la_cage_aux_folles_phantasy_people_4");
    },

    la_cage_aux_folles_where_city() {
      scenePositionsCity.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_city_moon_full() {
      moonsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_city_moon_half() {
      moonsHalfCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_city_moon_new() {
      // ToDo
      console.log("la_cage_aux_folles_city_moon_new");
    },

    la_cage_aux_folles_city_people_1() {
      const avatars1 = avatarsCity.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_city_people_2() {
      const avatars1 = avatarsCity.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_city_people_3() {
      avatarsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    la_cage_aux_folles_city_people_4() {
      // ToDo
      console.log("la_cage_aux_folles_city_people_4");
    },
  };
};
