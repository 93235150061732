import {
   addDoc,
   collection,
   deleteDoc,
   doc,
   getFirestore,
   setDoc,
   serverTimestamp,
   getDoc,
} from "firebase/firestore";

import { EnumTarget, KeywordInterface } from "../types/types";
import { getDocument } from "./servicesRead";
import { db } from "../firebase/firebaseConfig";

const getDeleteDate: any = (currentDate: number) => {
   const now = new Date(currentDate * 1000);
   now.setDate(now.getDate() + 21);
   return now;
};

export async function addRoom(room: any) {
   // Add a new document in collection "cities"
   const newRoom = await addDoc(collection(db, "Rooms"), {
      name: room.name,
      created_at: serverTimestamp(),
   });

   const roomRef = doc(db, "Rooms", newRoom.id);
   const roomSnap = await getDoc(roomRef);
   const to_be_deleted_at = getDeleteDate(roomSnap.data()?.created_at.seconds);
   await setDoc(
      doc(db, "Rooms", newRoom.id),
      { to_be_deleted_at: to_be_deleted_at },
      { merge: true }
   );
   console.log("NewRoomId", newRoom.id);
   return {
      name: room.name,
      id: newRoom.id,
   };
}

export async function addStudentToRoom(name: string, roomId: string) {
   console.log(name, roomId);
   if (!roomId) {
      const student = await addDoc(collection(db, "Freelancers"), {
         name: name,
         progress: 0,
         created_at: serverTimestamp(),
      });
      const studentRef = doc(db, "Freelancers", student.id);
      const studentSnap = await getDoc(studentRef);
      const to_be_deleted_at = getDeleteDate(
         studentSnap.data()?.created_at.seconds
      );
      await setDoc(
         doc(db, "Freelancers", student.id),
         { to_be_deleted_at: to_be_deleted_at },
         { merge: true }
      );
      console.log(student.id);
      return student.id;
   } else {
      const student = await addDoc(
         collection(db, "Rooms", roomId, "Students"),
         {
            name: name,
            progress: 0,
            created_at: serverTimestamp(),
         }
      );
      const studentRef = doc(db, "Rooms", roomId, "Students", student.id);
      const studentSnap = await getDoc(studentRef);
      console.log(studentSnap.data());
      const to_be_deleted_at = getDeleteDate(
         studentSnap.data()?.created_at.seconds
      );
      await setDoc(
         doc(db, "Rooms", roomId, "Students", student.id),
         { to_be_deleted_at: to_be_deleted_at },
         { merge: true }
      );
      console.log(student.id, roomId);
      return student.id;
   }
}

export async function updateStudent(
   payload: any,
   roomId: string | undefined,
   studentId: string
) {
   if (!payload || !studentId) return false;
   let reference = doc(db, "Freelancers", studentId);
   if (roomId) reference = doc(db, "Rooms", roomId, "Students", studentId);
   const student = await setDoc(reference, { ...payload }, { merge: true });
   return student;
}

//*******************************************************
// keywords
//*******************************************************

export async function addKeyword(target: EnumTarget, keywordname: string) {
   // Add a new document in collection "cities"
   const newRoom = await addDoc(collection(db, "Answers", target, "Keywords"), {
      name: keywordname,
      count: 0,
   });
}

export async function deleteKeyword(target: EnumTarget, id: string) {
   const deletedKeyword = await deleteDoc(
      doc(db, "Answers", target, "Keywords", id)
   );
}

export async function deleteRoom(id: string) {
   const deletedRoom = await deleteDoc(doc(db, "Rooms", id));
   console.log(deletedRoom);
}

export async function updateKeywordCount(
   target: EnumTarget,
   keyword: KeywordInterface
) {
   const oldKeyword = await getDocument({
      collectionName: "Answers",
      docId: target,
      subcollectionName: "Keywords",
      subdocId: keyword.id,
   });

   console.log("now: ", oldKeyword?.count + 1);
   await setDoc(
      doc(db, "Answers", target, "Keywords", keyword.id),
      {
         count: oldKeyword?.count + 1,
      },
      { merge: true }
   );

   // await setDoc(doc(db, "Keywords", keyword.id), {
   //    count:
   //  });
   // Add a new document in collection "cities"
   // getDocument({ docId: roomIdFromProps })
}

export async function updateQuestions(questions: any) {
   await setDoc(doc(db, "Questions", "Feedback"), questions, { merge: true });
}

//*******************************************************
// Navigation
//*******************************************************

export async function updateNavigation(value: boolean) {
   await setDoc(doc(db, "settings", "navigation"), {
      show: value,
   });
}
