import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import IconLogo from "../../Icons/IconLogo";

export default function Slide0Start() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const setAnimationVolume = useStudentStore(
      (state) => state.setAnimationVolume
   );
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);

   useEffect(() => {
      setInProp(true);
      setAnimationVolume(0.2);

      setTimeout(() => {
         nextSlide();
      }, 7000);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="container">
               <div className="row">
                  <div className="col-md-6 m-auto">
                     <IconLogo
                        dimensions={{ width: "581.137", height: "550" }}
                        color={"#fff"}
                     />
                  </div>
               </div>
            </div>
         </div>
      </CSSTransition>
   );
}
