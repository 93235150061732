import { useStudentStore } from "../../../store/appStore";
import {
   ChangeEvent,
   Dispatch,
   SetStateAction,
   useEffect,
   useRef,
   useState,
} from "react";
import {
   EnumFundusSpecial,
   IAccordionEffects,
   IAccordionObjects,
} from "../../../types/types";
import IconMinus from "../../Icons/IconMinus";
import IconPlus from "../../Icons/IconPlus";
import { getRndInteger } from "../../../helpers/utils";
// import AccordionItem from "./AccordionItem";
import CloneAddbutton from "../SlideEditorElements/CloneAddButton";
import IconTrash from "../../Icons/IconTrash";
import { soundList } from "../../../audio/effects/soundList";
import { HexColorPicker } from "react-colorful";
import ColorPicker from "../ColorPicker";
import Modal from "../Modal";

interface Props {
   onAddNewObject: (name: string) => void;
   onDeleteObject: (uuid: string) => void;
   onGrabObject: (uuid: string) => void;
   onGetUuidForSettings: (uuid: string) => void;
   onShowCloneSettingsUi: () => void;
   onToggleLight: (uuid: string) => void;
   onSetLightIntensity: (uuid: string, intensity: number) => void;
   onSetLightColor: (uuid: string, color: string) => void;
   onHandleToggleEffect: (effect: string, enable: boolean) => void;
   setMoveEnabled: Dispatch<SetStateAction<boolean>>;
   setScaleEnabled: Dispatch<SetStateAction<boolean>>;
   selectedItem: string | null;
   setSelectedItem: Dispatch<SetStateAction<string | null>>;
   onHandleApplyTexture: (textureName: string, target: string) => void;
   onHandleResetTexture: (target: string) => void;
   isPreFinishActive: boolean;
}

let objectItems: IAccordionObjects = {
   title: "meine objekte",
   content: [],
   isOpen: true,
};

let lightItems = {
   title: "licht",
   content: [],
   isOpen: false,
};

let effectsItems: IAccordionEffects = {
   title: "effekte",
   content: "none",
   isOpen: false,
};

let soundItems = {
   title: "Soundeffekte",
   content: soundList,
   isOpen: false,
};

let textureItems = {
   title: "Texturen",
   content: [],
   isOpen: false,
   isFloorOpen: false,
};

const Accordion = ({
   onAddNewObject,
   onDeleteObject,
   onGrabObject,
   onGetUuidForSettings,
   onShowCloneSettingsUi,
   onToggleLight,
   onSetLightIntensity,
   onSetLightColor,
   onHandleToggleEffect,
   setMoveEnabled,
   setScaleEnabled,
   selectedItem,
   setSelectedItem,
   onHandleApplyTexture,
   onHandleResetTexture,
   isPreFinishActive,
}: Props) => {
   const assets = useStudentStore((state) => state?.assets);
   const fundus = useStudentStore((state) => state.fundus);
   const setFundus = useStudentStore((state) => state.setFundus);
   const [dataObjects, setDataObjects] = useState<any>(objectItems);
   const [dataSounds, setDataSounds] = useState<any>(soundItems);
   const [dataLights, setDataLights] = useState<any>(lightItems);
   const [dataEffects, setDataEffects] = useState<any>(effectsItems);
   const [dataTextures, setDataTextures] = useState<any>(textureItems);
   const [checkedEffect, setCheckedEffect] = useState<any>(
      EnumFundusSpecial.none
   );

   useEffect(() => {
      if (assets && assets?.objects && dataObjects) {
         setDataObjects({ ...dataObjects, content: assets?.objects });
         // if (assets?.objects?.length > 0 && assets?.objects[0]?.uuid) {
         //    handleGrabObject(assets?.objects[0]?.uuid);
         //    //setMoveEnabled(true);
         //    //setScaleEnabled(false);
         // } else {
         //    setMoveEnabled(false);
         // }
      }
   }, [assets?.objects]);

   useEffect(() => {
      if (assets && assets?.lights && dataLights) {
         setDataLights({
            ...dataLights,
            content: assets?.lights,
         });
      }
   }, [assets.lights]);

   useEffect(() => {
      if (assets && assets?.effects && dataEffects) {
         setCheckedEffect(assets?.effects);
         setDataEffects({ ...dataEffects, content: assets?.effects });
      }
   }, [assets?.effects]);

   useEffect(() => {
      if (assets && assets?.textures && dataTextures) {
         setDataTextures({ ...dataTextures, content: assets?.textures });
      }
   }, [assets?.textures]);

   useEffect(() => {
      if (isPreFinishActive) stopAll();
   }, [isPreFinishActive]);

   //*******************************************************
   // Methods
   //*******************************************************

   const soundFilesRef = useRef<any[]>([]);
   const stopAll = () => {
      soundFilesRef.current?.map((sound) => sound?.pause());
   };

   const handleItemClick = () => {
      setDataObjects({
         ...dataObjects,
         isOpen: !dataObjects.isOpen,
      });
   };

   const handleAccordionLightsToggle = () => {
      if (dataLights)
         setDataLights({ ...dataLights, isOpen: !dataLights.isOpen });
   };

   const handleAccordionEffectsToggle = () => {
      setDataEffects({
         ...dataEffects,
         isOpen: !dataEffects.isOpen,
      });
   };

   const handleAccordionSoundsToggle = () => {
      setDataSounds({
         ...dataSounds,
         isOpen: !dataSounds.isOpen,
      });
   };

   const handleAccordionTexturesToggle = (isFloorTab: boolean) => {
      const newState = {
         ...dataTextures,
      };
      console.log(newState);
      if (isFloorTab) newState.isFLoorOpen = !dataTextures.isFLoorOpen;
      else newState.isOpen = !dataTextures.isOpen;
      setDataTextures(newState);
   };

   const handleToogleLightsByUuid = (uuid: string) => {
      onToggleLight(uuid);
   };

   const handleSetLightIntensity = (uuid: string, intensity: number) => {
      console.log(uuid, intensity);
      onSetLightIntensity(uuid, intensity);
   };

   const handleSetLightColor = (uuid: string, color: string) => {
      console.log(uuid, color);
      // onSetLightIntensity(uuid, intensity);
   };

   const handleSetEffect = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value || EnumFundusSpecial.none;
      setFundus({
         ...fundus,
         special: value as EnumFundusSpecial,
      });
      setCheckedEffect(value);
      onHandleToggleEffect(value, true);
   };

   const handleDeleteItem = (uuid: string) => {
      if (uuid && uuid !== "") {
         onDeleteObject(uuid);
      }
   };

   const handleGrabObject = (uuid: string | null) => {
      if (uuid && uuid !== "") {
         setSelectedItem(uuid);
         onGrabObject(uuid);
         onGetUuidForSettings(uuid);
         onShowCloneSettingsUi();
         setMoveEnabled(true);
         setScaleEnabled(false);
      }
   };

   // helpers
   //--------------------------------------------------------

   const [colorPickerModal, setcolorPickerModal] = useState({
      color: null,
      show: false,
      uuid: null,
   });

   const handleColorChange = (uuid: any, color: any) => {
      setcolorPickerModal({
         color: color,
         uuid: uuid,
         show: true,
      });
      if (uuid && color) {
         onSetLightColor(uuid, color);
      }
   };

   return (
      <>
         <div className="accordion">
            <div className="accordion__item">
               <div
                  className="d-flex py-3 my-1 justify-content-between cursor-pointer"
                  onClick={() => handleItemClick()}
               >
                  <span className="text-uppercase hbody">
                     {dataObjects?.title}
                  </span>
                  <span className={`${dataObjects?.isOpen ? "pt-2" : "pt-1"}`}>
                     {dataObjects?.isOpen ? <IconMinus /> : <IconPlus />}
                  </span>
               </div>
               <div
                  className={
                     "accordion__itemContent" +
                     (dataObjects.isOpen
                        ? " accordion__itemContent--opened"
                        : "")
                  }
               >
                  {dataObjects?.content?.map((item: any) => (
                     <div
                        className={
                           "accordion__subItem" +
                           (selectedItem === item.uuid
                              ? " accordion__subItem--active"
                              : "")
                        }
                        key={getRndInteger()}
                     >
                        <div className="row justify-content-between">
                           <div
                              className="col-9 d-flex align-items-center cursor-pointer opacity"
                              onClick={() => handleGrabObject(item?.uuid || "")}
                           >
                              <img
                                 src={item.thumbnail}
                                 alt="Objekt"
                                 width="40"
                                 height="40"
                              />
                              <div className="px-2">{item?.label}</div>
                           </div>
                           <div className="col-3 d-flex align-items-center justify-content-end">
                              <div className="align-self-center opacity" />
                              <div
                                 className="opacity me-3 cursor-pointer"
                                 onClick={() =>
                                    handleDeleteItem(item?.uuid || "")
                                 }
                              >
                                 <IconTrash />
                              </div>
                           </div>
                        </div>
                     </div>
                  ))}

                  <CloneAddbutton
                     onAddNewObject={onAddNewObject}
                     clones={assets?.clones}
                  />
               </div>
            </div>

            <div className="accordion__item">
               <div
                  className="d-flex py-3 my-1 justify-content-between align-items-center cursor-pointer"
                  onClick={handleAccordionLightsToggle}
               >
                  <span className="text-uppercase hbody">
                     {dataLights?.title}
                  </span>
                  <span>
                     {dataLights?.isOpen ? <IconMinus /> : <IconPlus />}
                  </span>
               </div>
               {dataLights && (
                  <div
                     className={
                        "accordion__itemContent" +
                        (dataLights.isOpen
                           ? " accordion__itemContent--opened"
                           : "")
                     }
                  >
                     {dataLights?.content?.map((light: any) => (
                        <div
                           className="accordion__subItem"
                           key={getRndInteger()}
                        >
                           <div className="mb-2 hsmall">{light.label}</div>
                           <div className="row">
                              <div className="col-3 col-xl-2">
                                 <label className="switch">
                                    <input
                                       type="checkbox"
                                       checked={light.visible}
                                       onChange={() =>
                                          handleToogleLightsByUuid(light.uuid)
                                       }
                                    />
                                    <span className="toggleSlider round">
                                       <span className="toggleSlider__labels"></span>
                                    </span>
                                 </label>
                              </div>
                              <div className="col-6 col-xl-7 d-flex align-items-center">
                                 <input
                                    id={light.uuid}
                                    type="range"
                                    min="0"
                                    step="0.25"
                                    max="20"
                                    value={light.intensity}
                                    onChange={(e: any) =>
                                       handleSetLightIntensity(
                                          light.uuid,
                                          e.target.value
                                       )
                                    }
                                    disabled={!light.visible}
                                    className="w-100"
                                 />
                              </div>
                              <div className="col-3 col-xl-3 d-flex align-items-center">
                                 <button
                                    onClick={() =>
                                       setcolorPickerModal({
                                          color: light.color,
                                          show: true,
                                          uuid: light.uuid,
                                       })
                                    }
                                    className="btn btn--3"
                                    style={{
                                       backgroundColor: light.color,
                                       color:
                                          light.color === "rgb(255,255,255)"
                                             ? "black"
                                             : "white",
                                    }}
                                 >
                                    Farbe
                                 </button>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               )}

               <ColorPicker
                  color={colorPickerModal.color}
                  show={colorPickerModal.show}
                  onClose={() =>
                     setcolorPickerModal({
                        ...colorPickerModal,
                        show: false,
                     })
                  }
                  onChange={(color: any) =>
                     handleColorChange(colorPickerModal.uuid, color)
                  }
               />
            </div>

            <div className="accordion__item">
               <div
                  className="d-flex py-3 my-1 justify-content-between align-items-center cursor-pointer"
                  onClick={handleAccordionEffectsToggle}
               >
                  <span className="text-uppercase hbody">
                     {dataEffects?.title}
                  </span>
                  <span>
                     {dataEffects?.isOpen ? <IconMinus /> : <IconPlus />}
                  </span>
               </div>
               <div
                  className={
                     "accordion__itemContent" +
                     (dataEffects.isOpen
                        ? " accordion__itemContent--opened"
                        : "")
                  }
               >
                  <div className="accordion__subItem pt-3 ps-3">
                     <div className="form-check">
                        <input
                           className="form-check-input cursor-pointer"
                           type="radio"
                           id="fog"
                           name="effects"
                           value={EnumFundusSpecial.fog}
                           checked={checkedEffect === EnumFundusSpecial.fog}
                           onChange={handleSetEffect}
                        />
                        <label
                           className="form-check-label cursor-pointer"
                           htmlFor="fog"
                        >
                           Nebel
                        </label>
                     </div>
                     <div className="form-check">
                        <input
                           className="form-check-input cursor-pointer"
                           type="radio"
                           id="snow"
                           name="effects"
                           value={EnumFundusSpecial.snow}
                           checked={checkedEffect === EnumFundusSpecial.snow}
                           onChange={handleSetEffect}
                        />
                        <label
                           className="form-check-label cursor-pointer"
                           htmlFor="snow"
                        >
                           Schnee
                        </label>
                     </div>
                     <div className="form-check">
                        <input
                           className="form-check-input cursor-pointer"
                           type="radio"
                           id="none"
                           name="effects"
                           value={EnumFundusSpecial.none}
                           checked={checkedEffect === EnumFundusSpecial.none}
                           onChange={handleSetEffect}
                        />
                        <label
                           className="form-check-label cursor-pointer"
                           htmlFor="none"
                        >
                           Keine
                        </label>
                     </div>
                  </div>
               </div>
            </div>

            <div className="accordion__item">
               <div
                  className="d-flex py-3 my-1 justify-content-between align-items-center cursor-pointer"
                  onClick={handleAccordionSoundsToggle}
               >
                  <span className="text-uppercase hbody">
                     {dataSounds?.title}
                  </span>
                  <span>
                     {dataSounds?.isOpen ? <IconMinus /> : <IconPlus />}
                  </span>
               </div>

               <div
                  className={
                     "accordion__itemContent" +
                     (dataSounds.isOpen
                        ? " accordion__itemContent--opened"
                        : "")
                  }
               >
                  {dataSounds.content.map((sound: any, index: number) => (
                     <div className="row mb-2">
                        <div className="col-4 d-flex align-items-center hsmall">
                           {sound.label}
                        </div>
                        <div className="col-8">
                           <audio
                              ref={(sound) => soundFilesRef.current.push(sound)}
                              src={sound.file}
                              controls
                              loop
                              className="d-block"
                           />
                        </div>
                     </div>
                  ))}
               </div>
            </div>

            <div className="accordion__item">
               <div
                  className="d-flex py-3 my-1 justify-content-between align-items-center cursor-pointer"
                  onClick={() => handleAccordionTexturesToggle(false)}
               >
                  <span className="text-uppercase hbody">Wände</span>
                  <span>
                     {dataTextures?.isOpen ? <IconMinus /> : <IconPlus />}
                  </span>
               </div>

               <div
                  className={
                     "accordion__itemContent" +
                     (dataTextures.isOpen
                        ? " accordion__itemContent--opened"
                        : "")
                  }
               >
                  <div className="row g-2 mb-2">
                     {dataTextures.content.map(
                        (texture: any, index: number) => (
                           <div
                              className="col-4 hsmall"
                              onClick={() => {
                                 onHandleApplyTexture(texture.name, "walls");
                              }}
                           >
                              <div
                                 style={{
                                    backgroundImage: `url(${texture.thumbnail})`,
                                    paddingBottom: "100%",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    cursor: "pointer",
                                 }}
                              ></div>
                           </div>
                        )
                     )}
                     <div
                        className="col-4 hsmall"
                        onClick={() => {
                           onHandleResetTexture("walls");
                        }}
                     >
                        <div className="texture-reset"></div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="accordion__item">
               <div
                  className="d-flex py-3 my-1 justify-content-between align-items-center cursor-pointer"
                  onClick={() => handleAccordionTexturesToggle(true)}
               >
                  <span className="text-uppercase hbody">Boden</span>
                  <span>
                     {dataTextures.isFLoorOpen ? <IconMinus /> : <IconPlus />}
                  </span>
               </div>

               <div
                  className={
                     "accordion__itemContent" +
                     (dataTextures.isFLoorOpen
                        ? " accordion__itemContent--opened"
                        : "")
                  }
               >
                  <div className="row g-2 mb-2">
                     {dataTextures.content.map(
                        (texture: any, index: number) => (
                           <div
                              className="col-4 hsmall"
                              onClick={() => {
                                 onHandleApplyTexture(texture.name, "floor");
                              }}
                           >
                              <div
                                 style={{
                                    backgroundImage: `url(${texture.thumbnail})`,
                                    paddingBottom: "100%",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    cursor: "pointer",
                                 }}
                              ></div>
                           </div>
                        )
                     )}
                     <div
                        className="col-4 hsmall"
                        onClick={() => {
                           onHandleResetTexture("floor");
                        }}
                     >
                        <div className="texture-reset"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Accordion;
