import { useState } from "react";
import { useStudentStore } from "../../store/appStore";

export default function ButtonVideo({ video, show, onClick }: any) {
   const [visited, setVisited] = useState<boolean>(false);
   const setIsModalActive = useStudentStore((state) => state.setIsModalActive);

   const handleClick = () => {
      console.log("modal");
      setVisited(true);
      onClick(video);
      setIsModalActive(true);
   };
   return (
      <button
         onClick={() => handleClick()}
         className={
            "btn btn--circle btn--play btn--onTop" +
            (show ? " item-enter-active" : " item-enter") +
            (visited ? " btn--visited" : "")
         }
         style={{
            top: `${video.top}%`,
            left: `${video.left}%`,
            right: `${video.right}%`,
            bottom: `${video.bottom}%`,
            fontSize: `${video.fontSize}rem`,
         }}
      >
         {video.label}
      </button>
   );
}
