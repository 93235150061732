import { useStudentStore } from "../../store/appStore";

interface Props {
   children: any;
   show: boolean;
   onClose: () => void;
   type?: "modal--narrow" | "modal--small";
}
export default function Modal({ children, show, onClose, type }: Props) {
   const setIsModalActive = useStudentStore((state) => state.setIsModalActive);
   const handleClose = () => {
      setIsModalActive(false);
      onClose();
   };
   return (
      <div
         className={
            "modal" + (show ? " modal--opened " : " modal--closed ") + type
         }
      >
         <div className="modal__inner">
            <button className="modal__close-btn" onClick={handleClose}></button>
            {children}
         </div>
      </div>
   );
}
