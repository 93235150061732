import {createSnow} from "../assets_dynamic/createSnow";
import {createFog} from "../assets_dynamic/createFog";
import {removeSnow} from "../assets_dynamic/removeSnow";

const effects = {
    fog: 'fog',
    snow: 'snow'
}

export const setEffect = (state, effect, enable) => {
    console.log('SetEffect')

    // as only one effect at a time, remove others
    switch (effect) {
        case effects.snow:
            createSnow(state);
            createFog(state, false)
            break;
        case effects.fog:
            createFog(state, enable);
            removeSnow(state);
            break;
        default:
            createFog(state, false)
            removeSnow(state);
            break;
    }
}