import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import stage from "../../../images/backgrounds/stage.jpg";
import { useStudentStore } from "../../../store/appStore";
import audio_szenen from "../../../audio/intro/06_2_MBB_bist_du_noch_da.mp3";

export default function Slide14Audio() {
   // transition effect
   //--------------------------------------------------------
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);

   // extras
   //--------------------------------------------------------
   const [showButtons, setShowButtons] = useState(false);
   let audio: any = useRef();

   useEffect(() => {
      setInProp(true);
      audio.current = new Audio(audio_szenen);
      audio.current.play();
      audio.current.onended = () => {
         setShowButtons(true);
      };

      return () => {
         audio.current.pause();
      };
   }, []);

   // Methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div
               className="mh-100vh position-relative mw-100vw"
               style={{
                  display: "flex",
                  justifyContent: "center",
               }}
            >
               <button
                  onClick={nextSlide}
                  className={
                     "btn btn--circle btn--onTop" +
                     (showButtons ? " item-enter-active" : " item-enter")
                  }
                  style={{ top: "50%", left: "50%" }}
               >
                  Let's Play
               </button>
               <span
                  style={{
                     position: "fixed",
                     top: "5%",
                     color: "white",
                     zIndex: 2,
                     fontSize: "1.5rem",
                  }}
               >
                  Wenn du magst, kannst du dir später die restlichen Videos
                  anschauen
               </span>

               <img src={stage} className="full" />
            </div>
         </div>
      </CSSTransition>
   );
}
