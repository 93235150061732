import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";

export default function Slide31Outro() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const scene = useStudentStore((state) => state.chosenScene);

   useEffect(() => {
      setInProp(true);
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div
            ref={nodeRef}
            className="mh-100vh d-flex justify-content-center flex-column text-white text-center"
         >
            <img
               className="mb-4 mt-auto m-auto mb-0"
               width={300}
               src={scene?.image}
               alt=""
            />
            <div className="container">
               <div className="row">
                  <div className="col-lg-10 col-xl-8 m-auto">
                     <h2 className="h3 mt-5">
                        Super! Jetzt kannst du selbst in deinem Bühnenbild
                        weiterarbeiten. Du kannst es verändern oder auch ganz
                        neu gestalten.
                     </h2>
                  </div>
               </div>
            </div>
            <div className="mb-5 mt-5 d-flex ">
               <div className="m-auto">
                  <button
                     onClick={nextSlide}
                     type="button"
                     className="btn btn--circle mb-3"
                  >
                     WEITER
                  </button>
               </div>
            </div>
            <h4 className="h3 mb-2 mt-auto">{scene?.title}</h4>
            <p className="mb-5">{scene?.meta}</p>
         </div>
      </CSSTransition>
   );
}
