import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import { useRoomsStore } from "../../store/appStore";

const GateSuperAdminLogin = () => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [validation, setValidation] = useState("");
   const [loading, setLoading] = useState(false);
   const setSuperUser = useRoomsStore((state) => state.setSuperUser);

   async function onLogin(e: any) {
      e.preventDefault();
      if (!email) {
         setValidation("Please enter a valid email");
         return false;
      }
      if (!password) {
         setValidation("Please enter a valid password");
         return false;
      }
      setValidation("");
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
         .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            setSuperUser(user);
            setLoading(false);
         })
         .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setValidation(errorCode);
            setLoading(false);
         });
   }

   return (
      <div className="bg-gradient pt-5 pb-5 mh-100vh d-flex align-items-center justify-content-center">
         <div className="container">
            <div className="row">
               <div className="col-12 col-md-6 col-lg-5 col-xl-4 m-auto">
                  <form className="w-100 d-flex flex-column">
                     <div className="mb-2">
                        <input
                           className="w-100"
                           id="email-address"
                           name="email"
                           type="email"
                           required
                           placeholder="E-Mail"
                           onChange={(e) => setEmail(e.target.value)}
                        />
                     </div>

                     <div className="mb-2">
                        <input
                           className="w-100"
                           id="password"
                           name="password"
                           type="password"
                           required
                           placeholder="Passwort"
                           onChange={(e) => setPassword(e.target.value)}
                        />
                     </div>

                     <div className="mb-2">
                        <button
                           type="submit"
                           className={
                              "btn btn--1 w-100" +
                              (loading ? " btn--loading" : "")
                           }
                           onClick={onLogin}
                        >
                           Login
                        </button>
                     </div>
                     {validation && <p>{validation}</p>}
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
};

export default GateSuperAdminLogin;
