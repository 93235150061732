import { useStudentStore } from "../../store/appStore";

interface Props {
   hideNext?: boolean;
   hidePrev?: boolean;
   onNext?: any;
   disabled?: boolean;
}
export default function SliderStepNav({
   hideNext = false,
   hidePrev = false,
   onNext = null,
   disabled = false,
}: Props) {
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const prevSlide = useStudentStore((state) => state.decrementSliderIndex);
   async function handleNext() {
      if (onNext) {
         //if onNext returns false there is a validation issue so lets stop the nextSlide function
         const callback = await onNext();
         if (callback === false) return;
      }
      nextSlide();
   }
   return (
      <div className="d-flex align-items-center justify-content-center mt-4">
         {!hidePrev && (
            <button
               disabled={disabled}
               className="btn btn--circle me-5"
               onClick={prevSlide}
            >
               Zurück
            </button>
         )}
         {!hideNext && (
            <button
               disabled={disabled}
               className="btn btn--circle"
               onClick={handleNext}
            >
               Weiter
            </button>
         )}
      </div>
   );
}
