import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import stage from "../../../images/backgrounds/stage.jpg";
import Modal from "../../UI/Modal";
import videoBeleuchtung from "../../../videos/interviews/MBB_Int_Jens_1080p.mp4";
import schau_dich_um from "../../../audio/intro/05_MBB_schau_dich_um.mp3";
import { useStudentStore } from "../../../store/appStore";
import { fancyTimeFormat, isDevelopment } from "../../../helpers/utils";
import { videos } from "../../Videos/videosList";
import ButtonVideo from "../../UI/ButtonVideo";

export default function Slide13() {
   // transition effect
   //--------------------------------------------------------
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   let audio: any = useRef();
   let timer: any;
   const timeLimit = isDevelopment() ? 20 : 900; //in seconds

   const [counter, setCounter] = useState<null | number>(null);
   const [showButtons, setShowButtons] = useState(false);

   // counter
   //--------------------------------------------------------
   useEffect(() => {
      if (counter === 0) {
         setModal(false);
         nextSlide();
         clearInterval(timer);
      }
      if (counter && !timer) {
         timer =
            counter >= 0 && setInterval(() => setCounter(counter - 1), 1000);
      }
      return () => clearInterval(timer);
   }, [counter]);

   // init mount
   //--------------------------------------------------------
   useEffect(() => {
      console.log("MOUNTED");
      setInProp(true);
      audio.current = new Audio(schau_dich_um);
      audio.current.play();
      audio.current.onended = () => {
         setCounter(timeLimit);
         setShowButtons(true);
      };

      return () => {
         audio.current.pause();
      };
   }, []);

   // modal
   //--------------------------------------------------------
   const [modal, setModal] = useState(false);
   const [videoUrl, setVideoUrl] = useState(videoBeleuchtung);
   const handelModal = (video: any) => {
      setVideoUrl(video.videoUrl);
      setModal(true);

      setTimeout(() => {
         // @ts-ignore
         if (video1Ref.current) video1Ref.current.play();
      }, 500);
   };

   // Videos
   //--------------------------------------------------------
   const video1Ref = useRef(null);

   // Methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);

   // buttons
   //--------------------------------------------------------

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="mh-100vh position-relative mw-100vw">
               {videos.map((video: any) => (
                  <ButtonVideo
                     show={showButtons}
                     video={video}
                     onClick={handelModal}
                  />
               ))}

               <span
                  style={{ top: "50%", left: "50%" }}
                  className={
                     "btn btn--circle btn--onTop" +
                     (showButtons ? " item-enter-active" : " item-enter")
                  }
               >
                  {counter && fancyTimeFormat(counter)}
               </span>

               {/* <button
                  onClick={nextSlide}
                  className="btn btn--circle btn--onTop"
                  style={{ bottom: "1%", right: "1%" }}
               >
                  Next
               </button> */}
               <img src={stage} className="full" />
            </div>
            <Modal show={modal} onClose={() => setModal(false)}>
               {modal && (
                  <video
                     width="750"
                     height="500"
                     controls
                     className="full"
                     ref={video1Ref}
                     onEnded={() => setModal(false)}
                  >
                     <source src={videoUrl} type="video/mp4" />
                  </video>
               )}
            </Modal>
         </div>
      </CSSTransition>
   );
}
