import { throwClone, throwClonesOnRandomPosition } from "../../control/clones";

export const part_love_song = (state) => {
  const scenePositionsNature = [
    {
      name: "Spotlight_Top_Light",
      uuid: "553d6e28-ab50-42ac-b81b-69386eb09aa3",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: 13,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "096330d7-93ad-457e-abea-44dc2ba35e37",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: "5",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Top_Light",
      uuid: "fee205ee-69c0-43f0-829c-837f5f0673bf",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: 7.5,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "8cd5569b-66ef-4a30-9b7d-6866ac9f0a80",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Left_Light",
      uuid: "fc73060a-2d7d-49d6-8967-7c1882c6d48f",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Front_Light",
      uuid: "dcccb24a-b737-4ade-bfb5-910b39dbb249",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: 3.4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Back_Light",
      uuid: "39fe40d1-9287-48c5-9b8f-77fa97b0a474",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "chameleon",
      uuid: "d21b9e3b-c1ff-45c4-8f42-f3f744eaac11",
      position: { x: -6, y: 8.167473565644505, z: -1 },
      rotation: 5.600000000000002,
      scale: 2.843708,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "pfau",
      uuid: "e47be2c1-971b-4d2b-bb53-e2371b169811",
      position: { x: 4, y: -6.546751220243909, z: 0 },
      rotation: 0.4,
      scale: 2.796884,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "76728cba-7760-41e9-8eaf-d3d4c8cb8cd9",
      position: { x: -4, y: 2.7977304327153774, z: -7 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "ec75ca2d-f407-4c06-91d2-0d62518bc6cd",
      position: { x: -8, y: 0.009784353157810094, z: 2 },
      rotation: 6.400000000000003,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "537376ef-b77b-4451-8ed8-e5b92532ecfb",
      position: { x: -7, y: 3.6814001651935166, z: -1 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "c3896690-75fb-4e08-bdfc-59c7e4b6a2bd",
      position: { x: -3, y: 1.191204613105808, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];
  let avatarsNature = [];
  scenePositionsNature.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsNature.push(avatar);
  });

  let moonsNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "moon") moonsNature.push(elem);
  });

  let moonsHalfNature = [];
  scenePositionsNature.some((elem) => {
    if (elem.name === "half_moon") moonsHalfNature.push(elem);
  });

  const scenePositionsPhantasy = [
    {
      name: "Spotlight_Top_Light",
      uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: "6",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Right_Light",
      uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Front_Light",
      uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "4",
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Back_Light",
      uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "anger_emoji",
      uuid: "cecd5079-c79a-4f89-b3b1-dd0aea71e676",
      position: { x: -2, y: -8.26124041172346, z: -14 },
      rotation: 0,
      scale: 1.484762,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neutral_emoji",
      uuid: "6da67b12-4e3b-4d76-a0bf-e3d47c4fd01c",
      position: { x: -6, y: -6.024633174468913, z: -5 },
      rotation: 0,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "neutral_emoji",
      uuid: "528d8bad-3fb9-4f9a-8f5b-be9fcb345260",
      position: { x: 5, y: -7.53213566503614, z: 3 },
      rotation: 0.6000000000000001,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "kiss_emoji",
      uuid: "78ffd8b0-a779-4d9f-a3b8-291e42a14020",
      position: { x: 7, y: -10.474103808275423, z: -9 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "kiss_emoji",
      uuid: "14f6de6c-f821-44c3-b775-6c533518ce8b",
      position: { x: 8, y: -6.647540508198937, z: 9 },
      rotation: 1,
      scale: 1.2,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "anger_emoji",
      uuid: "fa7ee312-9ecc-469a-8005-bf48ef71f425",
      position: { x: 12, y: -19.533098221927073, z: 5 },
      rotation: 0,
      scale: 1.925115,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "ab56165c-89f5-4d9a-962f-244d5eb34878",
      position: { x: -5, y: -1.4218839074593062, z: 0 },
      rotation: 0,
      scale: 0.8,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "b690f2ba-a905-457a-b48d-7d1aab74a335",
      position: { x: 6, y: 9.015203653638709, z: -7 },
      rotation: 0,
      scale: 0.660061,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "26df8a78-038d-4b3f-9b87-58aa0129af15",
      position: { x: 15, y: -0.03773026676601143, z: 0 },
      rotation: 0,
      scale: 0.6,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "282acd37-e6a7-4fcd-936b-3570ef62ccc5",
      position: { x: -11, y: 0.12472834145307793, z: 5 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "2389c182-317a-4356-930c-dbde3ca26c96",
      position: { x: 3, y: 0.2277394119067524, z: -3 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "12e47e43-0e80-4e3b-8922-e27de5885635",
      position: { x: -2, y: -0.2604925497363011, z: 8 },
      rotation: 0.4,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsPhantasy = [];
  scenePositionsPhantasy.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsPhantasy.push(avatar);
  });

  let moonsPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "moon") moonsPhantasy.push(elem);
  });

  let moonsHalfPhantasy = [];
  scenePositionsPhantasy.some((elem) => {
    if (elem.name === "half_moon") moonsHalfPhantasy.push(elem);
  });

  const scenePositionsCity = [
    {
      name: "Spotlight_Top_Light",
      uuid: "ff45f2e7-12aa-45b8-be7c-d140f0845a80",
      position: { x: -6, y: 56, z: 23 },
      rotation: 0,
      scale: 1,
      intensity: 13,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Spotlight_Center_Light",
      uuid: "98f12544-259a-4595-9279-4c18c46e8fef",
      position: { x: -6, y: 36, z: 60 },
      rotation: 0,
      scale: 1,
      intensity: 10,
      color: { r: 1, g: 1, b: 1 },
      visible: false,
    },
    {
      name: "Top_Light",
      uuid: "d57bf8a4-99ce-466e-aa85-131fba30532c",
      position: { x: 0, y: 33, z: 0 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Right_Light",
      uuid: "ef945e90-9d4b-425a-8ece-b78d84039115",
      position: { x: 40.5, y: -48, z: 4 },
      rotation: 0,
      scale: 1,
      intensity: "10",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Left_Light",
      uuid: "498dbb2a-aa6f-4ca7-875e-8cce5e5a0a46",
      position: { x: -15.9, y: 30, z: -9 },
      rotation: 0,
      scale: 1,
      intensity: 4,
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Front_Light",
      uuid: "0482c300-b9d0-4a69-a713-27bd29d617c0",
      position: { x: -6, y: 47, z: 101.4 },
      rotation: 0,
      scale: 1,
      intensity: "2",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "Back_Light",
      uuid: "9b584146-abe6-46ff-87ce-c09aa343eeb7",
      position: { x: 40.5, y: 47, z: -548 },
      rotation: 0,
      scale: 1,
      intensity: "3",
      color: { r: 1, g: 1, b: 1 },
      visible: true,
    },
    {
      name: "city",
      uuid: "42aa34fb-a217-4a52-b441-aa73c7ef4bc5",
      position: { x: 0, y: 1.2469354095369485, z: 0 },
      rotation: 0,
      scale: 0.752288,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "bus_stop",
      uuid: "ec4101a0-ffe1-4086-92ab-e5efea16fb1c",
      position: { x: -11, y: 0.31095566816199655, z: 6 },
      rotation: 0,
      scale: 0.868211,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar_sitzend",
      uuid: "c995ccd3-2f50-4cae-a122-1a2d18bd690f",
      position: { x: -11, y: -0.834795331119041, z: 5 },
      rotation: 0,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "moon",
      uuid: "3c97dd33-f81e-4d9c-adef-2b79948e55af",
      position: { x: 1, y: 4.909015515939331, z: -11 },
      rotation: 0,
      scale: 0.65408,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "9b343ee2-6684-4149-a36c-fff9ad05cb66",
      position: { x: 5, y: 0.034352737593150995, z: -14 },
      rotation: 0.2,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
    {
      name: "avatar",
      uuid: "56e21b7f-b681-4795-9224-9283e1fa8a66",
      position: { x: 12, y: -0.3116425675627783, z: 9 },
      rotation: 0.4,
      scale: 1,
      intensity: "",
      color: "",
      visible: true,
    },
  ];

  let avatarsCity = [];
  scenePositionsCity.some((avatar, index) => {
    if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
      avatarsCity.push(avatar);
  });

  let moonsCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "moon") moonsCity.push(elem);
  });

  let moonsHalfCity = [];
  scenePositionsCity.some((elem) => {
    if (elem.name === "half_moon") moonsHalfCity.push(elem);
  });

  return {
    async love_song_where_nature() {
      scenePositionsNature.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    love_song_nature_moon_full() {
      moonsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_nature_moon_half() {
      moonsHalfNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_nature_moon_new() {
      // ToDo
      console.log("love_song_nature_moon_new");
    },

    love_song_nature_people_1() {
      const avatars1 = avatarsNature.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_nature_people_2() {
      const avatars1 = avatarsNature.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_nature_people_3() {
      avatarsNature.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_nature_people_4() {
      // ToDo
      console.log("love_song_nature_people_4");
    },

    love_song_where_phantasy() {
      scenePositionsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    love_song_phantasy_moon_full() {
      moonsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_phantasy_moon_half() {
      moonsHalfPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_phantasy_moon_new() {
      // ToDo
      console.log("love_song_phantasy_moon_new");
    },

    love_song_phantasy_people_1() {
      const avatars1 = avatarsPhantasy.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_phantasy_people_2() {
      const avatars1 = avatarsPhantasy.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_phantasy_people_3() {
      avatarsPhantasy.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_phantasy_people_4() {
      // ToDo
      console.log("love_song_phantasy_people_4");
    },

    love_song_where_city() {
      scenePositionsCity.forEach((position, index) => {
        setTimeout(() => {
          // lights
          if (position.name.includes("Light")) {
            const currentLight = state.scene.getObjectByName(position.name);
            currentLight.visible = position.visible;
            currentLight.intensity = position.intensity;
          }
          // everything except moon and avatars
          if (
            position.name !== "moon" &&
            position.name !== "half_moon" &&
            position.name !== "avatar" &&
            position.name !== "avatar_sitzend"
          ) {
            throwClone({
              state: state,
              name: position.name,
              x: position.position.x,
              y_from: 20,
              y_to: position.position.y,
              z: position.position.z,
              scale: position.scale,
              rotation: position.rotation,
            });
          }
        }, (index + 1) * 100);
      });
    },

    love_song_city_moon_full() {
      moonsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_city_moon_half() {
      moonsHalfCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_city_moon_new() {
      // ToDo
      console.log("love_song_city_moon_new");
    },

    love_song_city_people_1() {
      const avatars1 = avatarsCity.slice(0, 1);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_city_people_2() {
      const avatars1 = avatarsCity.slice(0, 2);
      avatars1.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_city_people_3() {
      avatarsCity.forEach((position, index) => {
        setTimeout(() => {
          throwClone({
            state: state,
            name: position.name,
            x: position.position.x,
            y_from: 20,
            y_to: position.position.y,
            z: position.position.z,
            scale: position.scale,
            rotation: position.rotation,
          });
        }, (index + 1) * 100);
      });
    },

    love_song_city_people_4() {
      // ToDo
      console.log("love_song_city_people_4");
    },
  };
};
