import gsap from "gsap";

export const sceneEditModeToggle = (enable, state) => {
   const toggleObjects = [state.scene.getObjectByName("gruppe_buehne_frontal")];
   const wall_rear = state.scene.getObjectByName("rueckwand_buehne");
   const wall_rear_box = state.scene.getObjectByName("rueckwand_wuerfel");
   const roof = state.scene.getObjectByName("decke");
   const wall_l = state.scene.getObjectByName("wand_l");
   const wall_r = state.scene.getObjectByName("wand_r");
   const roofInit = {
      ...state.reference.decke.position,
   };
   const wall_lInit = {
      ...state.reference.wand_l.position,
   };
   const wall_rInit = {
      ...state.reference.wand_r.position,
   };
   const wall_rearInit = {
      ...state.reference.rueckwand_buehne.position,
   };
   const wall_rearBoxInit = {
      ...state.reference.rueckwand_wuerfel.position,
   };

   console.log(wall_rearInit);
   gsap.to(roof.position, { ...roofInit, y: enable ? 50 : roofInit.y });
   gsap.to(wall_l.position, { ...wall_lInit, x: enable ? -50 : wall_lInit.x });
   gsap.to(wall_r.position, { ...wall_rInit, x: enable ? 50 : wall_rInit.x });
   gsap.to(wall_rear.position, {
      ...wall_rearInit,
      z: enable ? -50 : wall_rearInit.z,
   });
   gsap.to(wall_rear_box.position, {
      ...wall_rearBoxInit,
      z: enable ? -50 : wall_rearBoxInit.z,
   });
   toggleObjects.forEach((obj) => {
      if (obj) {
         obj.visible = !enable;
      }
   });
};

export const sceneSneakModeToggle = (state, args) => {
   const { enable, wallsLeft, wallsRight } = args;
   let toggleObjects = [];

   if (wallsRight)
      toggleObjects = [
         ...toggleObjects,
         state.scene.getObjectByName("wand_rechts"),
         state.scene.getObjectByName("wand_rechts_aussen"),
      ];

   if (wallsLeft)
      toggleObjects = [
         ...toggleObjects,
         state.scene.getObjectByName("wand_links"),
         state.scene.getObjectByName("wand_links_aussen"),
      ];

   toggleObjects.forEach((obj) => {
      if (obj) {
         obj.visible = enable;
      }
   });
};

export const roofToggle = (enable, state) => {
   const toggleObjects = [
      state.scene.getObjectByName("Mesh147_1"),
      state.scene.getObjectByName("Mesh147"),
      state.scene.getObjectByName("blende_front001"),
      state.scene.getObjectByName("blende_r001"),
      state.scene.getObjectByName("zugstangen001"),
   ];

   toggleObjects.forEach((obj) => {
      if (obj) {
         obj.visible = enable;
      }
   });
};
