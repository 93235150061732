import { Link } from "react-router-dom";

const PageServiceNav = () => {
   return (
      <div className="text-purple hsmall d-flex align-items-center justify-content-center ">
         <Link to="/page/impressum" className="me-2">
            Impressum
         </Link>{" "}
         |
         <Link to="/page/datenschutz" className="ms-2 me-2">
            Datenschutz
         </Link>
         |
         <Link to="/page/credits" className="ms-2">
            Credits
         </Link>
      </div>
   );
};

export default PageServiceNav;
