import * as THREE from "three";

export const createPlaneMash = (state) => {
   const planeMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(39, 30),
      new THREE.MeshPhongMaterial({ color: 0x111111 })
   );
   planeMesh.rotateX(-Math.PI / 2);
   planeMesh.receiveShadow = true;
   planeMesh.name = "ground";
   planeMesh.userData.name = "ground";
   planeMesh.position.set(0, 4, -4);
   planeMesh.visible = false;
   state.scene.add(planeMesh);

   // const bigPlaneMesh = new THREE.Mesh(
   //    new THREE.PlaneGeometry(500, 500),
   //    new THREE.MeshPhongMaterial({ color: 0x111111 })
   // );
   // bigPlaneMesh.rotateX(-Math.PI / 2);
   // bigPlaneMesh.receiveShadow = true;
   // bigPlaneMesh.name = "earth";
   // bigPlaneMesh.userData.name = "earth";
   // state.scene.add(bigPlaneMesh);

   // grid
   // const grid = new THREE.GridHelper(20, 20);
   // state.scene.add(grid);

   return planeMesh;
};

export const createHighlightMash = (state) => {
   const highlightMesh = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshLambertMaterial({
         side: THREE.DoubleSide,
         transparent: false,
         color: 0x111111,
      })
   );
   highlightMesh.receiveShadow = true;
   highlightMesh.rotateX(-Math.PI / 2);
   highlightMesh.position.set(0.5, 0, 0.5);
   state.scene.add(highlightMesh);
   return highlightMesh;
};
