import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import audio_zeit from "../../../audio/intro/03_MBB_besser_kennenlernen.mp3";
import { useStudentStore } from "../../../store/appStore";
import { EnumAnimationState } from "../../../types/types";

export default function Slide12AudioOutro() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   let audio: any = useRef();

   useEffect(() => {
      setInProp(true);
      audio.current = new Audio(audio_zeit);
      audio.current.play();
      audio.current.onended = () => {
         setAnimationState(EnumAnimationState.explosion);
         // nextSlide();
      };

      return () => {
         audio.current.pause();
      };
   }, []);

   // methods
   //--------------------------------------------------------
   // const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const setAnimationState = useStudentStore(
      (state) => state.setAnimationState
   );

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef} className="mh-100vh d-flex">
            {/* <div className="d-flex mt-auto mb-5 ms-auto">
               <button
                  className="btn btn--circle btn--circle-small me-3"
                  onClick={() => audio.current.play()}
               >
                  play
               </button>
               <button
                  className="btn btn--circle btn--circle-small"
                  onClick={() => audio.current.pause()}
               >
                  stop
               </button>
            </div> */}
         </div>
      </CSSTransition>
   );
}
