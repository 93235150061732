export default function IconRotate({ color }: any) {
   return (
      <svg
         enableBackground="new 0 0 64 64"
         height="64px"
         id="Layer_1"
         version="1.1"
         viewBox="0 0 64 64"
         width="64px"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path d="M12.644,39.108c0.007,0.048,0.017,0.094,0.028,0.14c0.012,0.047,0.026,0.093,0.043,0.14  c0.017,0.046,0.034,0.091,0.055,0.134c0.021,0.044,0.044,0.085,0.069,0.127c0.025,0.042,0.052,0.083,0.082,0.123  c0.028,0.037,0.058,0.072,0.089,0.107c0.036,0.04,0.073,0.077,0.113,0.113c0.018,0.016,0.032,0.035,0.051,0.051L29.334,53.17  c0.01,0.008,0.022,0.011,0.033,0.019c0.119,0.092,0.25,0.166,0.393,0.219c0.023,0.009,0.046,0.015,0.07,0.022  c0.143,0.045,0.292,0.076,0.451,0.076s0.308-0.031,0.451-0.076c0.023-0.008,0.046-0.014,0.07-0.022  c0.143-0.053,0.274-0.127,0.393-0.219c0.01-0.008,0.023-0.011,0.033-0.019l16.158-13.127c0.021-0.017,0.035-0.037,0.055-0.054  c0.037-0.034,0.073-0.069,0.106-0.107c0.034-0.036,0.065-0.074,0.094-0.113c0.028-0.038,0.054-0.077,0.078-0.117  c0.026-0.043,0.051-0.087,0.072-0.132c0.02-0.042,0.037-0.086,0.054-0.13c0.017-0.048,0.031-0.095,0.044-0.143  c0.012-0.046,0.021-0.091,0.027-0.138c0.009-0.053,0.014-0.104,0.016-0.158c0.002-0.024,0.008-0.047,0.008-0.072V15.317  c0-0.11-0.014-0.216-0.036-0.319c-0.006-0.029-0.018-0.056-0.026-0.084c-0.02-0.073-0.042-0.145-0.073-0.213  c-0.016-0.035-0.036-0.066-0.055-0.1c-0.031-0.058-0.064-0.114-0.103-0.167c-0.025-0.034-0.053-0.065-0.08-0.097  c-0.042-0.048-0.086-0.093-0.134-0.136c-0.017-0.015-0.029-0.034-0.047-0.048L31.226,1.025c-0.551-0.448-1.341-0.448-1.892,0  L13.175,14.153c-0.017,0.013-0.028,0.031-0.044,0.045c-0.05,0.044-0.096,0.092-0.14,0.143c-0.025,0.03-0.051,0.059-0.074,0.09  c-0.041,0.055-0.075,0.114-0.108,0.174c-0.017,0.032-0.036,0.061-0.051,0.094c-0.032,0.07-0.055,0.143-0.076,0.218  c-0.008,0.027-0.019,0.053-0.025,0.081c-0.022,0.103-0.036,0.21-0.036,0.32v23.562c0,0.025,0.006,0.049,0.007,0.073  C12.631,39.005,12.636,39.057,12.644,39.108z M44.938,38.165L31.78,48.854V29.159l13.158-10.69V38.165z M28.78,48.854L15.621,38.165  V18.468L28.78,29.159V48.854z M30.28,4.122l13.779,11.195L30.28,26.512L16.5,15.317L30.28,4.122z" />
         <path d="M62.869,38.137l-7.521-3.475c-0.139-0.064-0.283-0.099-0.428-0.118c-0.011-0.002-0.021-0.006-0.033-0.008  c-0.012-0.001-0.022,0-0.033-0.001c-0.051-0.005-0.1-0.008-0.15-0.007c-0.017,0-0.032,0-0.049,0.001  c-0.053,0.001-0.104,0.006-0.154,0.012c-0.014,0.002-0.028,0.005-0.042,0.008c-0.104,0.017-0.206,0.044-0.303,0.081  c-0.021,0.009-0.042,0.016-0.063,0.024c-0.163,0.072-0.31,0.172-0.437,0.295c-0.011,0.01-0.023,0.013-0.033,0.022  c-0.006,0.007-0.008,0.015-0.014,0.021c-0.123,0.128-0.22,0.279-0.292,0.446c-0.007,0.019-0.017,0.034-0.023,0.053  c-0.041,0.106-0.073,0.218-0.089,0.336l-1.078,8.215c-0.107,0.821,0.471,1.575,1.292,1.683c0.066,0.009,0.132,0.013,0.197,0.013  c0.741,0,1.386-0.55,1.485-1.305l0.625-4.761c1.03,1.65,1.573,3.39,1.573,5.142c0,8.545-12.121,15.496-27.019,15.496  c-14.898,0-27.02-6.951-27.02-15.496c0-2.695,1.265-5.365,3.657-7.72c0.59-0.581,0.598-1.531,0.017-2.121  c-0.581-0.591-1.531-0.597-2.121-0.018c-2.979,2.932-4.553,6.341-4.553,9.858c0,10.199,13.467,18.496,30.02,18.496  c16.552,0,30.019-8.297,30.019-18.496c0-1.736-0.387-3.446-1.134-5.085l2.446,1.13c0.203,0.095,0.417,0.139,0.628,0.139  c0.566,0,1.109-0.322,1.362-0.871C63.949,39.375,63.621,38.483,62.869,38.137z" />
      </svg>
   );
}
