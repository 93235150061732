import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

export function subscribeToRooms(
   args: any = {
      roomId: false,
      studentId: false,
      callback: null,
   }
) {
   const { roomId, studentId, callback } = args;
   let collectionObj = collection(db, "Rooms");
   if (roomId && studentId) {
      collectionObj = collection(db, "Rooms", roomId, "Students", studentId);
   } else if (roomId) {
      collectionObj = collection(db, "Rooms", roomId, "Students");
   }

   onSnapshot(collectionObj, (snapshot) => {
      let items: any[] = [];
      snapshot.docs.forEach((doc) => {
         items.push({
            ...doc.data(),
            id: doc.id,
         });
      });
      console.log(items);
      items.sort((a, b) => (a.name < b.name ? -1 : 1)); // b - a for reverse sort
      console.log(items);
      if (callback) callback(items);
   });
}

export function subscribeToCollection(
   args: any = {
      collectionName: "",
      docId: "",
      subcollectionName: false,
      subdocId: false,
      callback: null,
   }
) {
   const { collectionName, callback, docId, subcollectionName, subdocId } =
      args;
   let collectionObj = collection(db, collectionName, docId, subcollectionName);

   onSnapshot(collectionObj, (snapshot: any) => {
      let items: any[] = [];
      snapshot.docs.forEach((doc: any) => {
         items.push({
            ...doc.data(),
            id: doc.id,
         });
      });
      items.sort((a, b) => (a.name < b.name ? -1 : 1)); // b - a for reverse sort
      if (callback) callback(items);
   });
}

export function subscribeToDocument(
   args: any = {
      collectionName: "",
      documentName: "",
      callback: null,
   }
) {
   const { collectionName, documentName, callback } = args;
   onSnapshot(
      doc(db, collectionName, documentName),
      { includeMetadataChanges: true },
      (doc) => {
         console.log(doc.data());
         if (callback) callback(doc.data());
      }
   );
}
