import { classtoggler } from "classtoggler";
import { slugify } from "./helpers/utils";
import { moveClone } from "./clones/moveClones";
import { throwClone } from "./control/clones";
import { getClonesYPositionByName } from "./helpers/positions";
import gsap from "gsap";
import { applyTextures } from "./helpers/applyTextures";

let where = "nature";

const model = document.querySelector("be-stage");
export const production = (state) => {
   renderNav();
   classtoggler();
   // Only runs in development and will be stripped in production builds.

   const btn_spotlight_add = document.querySelector("#spotlight_add");
   if (btn_spotlight_add) {
      btn_spotlight_add.addEventListener("click", () => {
         model.addSpotLight();
      });
   }

   const btn_texture = document.querySelector("#texture");
   if (btn_texture) {
      btn_texture.addEventListener("click", () => {
         model.texture();
      });
   }

   const btn_texture_reset = document.querySelector("#reset_texture");
   if (btn_texture_reset) {
      btn_texture_reset.addEventListener("click", () => {
         model.resetTexture("floor");
      });
   }

   const btn_screenshot = document.querySelector("#screenshot");
   if (btn_screenshot) {
      btn_screenshot.addEventListener("click", async () => {
         const blob = await model.fetchScreenshot();
         // console.log(img);
         // const link = document.createElement("a");
         // link.download = "download.mp4";
         // link.href = img;
         // link.click();
         // link.delete;

         const video = document.querySelector("#video_player");
         const myFile = new File([blob], "demo.webm", { type: "video/webm" });
         const src = URL.createObjectURL(myFile);
         console.log(src);
         video.src = src;
      });
   }

   const btn_video = document.querySelector("#video");
   if (btn_video) {
      btn_video.addEventListener("click", () => {
         const path = document.location.origin;
         model.applyVideoTexture(
            `${path}/assets/textures/textures_video/230530HWMD1645AE_pexels-mart-production-7565427_422.mp4`,
            "flor"
         );
      });
   }

   const directionallight_add = document.querySelector("#directionallight_add");
   if (directionallight_add) {
      directionallight_add.addEventListener("click", () => {
         model.addDirectionalLight();
      });
   }

   const btn_camera_out = document.querySelector("#camera_out");
   if (btn_camera_out) {
      btn_camera_out.addEventListener("click", () => {
         console.log("zoom out");
         model.zoomOut();
      });
   }

   const btn_camera_in = document.querySelector("#camera_in");
   if (btn_camera_in) {
      btn_camera_in.addEventListener("click", () => {
         model.zoomIn();
      });
   }

   const btn_add_fog = document.querySelector("#add_fog");
   if (btn_add_fog) {
      btn_add_fog.addEventListener("click", () => {
         model.handleFog(true);
      });
   }

   const btn_remove_fog = document.querySelector("#remove_fog");
   if (btn_remove_fog) {
      btn_remove_fog.addEventListener("click", () => {
         model.handleFog(false);
      });
   }

   const btn_edit_mode = document.querySelector("#edit_mode");
   if (btn_edit_mode) {
      btn_edit_mode.addEventListener("click", () => {
         model.editModeEnable(true);
      });
   }

   const light = document.querySelector("#light");
   if (light) {
      console.log(light);
      light.addEventListener("change", (e) => {
         console.log(e.target.value);
         model.directionalLightIntensity(e.target.value);
      });
   }

   const light_color = document.querySelector("#light_color");
   if (light_color) {
      light_color.addEventListener("change", (e) => {
         console.log(e.target.value);
         model.setAllEnabledLightsColor(e.target.value);
      });
   }

   const apiButton = document.querySelector("#api");
   if (apiButton) {
      apiButton.addEventListener("click", (e) => {
         console.log(model.fetchAssets());
      });
   }

   const resetButton = document.querySelector("#reset");
   if (resetButton) {
      resetButton.addEventListener("click", (e) => {
         model.cleanStage();
      });
   }

   // LIGHTS
   //--------------------------------------------------------
   const directionalLights = state.scene.children.filter((child) => {
      return child.isDirectionalLight || child.isSpotLight;
   });
   renderLightsNav(directionalLights);

   // OBJEKTE
   //--------------------------------------------------------
   const objectsButton = document.querySelector("#current_objects");
   console.log(objectsButton);
   if (objectsButton) {
      objectsButton.addEventListener("click", (e) => {
         renderNavs();
      });
   }

   // movement
   //--------------------------------------------------------
   const moveBtns = document.querySelectorAll(".moveBtn");
   moveBtns.forEach((button) => {
      button.addEventListener("click", (e) => {
         const direction = button.getAttribute("data-direction");
         model.moveClone(direction, model.state.activeClone);
      });
   });

   const clearActiveClone = document.querySelector("#active_clone_clear");
   clearActiveClone.addEventListener("click", (e) => {
      model.resetActiveClone();
   });

   // delete
   //--------------------------------------------------------
   const deleteClone = document.querySelector("#delete_selected_clone");
   deleteClone.addEventListener("click", (e) => {
      const activeClone = model.state.activeClone;
      model.deleteClone(activeClone);
      setTimeout(() => {
         renderNavs();
      });
   });

   // rotate
   //--------------------------------------------------------
   const rotateClone = document.querySelector("#active_clone_rotate");
   rotateClone.addEventListener("click", (e) => {
      const activeClone = model.state.activeClone;
      model.rotateClone(activeClone);
   });

   // scale
   //--------------------------------------------------------
   const scaleCLoneBtns = document.querySelectorAll(".scale_clone");
   scaleCLoneBtns.forEach((btn) => {
      const direction = btn.getAttribute("data-scale");
      btn.addEventListener("click", (e) => {
         const activeClone = model.state.activeClone;
         model.scaleClone(direction, activeClone);
      });
   });

   // export
   //--------------------------------------------------------
   const exportBtn = document.querySelector("#export");
   exportBtn.addEventListener("click", (e) => {
      const objects = model.fetchAssets().objects;
      console.log("objects", objects);
      console.log("children", model.state.scene.children);
      let result = model.state.scene.children.filter((o1) =>
         objects.some((o2) => o1.uuid === o2.uuid || o1.name.includes("Light"))
      );
      const models = result.map((elem) => ({
         name: elem.name,
         uuid: elem.uuid,
         position: {
            x: elem.position.x,
            y: elem.position.y,
            z: elem.position.z,
         },
         rotation: elem.rotation._y,
         scale: elem.scale.y,
         intensity: elem.intensity ? elem.intensity : "",
         color: elem.color
            ? { r: elem.color.r, g: elem.color.g, b: elem.color.b }
            : "",
         visible: elem.visible,
      }));
      console.log("models", models);
      navigator.clipboard.writeText(JSON.stringify(models)).then(
         () => {
            console.log("Content copied to clipboard");
         },
         () => {
            console.error("Failed to copy");
         }
      );
   });
};

const matrix_questions = [
   {
      name: "where",
      variations: ["Nature", "Phantasy", "City"],
   },
   {
      name: "moon",
      variations: ["Full", "Half", "New"],
   },
   {
      name: "people",
      variations: ["1", "2", "3", "4"],
   },
   {
      name: "special",
      variations: ["Fog", "Snow", "none"],
   },
];

const matrix_arien = [
   "Lenz",
   "Wir Ihr",
   "Love Song",
   "Don Giovanni",
   "La cage aux folles",
   "Feischütz",
];
const renderNav = () => {
   const container = document.querySelector("#nav");
   let markup = "";

   matrix_arien.forEach((arie) => {
      markup += `
         <h2
            class="bg-white p-2 mb-0 bt-white cursor"
            data-toggler-target="#${slugify(arie)}" 
            data-toggler-group="nav"
         >${arie}</h2>
         <div class="mb-4 d-none" id="${slugify(arie)}">
            <div class="bg-white p-2">
               ${renderSubNav(arie)}
            </div>
         </div>
      `;
   });

   container.innerHTML = markup;

   const buttons = document.querySelectorAll(".js-configurator-btn");
   buttons.forEach((button) => {
      button.addEventListener("click", () => {
         const arie = button.getAttribute("data-arie");
         const question = button.getAttribute("data-question");
         const variation = button.getAttribute("data-variation");
         if (question === "where") {
            where = variation;
            model.createScenePart(arie, "", question, variation);
         } else {
            model.createScenePart(arie, where, question, variation);
         }
      });
   });
};

const renderSubNav = (arie) => {
   let markup = "";
   matrix_questions.forEach((question) => {
      markup += `<div class="mb-3"><h3 class="mb-1">${question.name}</h3>`;
      question.variations.forEach(
         (variation) =>
            (markup += `
               <button 
                  data-arie="${slugify(arie)}" 
                  data-question="${slugify(question.name)}" 
                  data-variation="${slugify(variation)}" 
                  class="me-1 mb-1 js-configurator-btn" 
                  id="${slugify(arie)}_${variation}"
               >
                     ${variation}
               </button>`)
      );
      markup += `</div>`;
   });
   return markup;
};

const renderLightsNav = (lights) => {
   const container = document.querySelector("#lights");
   let markup = "";
   lights.forEach((light) => {
      console.log(light);
      markup += `
            <div>
               <button class="js-light-btn" data-uuid="${light.uuid}">
                  ${light.name}
               </button>
               <input min="1" max="10" type="range" class="js-light-range" data-uuid="${light.uuid}" />
               <input data-uuid="${light.uuid}" type="text"class="js-light-color-change" value="${light.color}" />
            </div>`;
   });
   container.innerHTML = markup;

   const buttons = document.querySelectorAll(".js-light-btn");
   buttons.forEach((button) => {
      button.addEventListener("click", () => {
         const uuid = button.getAttribute("data-uuid");
         console.log(uuid);
         const enabled = model.toggleLightByUuid(uuid);
         if (enabled) button.classList.add("btn--active");
         else button.classList.remove("btn--active");
      });
   });

   const sliders = document.querySelectorAll(".js-light-range");
   sliders.forEach((slider) => {
      slider.addEventListener("input", (e) => {
         const uuid = slider.getAttribute("data-uuid");
         model.setLightIntensityByUuid(uuid, e.target.value);
         // model.switchOnLightByName(name);
      });
   });

   const light_color_change_btns = document.querySelectorAll(
      ".js-light-color-change"
   );
   light_color_change_btns.forEach((btn) => {
      btn.addEventListener("change", () => {
         const color = btn.value;
         const uuid = btn.getAttribute("data-uuid");
         console.log(color);
         model.setLightColorByUuid(uuid, color);
      });
   });
};

const renderObjectsNav = (objects) => {
   console.log("objects:", objects);
   const container = document.querySelector("#objekte");
   if (objects.length === 0) {
      container.innerHTML = "";
      return false;
   }
   let markup = "";
   objects.forEach((object) => {
      markup += `
               <div class="mb-1"><button class="js-objekt-btn" data-id="${object.uuid}">
                     ${object.label}
               </button></div>`;
   });
   container.innerHTML = markup;

   const buttons = document.querySelectorAll(".js-objekt-btn");
   if (!buttons) return false;
   buttons.forEach((button) => {
      button.addEventListener("click", () => {
         const id = button.getAttribute("data-id");
         console.log(id);
         model.grabObjectByUuid(id);
      });
   });
};

const renderClonesButtons = (clones) => {
   const container = document.querySelector("#objects_add");
   let markup = "";
   clones.forEach((clone) => {
      markup += `
               <div class="mb-1"><button class="js-clones-btn" data-name="${clone.name}">
                     ${clone.label}
               </button></div>`;
   });
   container.innerHTML = markup;

   const buttons = document.querySelectorAll(".js-clones-btn");
   buttons.forEach((button) => {
      button.addEventListener("click", () => {
         const clone_name = button.getAttribute("data-name");
         model.throwClone(clone_name);
         setTimeout(() => {
            renderNavs();
         }, 500);
      });
   });
};

const renderTextures = (textures) => {
   const container = document.querySelector("#textures_container_add");
   let markup = "";
   textures.forEach((texture) => {
      markup += `
               <span class="js-texture-btn d-inline-block" data-name="${texture.name}">
                     <img src="${texture.thumbnail}" width="80" style="object-fit:none;">
               </span>`;
   });
   container.innerHTML = markup;

   const buttons = document.querySelectorAll(".js-texture-btn");
   buttons.forEach((button) => {
      button.addEventListener("click", () => {
         const name = button.getAttribute("data-name");
         console.log(model.state.textures[name]);
         const videoTexture = model.state.textures[name];
         if (!videoTexture) return false;
         model.applyTexture(name, "floor");
         return false;
      });
   });
};

const renderNavs = () => {
   console.log("reload");
   const objects = model.fetchAssets().objects;
   renderObjectsNav(objects);

   const clones = model.fetchAssets().clones;
   if (clones.length > 0) renderClonesButtons(clones);

   const textures = model.fetchAssets().textures;
   if (clones.length > 0) renderTextures(textures);
};
