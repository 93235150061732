import {
   getDownloadURL,
   getStorage,
   ref,
   uploadBytes,
   uploadString,
} from "firebase/storage";
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import IconLogo from "../../Icons/IconLogo";
import Accordion from "../../UI/Accordion/Accordion";
import Modal from "../../UI/Modal";
import { updateStudent } from "../../../services/servicesCrud";
import { useStudentStore } from "../../../store/appStore";
import { useNavigate, useParams } from "react-router-dom";
import CloneSettingsUI from "../../UI/SlideEditorElements/CloneSettingsUI";
import CameraSettingsUI from "../../UI/SlideEditorElements/CameraSettingsUI";
import { EnumAnimationState } from "../../../types/types";

export default function SlideEditor({
   onAddNewObject,
   onFinish,
   onDeleteObject,
   onGrabObject,
   onMoveObject,
   onRotateObject,
   onScaleObject,
   onResetObject,
   onToggleLight,
   onSetLightIntensity,
   onHandleToggleEffect,
   onHandleCameraZoom,
   onHandleApplyTexture,
   onHandleResetTexture,
   onSetLightColor,
}: any) {
   const { roomId } = useParams();
   const student = useStudentStore((state) => state.student);

   const setAnimationState = useStudentStore(
      (state) => state.setAnimationState
   );
   const chosenScene = useStudentStore((state) => state.chosenScene);
   const [inProp, setInProp] = useState(false);
   const modals = useStudentStore((state) => state.modalsInEditor);
   const setModals = useStudentStore((state) => state.setModalsInEditor);
   const nodeRef = useRef(null);
   const emptyFinishState: any = {
      screenshot: null,
      screenshot_error: null,
      publish_with_name: null,
      confirm: false,
      redirect: false,
      screenshot_loading: false,
   };
   const [finish, setFinish] = useState(emptyFinishState);
   const storage = getStorage();
   const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
   const [cloneUuid, setCloneUuid] = useState<string | null>(null);
   const [showCloneSettings, setShowCloneSettings] = useState<boolean>(false);
   const [moveEnabled, setMoveEnabled] = useState(false);
   const [scaleEnabled, setScaleEnabled] = useState(false);
   const [preFinish, setPreFinish] = useState(false);
   const [selectedItem, setSelectedItem] = useState<string | null>(null);

   useEffect(() => {
      console.log("Mounted Slider Editor");
      setInProp(true);
      audio.current = new Audio(chosenScene?.audioFull);

      setTimeout(() => {
         console.log("settimeout");
         setModals({ ...modals, description: true });
      }, 1000);

      return () => {
         audio.current.pause();
      };
   }, []);

   useEffect(() => {
      setModals({ ...modals, final: false });
      if (finish.redirect) setAnimationState(EnumAnimationState.implosion);
   }, [finish.redirect]);

   // audio
   //--------------------------------------------------------
   let audio: any = useRef();
   const handleAudioState = () => {
      console.log(audio.current.duration);
      // audio.current.play();
      if (audio.current.duration === 0 || audio.current.paused) {
         audio.current.play();
         setAudioPlaying(true);
      } else {
         audio.current.pause();
         setAudioPlaying(false);
      }
   };

   // finishing methods
   //--------------------------------------------------------

   const handlePreFinish = () => {
      console.log(modals.final);
      // if (animationState === EnumAnimationState.implosion)
      //    setAnimationState(EnumAnimationState.end);
      // else setAnimationState(EnumAnimationState.implosion);
      audio.current.pause();
      setAudioPlaying(false);
      setModals({ ...modals, final: true });
      setPreFinish(true);
   };

   async function handleFinish(publish_with_name: boolean) {
      audio.current.pause();
      setFinish((prev: any) => {
         console.log(prev);
         return { ...prev, publish_with_name };
      });
      try {
         const img = await onFinish();
         setFinish((prev: any) => {
            return { ...prev, screenshot: img };
         });
      } catch (error) {
         console.log(error);
         setFinish((prev: any) => {
            return { ...prev, screenshot_error: error };
         });
      }
   }

   const handleCancel = () => {
      setModals({ ...modals, final: false });
      setFinish(emptyFinishState);
      setPreFinish(false);
   };

   async function handleSubmit() {
      if (!student) console.warn("No student logged in");
      if (student && finish.screenshot) {
         setFinish((prev: any) => {
            console.log(prev);
            return { ...prev, screenshot_loading: true };
         });
         const storageRef = ref(
            storage,
            `screenshots/${roomId}/${student?.id}.mp4`
         );
         const firebaseImage = uploadBytes(storageRef, finish.screenshot)
            .then((snapshot) => {
               getDownloadURL(snapshot.ref).then((downloadURL) => {
                  console.log("File available at", downloadURL);
                  saveScreenshotToStudent(downloadURL);
               });
            })
            .catch((error) => {
               console.log(error);
            });
      } else {
         setFinish((prev: any) => {
            return { ...prev, redirect: true };
         });
      }
   }

   async function saveScreenshotToStudent(downloadURL: String) {
      if (student && finish.screenshot) {
         const upload = await updateStudent(
            {
               publish_with_name: finish.publish_with_name,
               result: downloadURL,
            },
            roomId,
            student.id
         );
         setFinish((prev: any) => {
            return { ...prev, redirect: true };
         });
      }
   }

   const onGetUuidForSettings = (uuid: string) => {
      uuid && setCloneUuid(uuid);
   };

   const onShowCloneSettingsUi = () => {
      setShowCloneSettings(true);
   };

   const onHideCloneSettingsUi = () => {
      setShowCloneSettings(false);
   };
   const getVideoUrl = (blob: any) => {
      const myFile = new File([blob], "demo.webm", { type: "video/webm" });
      const src = URL.createObjectURL(myFile);
      return src;
   };

   //*******************************************************
   // Render
   //*******************************************************

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef} className="mw-100vw d-flex">
            <div className="row mh-100vh">
               <div className="col-md-3 bg-white px-4 fixed-sidebar">
                  <div className="col-6 py-4">
                     <IconLogo color={"#000"} />
                  </div>
                  <div className="mb-4">
                     <Accordion
                        onAddNewObject={onAddNewObject}
                        onDeleteObject={onDeleteObject}
                        onGrabObject={onGrabObject}
                        onGetUuidForSettings={onGetUuidForSettings}
                        onShowCloneSettingsUi={onShowCloneSettingsUi}
                        onToggleLight={onToggleLight}
                        onSetLightIntensity={onSetLightIntensity}
                        onHandleToggleEffect={onHandleToggleEffect}
                        setMoveEnabled={setMoveEnabled}
                        setScaleEnabled={setScaleEnabled}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        onHandleApplyTexture={onHandleApplyTexture}
                        onHandleResetTexture={onHandleResetTexture}
                        onSetLightColor={onSetLightColor}
                        isPreFinishActive={preFinish}
                     />
                  </div>
                  <div className="row mt-auto mb-4">
                     <div className="col-6 mb-3">
                        <button
                           title="Musikstück anhören"
                           type="button"
                           onClick={() => handleAudioState()}
                           className="btn btn--3 bg-blue_ocean w-100 px-4 d-block"
                        >
                           {
                              audioPlaying
                                 ? "pause"
                                 : // <IconPause color="white" />
                                   chosenScene?.title

                              // <IconMelody color="white" />
                           }
                        </button>
                     </div>
                     <div className="col-6">
                        <button
                           onClick={() =>
                              setModals({ ...modals, description: true })
                           }
                           className="btn btn--3 w-100 bg-blue_ocean"
                        >
                           Anleitung
                        </button>
                     </div>
                     <div className="col-12">
                        <button
                           title="Editieren Beenden, screenshot aufnehmen, speichern..."
                           onClick={handlePreFinish}
                           className="btn btn--1 bg-primary w-100"
                        >
                           BEENDEN
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            <Modal
               type="modal--narrow"
               show={modals.final}
               onClose={handleCancel}
            >
               <div>
                  {finish.screenshot_error && (
                     <div>
                        <p>
                           Dein Browser Unterstützt leider keine Videoaufnahme.
                           Wir können leider deine Arbeit als Video nicht
                           speichern.
                        </p>
                        <button
                           onClick={handleSubmit}
                           className={
                              "btn btn--1 bg-purple border-none w-100" +
                              (finish.screenshot_loading ? " btn--loading" : "")
                           }
                        >
                           OK
                        </button>
                     </div>
                  )}
                  {finish.screenshot && (
                     <video
                        controls
                        src={getVideoUrl(finish.screenshot)}
                        autoPlay
                        loop
                     ></video>
                  )}
                  {finish.publish_with_name === null && (
                     <>
                        <h1 className="h1"> Glückwunsch!</h1>
                        <p>
                           Du hast dein Bühnenbild fertig gestellt. Wir werden
                           jetzt einen Screenshot davon aufnehmen. Sag uns
                           bitte, ob dein Name auch gespeichert werden soll,
                           oder ob du lieber anonym bleiben willst.
                        </p>
                        <div className="row">
                           <div className="col-md-6">
                              <button
                                 onClick={() => handleFinish(false)}
                                 className="btn btn--1 bg-purple border-none w-100"
                              >
                                 Anonym
                              </button>
                           </div>
                           <div className="col-md-6">
                              <button
                                 onClick={() => handleFinish(true)}
                                 className="btn btn--1 bg-purple border-none w-100"
                              >
                                 Öffentlich
                              </button>
                           </div>
                        </div>
                     </>
                  )}
                  {finish.publish_with_name !== null &&
                     !finish.screenshot &&
                     !finish.screenshot_error && (
                        <p>Die Bühne wird aufgenommen...</p>
                     )}
                  {finish.screenshot && (
                     <>
                        <h1 className="h4 mb-1">Sieht gut aus!</h1>
                        <p>
                           Das Bild wird jetzt in der Gallery gespeichert und
                           ich verabschiede mich. Bist du mit deinem Bühnenbild
                           zufrieden?
                        </p>
                        <div className="row">
                           <div className="col-md-6">
                              <button
                                 onClick={handleCancel}
                                 className={
                                    "btn btn--1 bg-red border-none w-100" +
                                    (finish.screenshot_loading
                                       ? " btn--loading"
                                       : "")
                                 }
                              >
                                 Weiterarbeiten
                              </button>
                           </div>
                           <div className="col-md-6">
                              <button
                                 onClick={handleSubmit}
                                 className={
                                    "btn btn--1 bg-purple border-none w-100" +
                                    (finish.screenshot_loading
                                       ? " btn--loading"
                                       : "")
                                 }
                              >
                                 Ja
                              </button>
                           </div>
                        </div>
                     </>
                  )}
               </div>
            </Modal>

            <Modal
               type="modal--narrow"
               show={modals.description}
               onClose={() => setModals({ ...modals, description: false })}
            >
               <div>
                  <h1 className="h3 reset">Anleitung:</h1>
                  <p>
                     Oben links kannst du Objekte anwählen, danach löschen oder
                     bearbeiten. Auch Hintergründe, Effekte und Soundloops
                     aktivierst du hier.
                     <br />
                     Tipp: Videos und Wände reagieren auf Licht. 
                  </p>
                  <p>
                     Oben rechts bearbeitest du dein Objekt. Du kannst es
                     drehen, vergrößern, verkleinern und bewegen. Klicke „save“,
                     um die Position zu speichern.
                  </p>
                  <p>
                     Unten links kannst du dein Beispiel abspielen, während du
                     arbeitest.
                  </p>
                  <p>„Beenden“ schliesst die „Magic Black Box“ ab.</p>
                  <p>
                     Unten rechts bedienst du die Ansicht. + und - sind fixe
                     Positionen, mit click & drag kannst du die Kamera rotieren.
                     Mit zwei Fingern / rechte Maustaste kannst du dich vertikal
                     bewegen und frei zoomen.
                  </p>
               </div>
            </Modal>
            {showCloneSettings && (
               <CloneSettingsUI
                  cloneUuid={cloneUuid}
                  onMoveObject={onMoveObject}
                  onRotateObject={onRotateObject}
                  onScaleObject={onScaleObject}
                  onResetObject={onResetObject}
                  onHideCloneSettingsUi={onHideCloneSettingsUi}
                  moveEnabled={moveEnabled}
                  setMoveEnabled={setMoveEnabled}
                  scaleEnabled={scaleEnabled}
                  setScaleEnabled={setScaleEnabled}
                  setSelectedItem={setSelectedItem}
               />
            )}

            <CameraSettingsUI onHandleCameraZoom={onHandleCameraZoom} />
         </div>
      </CSSTransition>
   );
}
