import PageServiceNav from "../Page/PageServiceNav";
import StickyNavigation from "../UI/StickyNavigation";
import { videos } from "./videosList";

export default function Videos() {
   return (
      <div className="pb-5 mh-100vh bg-white pt--100">
         <StickyNavigation />
         <div className="container">
            <h1 className="strong text-uppercase mb-5">Backstage Videos</h1>
            <div className="row">
               {videos.map((video) => (
                  <div className="col-4 mb-5">
                     <video controls>
                        <source src={video.videoUrl} type="video/webm" />
                     </video>
                     {video.label}
                  </div>
               ))}
            </div>
         </div>
         <div className="mt-5">
            <PageServiceNav />
         </div>
      </div>
   );
}
