interface InterfaceProps {
   progress?: number;
   styling?: "progress--default" | "progress--slider";
}

export default function ProgressBar({ progress, styling }: InterfaceProps) {
   const progressPerc = progress ? progress : 0;
   const style: React.CSSProperties = {
      width: progressPerc + "%",
   };
   return (
      <div className={"progress " + (styling ? styling : "")}>
         <div className="progress__fill" style={style}></div>
      </div>
   );
}
