import { useTeacherStore } from "../../store/appStore";
import Room from "./Room";
import RoomFormAdd from "./RoomFormAdd";

export default function RoomCreate() {
   const room = useTeacherStore((state) => state.room);
   if (room) {
      return <Room name={room.name} roomId={room.id} />;
   } else {
      return (
         <div className="bg-gradient pt-5 pb-5 mh-100vh d-flex flex-column align-items-center justify-content-center">
            <div className="container">
               <div className="row">
                  <div className="col-md-6 col-xl-4 m-auto">
                     <h2>Willkommen</h2>
                     <p>Hier können Sie den Raum für Ihre Klasse anlegen</p>

                     <RoomFormAdd />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
