import Modal from "../Modal";
import newItem from "../../Icons/addNew.png";
import { getRndInteger } from "../../../helpers/utils";
import { useStudentStore } from "../../../store/appStore";

export default function CloneAddbutton({ onAddNewObject, clones }: any) {
   const modals = useStudentStore((state) => state.modalsInEditor);
   const setModals = useStudentStore((state) => state.setModalsInEditor);
   const handleAddNewObjectToStage = (name: string) => {
      if (name && modals.objects3d) {
         onAddNewObject(name);
         setModals({ ...modals, objects3d: false });
      }
   };
   const openModal = () => {
      console.log(clones);
      setModals({ ...modals, objects3d: true });
   };
   return (
      <>
         <div
            className="cursor-pointer py-3 mx-1 d-flex opacity"
            onClick={openModal}
         >
            <img src={newItem} alt="Add new item" />
            <div className="p-3 hsmall">Neues Objekt</div>
         </div>

         <Modal
            type="modal--narrow"
            show={modals.objects3d}
            onClose={() => setModals({ ...modals, objects3d: false })}
         >
            <div className="row">
               {clones?.map((clone: any, index: number) => {
                  return (
                     <div
                        key={clone?.uuid + getRndInteger()}
                        className="col-3 p-3 cursor-pointer opacity text-center"
                        onClick={() => handleAddNewObjectToStage(clone?.name)}
                     >
                        <img
                           src={clone.thumbnail}
                           className="p-2 m-auto"
                           alt={clone.name}
                        />
                        <div>{clone.label}</div>
                     </div>
                  );
               })}
            </div>
         </Modal>
      </>
   );
}
