import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import mond from "../../../images/scenes/preview/230519_mbb_thumbnail_mond_hwmd.png";
import { EnumFundusMoon } from "../../../types/types";

export default function Slide27fundusMoon() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const scene = useStudentStore((state) => state.chosenScene);
   const setFundus = useStudentStore((state) => state.setFundus);
   const fundus = useStudentStore((state) => state.fundus);

   useEffect(() => {
      setInProp(true);
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const handleClick = (item: any) => {
      console.log(item);
      setFundus({
         ...fundus,
         moon: item,
      });
      nextSlide();
   };

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div
            ref={nodeRef}
            className="mh-100vh d-flex justify-content-center flex-column text-white text-center"
         >
            <img
               className="mb-4 mt-auto m-auto mb-0"
               width={300}
               src={scene?.image}
               alt=""
            />
            <h2 className="h3 mt-5">Ist der Mond zu sehen?</h2>
            <div className="mb-5 mt-5 d-flex ">
               <div className="ms-auto me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusMoon.full);
                     }}
                     type="button"
                     className="btn btn--circle mb-3 bg-transparent"
                  >
                     <img src={mond} />
                  </button>
                  <p className="text-uppercase">Ja</p>
               </div>
               {/* <div className="ms-5 me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusMoon.half);
                     }}
                     type="button"
                     className="btn btn--circle mb-3 m-auto"
                  >
                     <img src={alien} />
                  </button>
                  <p className="text-uppercase">Halbmond</p>
               </div> */}
               <div className="ms-5 me-auto">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusMoon.none);
                     }}
                     type="button"
                     className="btn btn--circle mb-3 bg-transparent"
                  >
                     {/* <img src={alien} /> */}
                  </button>
                  <p className="text-uppercase">Nein</p>
               </div>
            </div>
            <h4 className="h3 mb-2 mt-auto">{scene?.title}</h4>
            <p className="mb-5">{scene?.meta}</p>
         </div>
      </CSSTransition>
   );
}
