import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import SliderStepNav from "../SliderStepNav";
import theater from "../../../audio/intro/1a_Theater.mp3";

export default function Slide6CloudIntroTheater() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);

   let audio: any = useRef();

   useEffect(() => {
      setInProp(true);
      audio.current = new Audio(theater);
      audio.current.play();

      return () => {
         audio.current.pause();
      };
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <h2 className="h2 text-white text-center">
               Woran denkst du, wenn du &bdquo;Theater&ldquo; hörst?
            </h2>
            <SliderStepNav hidePrev={true} />
         </div>
      </CSSTransition>
   );
}
