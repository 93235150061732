export default function IconScale({ color }: any) {
   return (
      <svg
         id="Layer_1"
         version="1.1"
         viewBox="0 0 32 32"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g>
            <g>
               <path
                  fill={color ? color : "black"}
                  d="M24.4003906,25.4003906H7.5996094c-0.5527344,0-1-0.4472656-1-1V7.5996094c0-0.5527344,0.4472656-1,1-1h16.8007813    c0.5527344,0,1,0.4472656,1,1v16.8007813C25.4003906,24.953125,24.953125,25.4003906,24.4003906,25.4003906z     M8.5996094,23.4003906h14.8007813V8.5996094H8.5996094V23.4003906z"
               />
            </g>
            <g>
               <path
                  fill={color ? color : "black"}
                  d="M2,8.5996094c-0.5527344,0-1-0.4472656-1-1V2c0-0.5527344,0.4472656-1,1-1h5.5996094c0.5527344,0,1,0.4472656,1,1    s-0.4472656,1-1,1H3v4.5996094C3,8.1523438,2.5527344,8.5996094,2,8.5996094z"
               />
            </g>
            <g>
               <path
                  fill={color ? color : "black"}
                  d="M30,8.5996094c-0.5527344,0-1-0.4472656-1-1V3h-4.5996094c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1H30    c0.5527344,0,1,0.4472656,1,1v5.5996094C31,8.1523438,30.5527344,8.5996094,30,8.5996094z"
               />
            </g>
            <g>
               <path
                  fill={color ? color : "black"}
                  d="M7.5996094,31H2c-0.5527344,0-1-0.4472656-1-1v-5.5996094c0-0.5527344,0.4472656-1,1-1s1,0.4472656,1,1V29h4.5996094    c0.5527344,0,1,0.4472656,1,1S8.1523438,31,7.5996094,31z"
               />
            </g>
            <g>
               <path
                  fill={color ? color : "black"}
                  d="M30,31h-5.5996094c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1H29v-4.5996094c0-0.5527344,0.4472656-1,1-1    s1,0.4472656,1,1V30C31,30.5527344,30.5527344,31,30,31z"
               />
            </g>
         </g>
      </svg>
   );
}
