import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import SliderStepNav from "../SliderStepNav";
import { subscribeToCollection } from "../../../services/subscriptions";
import { EnumTarget, KeywordInterface } from "../../../types/types";
import interessant from "../../../audio/intro/11_MBB_Guck_mal.mp3";

export default function Slide11() {
   const [inProp, setInProp] = useState(false);
   const [keywords, setKeywords] = useState<KeywordInterface[]>([]);
   const [maxCount, setMaxCount] = useState(0);
   const [cloudVisible, setCloudVisible] = useState(false);
   const [keywordsOper, setKeywordsOper] = useState<KeywordInterface[]>([]);
   const nodeRef = useRef(null);
   let audio: any = useRef();

   // fontsize
   //--------------------------------------------------------
   const maxFontSize = 80;
   const minFontSize = 20;
   const FontSizeDelta = maxFontSize - minFontSize;

   const updateKeywords = (items: KeywordInterface[]) => {
      const keywords_copy = [...keywords];
      if (keywords_copy.length === 0) {
         updateMaxCount(items);
         setKeywords([...items]);
         return;
      }

      const newItems: KeywordInterface[] = [];
      keywords_copy.forEach((keyword) => {
         items.forEach((item) => {
            if (keyword.id === item.id && keyword.count !== item.count) {
               newItems.push(item);
            } else {
               newItems.push(keyword);
            }
            updateMaxCount(items);
         });
      });
      setKeywords([...newItems]);
   };

   const updateKeywordsOper = (items: KeywordInterface[]) => {
      const keywords_copy = [...keywordsOper];
      if (keywords_copy.length === 0) {
         updateMaxCount(items);
         setKeywordsOper([...items]);
         return;
      }

      const newItems: KeywordInterface[] = [];
      keywords_copy.forEach((keyword) => {
         items.forEach((item) => {
            if (keyword.id === item.id && keyword.count !== item.count) {
               newItems.push(item);
            } else {
               newItems.push(keyword);
            }
            updateMaxCount(items);
         });
      });
      setKeywordsOper([...newItems]);
   };

   useEffect(() => {
      setInProp(true);
      let allItems: KeywordInterface[] = [];
      audio.current = new Audio(interessant);
      audio.current.onended = () => {
         setCloudVisible(true);
      };

      audio.current.play();

      subscribeToCollection({
         collectionName: "Answers",
         docId: EnumTarget.theather,
         subcollectionName: "Keywords",
         callback: (items: KeywordInterface[]) => {
            updateKeywords(items);
         },
      });

      subscribeToCollection({
         collectionName: "Answers",
         docId: EnumTarget.oper,
         subcollectionName: "Keywords",
         callback: (items: any) => {
            updateKeywordsOper(items);
         },
      });

      return () => {
         audio.current.pause();
      };
   }, []);

   // helpers
   //--------------------------------------------------------
   const updateMaxCount = (items: any) => {
      items.forEach((item: any) => {
         if (maxCount < item.count) setMaxCount(item.count);
      });
   };

   // render
   //--------------------------------------------------------

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <div className="container">
               <div
                  className={
                     "row" +
                     (cloudVisible ? " item-enter-active" : " item-enter")
                  }
               >
                  <div className="col-xl-10 m-auto">
                     <div className="d-flex flex-wrap justify-content-center">
                        {keywords?.map((keyword, index) => {
                           return (
                              <span
                                 key={index}
                                 data-count={keyword.count}
                                 style={{
                                    fontSize:
                                       20 +
                                       keyword.count *
                                          (FontSizeDelta / maxCount),
                                 }}
                                 className="text-white p-3 shadow"
                              >
                                 {keyword.name}
                              </span>
                           );
                        })}
                        {keywordsOper?.map((keyword, index) => {
                           return (
                              <span
                                 key={index}
                                 data-count={keyword.count}
                                 style={{
                                    fontSize:
                                       20 +
                                       keyword.count *
                                          (FontSizeDelta / maxCount),
                                 }}
                                 className="text-white p-3 shadow"
                              >
                                 {keyword.name}
                              </span>
                           );
                        })}
                     </div>
                  </div>
               </div>
            </div>
            <div
               className={cloudVisible ? " item-enter-active" : " item-enter"}
            >
               <SliderStepNav />
            </div>
         </div>
      </CSSTransition>
   );
}
