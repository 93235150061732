import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../../store/appStore";
import unicorn from "../../../images/scenes/preview/230519_mbb_thumbnail_unicorn_hwmd.png";
import fuchs from "../../../images/scenes/preview/230519_mbb_thumbnail_fuchs_hwmd.png";
import laterne from "../../../images/scenes/preview/230519_mbb_thumbnail_laterne_hwmd.png";
import { EnumFundusMoon, EnumFundusWhere } from "../../../types/types";
import IconArrow from "../../Icons/IconArrow";

export default function Slide25fundusScene() {
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);
   const scene = useStudentStore((state) => state.chosenScene);
   const setFundus = useStudentStore((state) => state.setFundus);
   const fundus = useStudentStore((state) => state.fundus);

   useEffect(() => {
      setInProp(true);
   }, []);

   // methods
   //--------------------------------------------------------
   const nextSlide = useStudentStore((state) => state.incrementSliderIndex);
   const handleClick = (item: any) => {
      const resetFundus = { where: item };
      setFundus({
         ...fundus,
         where: item,
         moon: EnumFundusMoon.none,
         people: 0,
      });
   };

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div
            ref={nodeRef}
            className="mh-100vh d-flex justify-content-center flex-column text-white text-center"
         >
            <img
               className="mb-4 mt-auto m-auto mb-0"
               width={300}
               src={scene?.image}
               alt=""
            />
            <h2 className="h3 mt-5">
               Du hast freie Auswahl: wo willst du deine Szene spielen lassen?
            </h2>
            <div className="mb-5 mt-5 d-flex ">
               <div className="ms-auto me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusWhere.nature);
                     }}
                     type="button"
                     className={
                        "btn btn--circle mb-3" +
                        (fundus?.where === EnumFundusWhere.nature
                           ? " bg-primary border-purple"
                           : " bg-transparent")
                     }
                  >
                     <img src={fuchs} />
                  </button>
                  <p className="text-uppercase">In der natur</p>
               </div>
               <div className="ms-5 me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusWhere.phantasy);
                     }}
                     type="button"
                     className={
                        "btn btn--circle mb-3" +
                        (fundus?.where === EnumFundusWhere.phantasy
                           ? " bg-primary border-purple"
                           : " bg-transparent")
                     }
                  >
                     <img src={unicorn} />
                  </button>
                  <p className="text-uppercase">An einem Phantasieort</p>
               </div>
               <div className="ms-5 me-5">
                  <button
                     onClick={() => {
                        handleClick(EnumFundusWhere.city);
                     }}
                     type="button"
                     className={
                        "btn btn--circle mb-3" +
                        (fundus?.where === EnumFundusWhere.city
                           ? " bg-primary border-purple"
                           : " bg-transparent")
                     }
                  >
                     <img src={laterne} />
                  </button>
                  <p className="text-uppercase">In einer Stadt</p>
               </div>
               <div className="ms-5 me-auto">
                  <button
                     onClick={() => {
                        nextSlide();
                     }}
                     type="button"
                     className="btn btn--circle mb-3 bg-transparent"
                  >
                     <IconArrow color="rgba(345, 345, 345, 0.5)" />
                  </button>
                  <p className="text-uppercase">Weiter</p>
               </div>
            </div>
            <h4 className="h3 mb-2 mt-auto">{scene?.title}</h4>
            <p className="mb-5">{scene?.meta}</p>
         </div>
      </CSSTransition>
   );
}
