import { useEffect, useRef, useState } from "react";
import { useStudentStore } from "../../store/appStore";
import Slider from "../Slider/Slider";
import SliderItem from "../Slider/SliderItem";
import Slide0Start from "../Slider/Slides/Slide0Start";
import Slide0End from "../Slider/Slides/Slide0End";
import Slide1 from "../Slider/Slides/Slide1";
import Slide2 from "../Slider/Slides/Slide2";
import Slide3 from "../Slider/Slides/Slide3";
import Slide4 from "../Slider/Slides/Slide4";
import Slide5 from "../Slider/Slides/Slide5";
import Slide6AudioIntro from "../Slider/Slides/Slide6AudioIntro";
import Slide6CloudIntroTheater from "../Slider/Slides/Slide6CloudIntroTheater";
import Slide7CloudTheater from "../Slider/Slides/Slide7CloudTheater";
import Slide8CloudIntro from "../Slider/Slides/Slide8CloudIntro";
import Slide9Cloud from "../Slider/Slides/Slide9Cloud";
import Slide10Cloud from "../Slider/Slides/Slide10Cloud";
import Slide11 from "../Slider/Slides/Slide11";
import Slide12 from "../Slider/Slides/Slide12";
import Slide12AudioOutro from "../Slider/Slides/Slide12AudioOutro";
import Slide13 from "../Slider/Slides/Slide13";
import Slide14Audio from "../Slider/Slides/Slide14Audio";
import Slide15Configurator from "../Slider/Slides/Slide15Configurator";
import Slide16ArieEdit from "../Slider/Slides/Slide16ArieEdit";
import Slide17ArieQuestion1 from "../Slider/Slides/Slide17ArieQuestion1";
import Slide18ArieQuestion2 from "../Slider/Slides/Slide18ArieQuestion2";
import Slide19ArieQuestion3 from "../Slider/Slides/Slide19ArieQuestion3";
import Slide20ArieQuestion4 from "../Slider/Slides/Slide20ArieQuestion4";
import Slide21ArieQuestion5 from "../Slider/Slides/Slide21ArieQuestion5";
import Slide22ArieQuestion6 from "../Slider/Slides/Slide22ArieQuestion6";
import Slide23ArieQuestion7 from "../Slider/Slides/Slide23ArieQuestion7";
import Slide24AudioEnd from "../Slider/Slides/Slide24AudioEnd";
import Slide25fundusScene from "../Slider/Slides/Slide25fundusScene";
import Slide26fundusTime from "../Slider/Slides/Slide26fundusTime";
import Slide27fundusMoon from "../Slider/Slides/Slide27fundusMoon";
import Slide28fundusMood from "../Slider/Slides/Slide28fundusMood";
import IconLogo from "../Icons/IconLogo";
import SlideEditor from "../Slider/Slides/SlideEditor";
import Slide29fundusPeople from "../Slider/Slides/Slide29fundusPeople";
import Slide30fundusSpecial from "../Slider/Slides/Slide30fundusSpecial";
import { EnumCameraZoom, EnumFundusMoon } from "../../types/types";
import AnimationBox from "../AnimationBox/AnimationBox";
import { EnumFundusCamera } from "../../types/types";
import Slide31Outro from "../Slider/Slides/Slide31Outro";
import SlideGameOver from "../Slider/Slides/SlideGameOver";

require("be-threejs");

declare global {
   namespace JSX {
      interface IntrinsicElements {
         "be-stage": any;
      }
   }
}

export default function Student() {
   const sliderIndex = useStudentStore((state) => state.sliderIndex);
   const fundus = useStudentStore((state) => state.fundus);
   const setAssets = useStudentStore((state) => state.setAssets);
   const storeAssets = useStudentStore((state) => state.assets);
   const beStage = useStudentStore((state) => state.beStage);
   const beStageMenu = useStudentStore((state) => state.beStageMenu);
   const bethreejs = useRef<null | any>(null);
   const chosenScene = useStudentStore((state) => state.chosenScene);
   const [loadingModel, setLoadingModel] = useState<boolean>(true);

   //*******************************************************
   // cycle
   //*******************************************************

   // MISC
   //--------------------------------------------------------

   //lets warn the users if they reload page
   useEffect(() => {
      window.addEventListener("beforeunload", (ev) => {
         ev.preventDefault();
         return (ev.returnValue = "Are you sure you want to close?");
      });
   }, []);

   //MODEL
   //--------------------------------------------------------

   // lets load the 3d model here and wait for the assets
   useEffect(() => {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.addEventListener("afterModelsLoaded", () => {
            setTimeout(() => {
               setLoadingModel(false);
               const assets = bethreejs?.current?.fetchAssets();
               assets && setAssets(assets);
            }, 500);
         });
      }
   }, [beStage]);

   //lets control the model here
   useEffect(() => {
      if (bethreejs && bethreejs.current && fundus) {
         if (fundus.where) {
            bethreejs.current.cleanStage().then(() => {
               bethreejs.current.setStage({
                  scene: chosenScene?.meta_title,
                  place: fundus?.where,
               });
            });
         }

         const assets = bethreejs?.current?.fetchAssets();
         assets && setAssets(assets);
      }
   }, [fundus?.where]);

   useEffect(() => {
      if (
         bethreejs &&
         bethreejs.current &&
         fundus &&
         storeAssets &&
         storeAssets.lights.length > 0
      ) {
         const frontLight = storeAssets?.lights?.find(
            (light) => light.name === "Front_Light"
         );
         console.log(frontLight);

         if (fundus.when && frontLight) {
            console.log(frontLight.uuid);
            handleSetLightIntensity(frontLight.uuid, fundus.when);
            const assets = bethreejs?.current?.fetchAssets();
            assets && setAssets(assets);
         }
      }
   }, [fundus?.when]);

   useEffect(() => {
      console.log(fundus?.moon);
      if (bethreejs && bethreejs.current && fundus) {
         if (fundus?.moon && fundus?.moon !== EnumFundusMoon?.none) {
            bethreejs.current.setStage({
               scene: chosenScene?.meta_title,
               place: fundus.where,
               moon: fundus?.moon,
            });
         }

         const assets = bethreejs?.current?.fetchAssets();
         assets && setAssets(assets);
      }
   }, [fundus?.moon]);

   useEffect(() => {
      if (bethreejs && bethreejs.current && fundus) {
         if (
            fundus?.people &&
            Number.isInteger(fundus?.people) &&
            fundus?.people > 0
         ) {
            bethreejs.current.setStage({
               scene: chosenScene?.meta_title,
               place: fundus.where,
               people: fundus?.people,
            });
         }

         const assets = bethreejs?.current?.fetchAssets();
         assets && setAssets(assets);
      }
   }, [fundus?.people]);

   useEffect(() => {
      if (bethreejs && bethreejs.current && fundus) {
         if (fundus?.special) {
            bethreejs.current.setEffect(fundus?.special, true);
         }

         const assets = bethreejs?.current?.fetchAssets();
         assets && setAssets(assets);
      }
   }, [fundus?.special]);

   useEffect(() => {
      if (bethreejs && bethreejs.current && fundus) {
         if (fundus.camera) {
            if (fundus.camera === EnumFundusCamera.zoomOut)
               bethreejs.current?.zoomOut();
            if (fundus.camera === EnumFundusCamera.zoomIn)
               bethreejs.current?.zoomIn();
         }
      }
   }, [fundus?.camera]);

   useEffect(() => {
      if (bethreejs && bethreejs.current && fundus) {
         if (fundus.mood) {
            console.log(fundus.mood);
            bethreejs.current?.setAllEnabledLightsColor(fundus.mood);
         }
      }
   }, [fundus?.mood]);

   //*******************************************************
   // BEFORE FINISH - STAGE MANIPULATION
   //*******************************************************
   function handleAddNewObject(name: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.throwClone(name);

         const assets = bethreejs?.current?.fetchAssets();
         assets && setAssets(assets);
      }
   }

   function handleDeleteObject(uuid: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.deleteCloneByUuid(uuid);

         const assets = bethreejs?.current?.fetchAssets();
         assets && setAssets(assets);
      }
   }

   function handleGrabObject(uuid: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.grabObjectByUuid(uuid);
      }
   }

   function handleMoveObject(uuid: string, direction: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.moveCloneByUuid(uuid, direction);
      }
   }

   function handleRotateObject(uuid: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.rotateCloneByUuid(uuid);
      }
   }

   function handleScaleObject(uuid: string, direction: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.scaleCloneByUuid(uuid, direction);
      }
   }

   function handleResetObject() {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.resetActiveClone();
      }
   }

   function handleToggleLight(uuid: string) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.toggleLightByUuid(uuid);
         const assets = bethreejs?.current?.fetchAssets();
         console.log("Fetched assets in handleToggleLight:", assets);
         assets && setAssets(assets);
      }
   }

   function handleSetLightIntensity(uuid: string, intensity: number) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.setLightIntensityByUuid(uuid, intensity);
         const assets = bethreejs?.current?.fetchAssets();
         console.log("Fetched assets in handleSetLightIntensity:", assets);
         assets && setAssets(assets);
      }
   }

   function handleSetLightColor(uuid: string, color: string) {
      console.log(color);
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.setLightColorByUuid(uuid, color);
         const assets = bethreejs?.current?.fetchAssets();
         console.log("Fetched assets: ", assets);
         assets && setAssets(assets);
      }
   }

   function handleToggleEffect(effect: string, enable: boolean) {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.setEffect(effect, enable);

         const assets = bethreejs?.current?.fetchAssets();
         console.log("assets", assets);
         assets && setAssets(assets);
      }
   }

   function handleApplyTexture(textureName: string, target = "walls") {
      console.log(textureName);
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.applyTexture(textureName, target);
      }
   }

   function handleResetTexture(target = "walls") {
      if (bethreejs && bethreejs.current) {
         bethreejs?.current?.resetTexture(target);
      }
   }

   function handleCameraZoom(zoom: string) {
      console.log("zoom", zoom);
      if (!bethreejs || !bethreejs.current) return false;
      if (zoom === EnumCameraZoom.zoomIN) {
         console.log(bethreejs.current);
         console.log("zoomOut");
         bethreejs.current?.zoomIn();
      }
      if (zoom === EnumFundusCamera.zoomOut) {
         console.log("zoomout");
         bethreejs.current?.zoomOut();
      }
   }

   //*******************************************************
   // FINISH
   //*******************************************************
   async function handleFinish() {
      const img = await bethreejs.current?.fetchScreenshot();
      return img;
   }

   //*******************************************************
   // Render
   //*******************************************************

   return (
      <div
         className={
            "headlines-shadow mh-100vh " +
            (sliderIndex >= 19 ? chosenScene?.background : "") +
            (beStageMenu ? " slider--bestage" : "")
         }
      >
         {sliderIndex > 2 && sliderIndex < 36 && (
            <div className="logo">
               <div className="container">
                  <IconLogo color={"#fff"} />
               </div>
            </div>
         )}

         {/* {sliderIndex <= 16 && <AnimationBox />} */}
         <AnimationBox />
         <Slider>
            <SliderItem>
               <Slide0Start />
            </SliderItem>

            <SliderItem>
               <Slide0End />
            </SliderItem>

            <SliderItem>
               <Slide1 />
            </SliderItem>

            <SliderItem>
               <Slide2 />
            </SliderItem>

            <SliderItem>
               <Slide3 />
            </SliderItem>

            <SliderItem>
               <Slide4 />
            </SliderItem>

            <SliderItem>
               <Slide5 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide6AudioIntro />
            </SliderItem>

            <SliderItem>
               <Slide6CloudIntroTheater />
            </SliderItem>

            <SliderItem>
               <Slide7CloudTheater />
            </SliderItem>

            <SliderItem>
               <Slide8CloudIntro />
            </SliderItem>

            <SliderItem>
               <Slide9Cloud />
            </SliderItem>

            <SliderItem>
               <Slide10Cloud />
            </SliderItem>

            <SliderItem>
               <Slide11 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide12AudioOutro />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide12 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide13 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide14Audio />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide15Configurator />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide16ArieEdit />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide17ArieQuestion1 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide18ArieQuestion2 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide19ArieQuestion3 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide20ArieQuestion4 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide21ArieQuestion5 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide22ArieQuestion6 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide23ArieQuestion7 />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide24AudioEnd />
            </SliderItem>

            <SliderItem fullHeight={true}>
               {!beStage && <Slide25fundusScene />}
               {beStage && !loadingModel && <Slide25fundusScene />}
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide26fundusTime />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide27fundusMoon />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide28fundusMood />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide29fundusPeople />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <Slide30fundusSpecial />
            </SliderItem>

            <SliderItem>
               <Slide31Outro />
            </SliderItem>

            <SliderItem fullHeight={true}>
               <SlideEditor
                  onDeleteObject={handleDeleteObject}
                  onAddNewObject={handleAddNewObject}
                  onFinish={handleFinish}
                  onGrabObject={handleGrabObject}
                  onMoveObject={handleMoveObject}
                  onRotateObject={handleRotateObject}
                  onScaleObject={handleScaleObject}
                  onResetObject={handleResetObject}
                  onToggleLight={handleToggleLight}
                  onSetLightIntensity={handleSetLightIntensity}
                  onHandleToggleEffect={handleToggleEffect}
                  onHandleCameraZoom={handleCameraZoom}
                  onHandleApplyTexture={handleApplyTexture}
                  onHandleResetTexture={handleResetTexture}
                  onSetLightColor={handleSetLightColor}
               />
            </SliderItem>
            <SliderItem>
               <SlideGameOver />
            </SliderItem>
         </Slider>

         {beStage && (
            <div
               className={
                  "be-stage " + (loadingModel ? "be-stage--loading" : "")
               }
            >
               <be-stage ref={bethreejs}></be-stage>
            </div>
         )}
         {beStage && loadingModel && (
            <div
               style={{
                  position: "absolute",
                  bottom: "5%",
                  left: "5%",
                  color: "white",
                  fontSize: "1.5rem",
               }}
            >
               Fundus wird geladen
            </div>
         )}
      </div>
   );
}
