import { throwClone, throwClonesOnRandomPosition } from "../../control/clones";

export const feischuetz = (state) => {
   const scenePositionsNature = [
      {
         name: "Spotlight_Top_Light",
         uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
         position: { x: -6, y: 56, z: 23 },
         rotation: 0,
         scale: 1,
         intensity: "10",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Spotlight_Center_Light",
         uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
         position: { x: -6, y: 36, z: 60 },
         rotation: 0,
         scale: 1,
         intensity: "1",
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Top_Light",
         uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
         position: { x: 0, y: 33, z: 0 },
         rotation: 0,
         scale: 1,
         intensity: "4",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Right_Light",
         uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
         position: { x: 40.5, y: -48, z: 4 },
         rotation: 0,
         scale: 1,
         intensity: "7",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Left_Light",
         uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
         position: { x: -15.9, y: 30, z: -9 },
         rotation: 0,
         scale: 1,
         intensity: "10",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Front_Light",
         uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
         position: { x: -6, y: 47, z: 101.4 },
         rotation: 0,
         scale: 1,
         intensity: "1",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Back_Light",
         uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
         position: { x: 40.5, y: 47, z: -548 },
         rotation: 0,
         scale: 1,
         intensity: 4,
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "rocks",
         uuid: "ffc12ce1-6550-4ff3-a91e-1c06e9b4a480",
         position: { x: 0, y: -0.9316002580777007, z: -1 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "moon",
         uuid: "e88438ea-0156-4149-87b1-ea27ee766919",
         position: { x: -2, y: -2.62821103697727, z: -7 },
         rotation: 0,
         scale: 1.2,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "tree_dead",
         uuid: "33d72554-e9fb-4249-93a3-5ab4bdacc4a2",
         position: { x: 29, y: -5.035770710106313, z: -5 },
         rotation: 0,
         scale: 2.157739,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "tree_dead",
         uuid: "08c3b855-8410-438f-906b-c572151cfef6",
         position: { x: 1, y: 10.326611158558165, z: 4 },
         rotation: 0,
         scale: 1.2,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar",
         uuid: "a3aeeaae-a5af-445a-a591-ee6660bdac8c",
         position: { x: 4, y: 3.1536113099296736, z: 1 },
         rotation: 0.2,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar",
         uuid: "1cf45680-2eec-402f-ae24-e698d483e75d",
         position: { x: -11, y: 11.42566267742417, z: 3 },
         rotation: 0.6000000000000001,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar",
         uuid: "66003b56-82a3-4974-970e-4b09686545c9",
         position: { x: 15, y: -0.01160459754679944, z: 8 },
         rotation: 3.400000000000001,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
   ];
   let avatarsNature = [];
   scenePositionsNature.some((avatar, index) => {
      if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
         avatarsNature.push(avatar);
   });

   let moonsNature = [];
   scenePositionsNature.some((elem) => {
      if (elem.name === "moon") moonsNature.push(elem);
   });

   let moonsHalfNature = [];
   scenePositionsNature.some((elem) => {
      if (elem.name === "half_moon") moonsHalfNature.push(elem);
   });

   const scenePositionsPhantasy = [
      {
         name: "Spotlight_Top_Light",
         uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
         position: { x: -6, y: 56, z: 23 },
         rotation: 0,
         scale: 1,
         intensity: "6",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Spotlight_Center_Light",
         uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
         position: { x: -6, y: 36, z: 60 },
         rotation: 0,
         scale: 1,
         intensity: 10,
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Top_Light",
         uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
         position: { x: 0, y: 33, z: 0 },
         rotation: 0,
         scale: 1,
         intensity: "2",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Right_Light",
         uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
         position: { x: 40.5, y: -48, z: 4 },
         rotation: 0,
         scale: 1,
         intensity: "2",
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Left_Light",
         uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
         position: { x: -15.9, y: 30, z: -9 },
         rotation: 0,
         scale: 1,
         intensity: 4,
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Front_Light",
         uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
         position: { x: -6, y: 47, z: 101.4 },
         rotation: 0,
         scale: 1,
         intensity: "2",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Back_Light",
         uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
         position: { x: 40.5, y: 47, z: -548 },
         rotation: 0,
         scale: 1,
         intensity: 4,
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Unicorn",
         uuid: "9a7576c9-3909-4bd5-9e1b-69b354968d4c",
         position: { x: -1, y: 2.2552538534314412, z: 1 },
         rotation: 0,
         scale: 2.06861,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "lantern",
         uuid: "0b40f82c-6274-4491-9421-e877a7d77835",
         position: { x: -10, y: -2.668135951825415, z: -7 },
         rotation: 1.2,
         scale: 1.697913,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "und",
         uuid: "e015e66e-e5f3-4f05-997d-9fe6a9731537",
         position: { x: -8, y: 12.73517086535592, z: -6 },
         rotation: 0.6000000000000001,
         scale: 2.404,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "tanne",
         uuid: "cf497d1e-4c5d-4fb3-8394-38af58145b30",
         position: { x: 2, y: 18.37937283303313, z: 5 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "moon",
         uuid: "c236b54e-03d4-4de3-854f-536fdcfab4e2",
         position: { x: 14, y: -7.423508415852701, z: 4 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar",
         uuid: "33ca65a0-f65f-4f48-80c5-9f289aa06d2d",
         position: { x: -8, y: -0.018384350975010982, z: -4 },
         rotation: 0.6000000000000001,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar_sitzend",
         uuid: "9081ca6a-5d71-4498-a9a1-ef060b341f98",
         position: { x: 13, y: 8.11503718055423, z: 5 },
         rotation: 6.000000000000003,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar_sitzend",
         uuid: "15ca016b-9ace-4b87-aad1-185910356956",
         position: { x: -8, y: 13.463475497548123, z: -5 },
         rotation: 0.6000000000000001,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
   ];

   let avatarsPhantasy = [];
   scenePositionsPhantasy.some((avatar, index) => {
      if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
         avatarsPhantasy.push(avatar);
   });

   let moonsPhantasy = [];
   scenePositionsPhantasy.some((elem) => {
      if (elem.name === "moon") moonsPhantasy.push(elem);
   });

   let moonsHalfPhantasy = [];
   scenePositionsPhantasy.some((elem) => {
      if (elem.name === "half_moon") moonsHalfPhantasy.push(elem);
   });

   const scenePositionsCity = [
      {
         name: "Spotlight_Top_Light",
         uuid: "0a0dd88e-c2b9-4821-8617-5d0c0be17ba8",
         position: { x: -6, y: 56, z: 23 },
         rotation: 0,
         scale: 1,
         intensity: "10",
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Spotlight_Center_Light",
         uuid: "c96c3597-5f3a-4fcc-95f7-37f05bc73692",
         position: { x: -6, y: 36, z: 60 },
         rotation: 0,
         scale: 1,
         intensity: "1",
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Top_Light",
         uuid: "97acb9db-74e7-495a-99a1-f80dac6c2801",
         position: { x: 0, y: 33, z: 0 },
         rotation: 0,
         scale: 1,
         intensity: "1",
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "Right_Light",
         uuid: "19480920-dc68-4002-8653-06a10c70c4a5",
         position: { x: 40.5, y: -48, z: 4 },
         rotation: 0,
         scale: 1,
         intensity: "4",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Left_Light",
         uuid: "8a41a83b-0e56-4236-948a-3547977dc4bd",
         position: { x: -15.9, y: 30, z: -9 },
         rotation: 0,
         scale: 1,
         intensity: "2",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Front_Light",
         uuid: "854579f0-de99-4c58-a74f-445c8d313ac3",
         position: { x: -6, y: 47, z: 101.4 },
         rotation: 0,
         scale: 1,
         intensity: "2",
         color: { r: 1, g: 1, b: 1 },
         visible: true,
      },
      {
         name: "Back_Light",
         uuid: "2aacdf77-0f7f-4caa-8a62-1bd805541871",
         position: { x: 40.5, y: 47, z: -548 },
         rotation: 0,
         scale: 1,
         intensity: "1",
         color: { r: 1, g: 1, b: 1 },
         visible: false,
      },
      {
         name: "bus_stop",
         uuid: "fb14a3c2-a551-4738-b8dd-d36691fbc597",
         position: { x: -10, y: -3.4541858337565103, z: -9 },
         rotation: 0,
         scale: 1.797401,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "moon",
         uuid: "fe08f358-f5cc-4a67-a5e7-82ed48f55a2e",
         position: { x: -9, y: 5.304975162025024, z: 6 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar_sitzend",
         uuid: "c9b2bd21-90bc-4ca3-903a-6bea2418995b",
         position: { x: -9, y: 0.027437821750919328, z: -9 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar_sitzend",
         uuid: "d4487661-e5fc-4f5e-b3cd-2ba5aeff3c2c",
         position: { x: -7, y: 0.428291400616183, z: -9 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "avatar",
         uuid: "470c9e6b-87b6-471d-8e10-c8519fa5c89c",
         position: { x: 1, y: -0.1381371347443544, z: -7 },
         rotation: 0.4,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "neon_m",
         uuid: "73ed5c6f-f909-463f-964d-f99b4891f400",
         position: { x: 7, y: 20.25163741238977, z: 0 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "neon_l",
         uuid: "9c2618b3-ed43-4b4e-af8e-e8aad35ac28e",
         position: { x: 14, y: 18.52463498029904, z: 0 },
         rotation: 6.200000000000003,
         scale: 1.2,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "neon_i",
         uuid: "07d51535-90b8-40c5-a94f-0304b2967798",
         position: { x: 11, y: 19.407057292655775, z: 0 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
      {
         name: "neon_k",
         uuid: "b19d4582-a7a2-492c-9954-6a83ef535057",
         position: { x: 17, y: 20.18107232491655, z: 0 },
         rotation: 0,
         scale: 1,
         intensity: "",
         color: "",
         visible: true,
      },
   ];
   let avatarsCity = [];
   scenePositionsCity.some((avatar, index) => {
      if (avatar.name === "avatar" || avatar.name === "avatar_sitzend")
         avatarsCity.push(avatar);
   });

   let moonsCity = [];
   scenePositionsCity.some((elem) => {
      if (elem.name === "moon") moonsCity.push(elem);
   });

   let moonsHalfCity = [];
   scenePositionsCity.some((elem) => {
      if (elem.name === "half_moon") moonsHalfCity.push(elem);
   });

   return {
      feischtz_nature() {
         scenePositionsNature.forEach((position, index) => {
            setTimeout(() => {
               // lights
               if (position.name.includes("Light")) {
                  const currentLight = state.scene.getObjectByName(
                     position.name
                  );
                  if (currentLight) {
                     currentLight.visible = position?.visible;
                     currentLight.intensity = position?.intensity;
                  }
               }
               // everything except moon and avatars
               if (
                  position.name !== "moon" &&
                  position.name !== "half_moon" &&
                  position.name !== "avatar" &&
                  position.name !== "avatar_sitzend"
               ) {
                  throwClone({
                     state: state,
                     name: position.name,
                     x: position.position.x,
                     y_from: 20,
                     y_to: position.position.y,
                     z: position.position.z,
                     scale: position.scale,
                     rotation: position.rotation,
                  });
               }
            }, (index + 1) * 100);
         });
      },

      feischtz_nature_moon_full() {
         moonsNature.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_nature_moon_half() {
         moonsHalfNature.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_nature_moon_new() {
         // ToDo
         console.log("feischtz_nature_moon_new");
      },

      feischtz_nature_people_1() {
         const avatars1 = avatarsNature.slice(0, 1);
         avatars1.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_nature_people_2() {
         const avatars1 = avatarsNature.slice(0, 2);
         avatars1.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_nature_people_3() {
         avatarsNature.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_nature_people_4() {
         // ToDo
         console.log("feischtz_nature_people_4");
      },

      feischtz_phantasy() {
         scenePositionsPhantasy.forEach((position, index) => {
            setTimeout(() => {
               // lights
               if (position.name.includes("Light")) {
                  const currentLight = state.scene.getObjectByName(
                     position.name
                  );
                  if (currentLight) {
                     currentLight.visible = position?.visible;
                     currentLight.intensity = position?.intensity;
                  }
               }
               // everything except moon and avatars
               if (
                  position.name !== "moon" &&
                  position.name !== "half_moon" &&
                  position.name !== "avatar" &&
                  position.name !== "avatar_sitzend"
               ) {
                  throwClone({
                     state: state,
                     name: position.name,
                     x: position.position.x,
                     y_from: 20,
                     y_to: position.position.y,
                     z: position.position.z,
                     scale: position.scale,
                     rotation: position.rotation,
                  });
               }
            }, (index + 1) * 100);
         });
      },

      feischtz_phantasy_moon_full() {
         moonsPhantasy.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_phantasy_moon_half() {
         moonsHalfPhantasy.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_phantasy_moon_new() {
         // ToDo
         console.log("feischtz_phantasy_moon_new");
      },

      feischtz_phantasy_people_1() {
         const avatars1 = avatarsPhantasy.slice(0, 1);
         avatars1.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_phantasy_people_2() {
         const avatars1 = avatarsPhantasy.slice(0, 2);
         avatars1.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_phantasy_people_3() {
         avatarsPhantasy.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_phantasy_people_4() {
         // ToDo
         console.log("feischtz_phantasy_people_4");
      },

      feischtz_city() {
         scenePositionsCity.forEach((position, index) => {
            setTimeout(() => {
               // lights
               if (position.name.includes("Light")) {
                  const currentLight = state.scene.getObjectByName(
                     position.name
                  );
                  if (currentLight) {
                     currentLight.visible = position?.visible;
                     currentLight.intensity = position?.intensity;
                  }
               }
               // everything except moon and avatars
               if (
                  position.name !== "moon" &&
                  position.name !== "half_moon" &&
                  position.name !== "avatar" &&
                  position.name !== "avatar_sitzend"
               ) {
                  throwClone({
                     state: state,
                     name: position.name,
                     x: position.position.x,
                     y_from: 20,
                     y_to: position.position.y,
                     z: position.position.z,
                     scale: position.scale,
                     rotation: position.rotation,
                  });
               }
            }, (index + 1) * 100);
         });
      },

      feischtz_city_moon_full() {
         moonsCity.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_city_moon_half() {
         moonsHalfCity.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_city_moon_new() {
         // ToDo
         console.log("feischtz_city_moon_new");
      },

      feischtz_city_people_1() {
         const avatars1 = avatarsCity.slice(0, 1);
         avatars1.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_city_people_2() {
         const avatars1 = avatarsCity.slice(0, 2);
         avatars1.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_city_people_3() {
         avatarsCity.forEach((position, index) => {
            setTimeout(() => {
               throwClone({
                  state: state,
                  name: position.name,
                  x: position.position.x,
                  y_from: 20,
                  y_to: position.position.y,
                  z: position.position.z,
                  scale: position.scale,
                  rotation: position.rotation,
               });
            }, (index + 1) * 100);
         });
      },

      feischtz_city_people_4() {
         // ToDo
         console.log("feischtz_city_people_4");
      },
   };
};
