import "./styles/main.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Room from "./components/Rooms/Room";
import Student from "./components/Student/Student";
import Rooms from "./components/Rooms/Rooms";
import Gallery from "./components/Gallery/Gallery";
import Videos from "./components/Videos/Videos";
import Gate from "./components/Gate/Gate";
import RoomCreate from "./components/Rooms/RoomCreate";
import Page from "./components/Page/Page";

function App() {
   return (
      <BrowserRouter>
         <Routes>
            <Route path="/" element={<Gate />} />
            <Route path="/nurintern" element={<Rooms />} />
            <Route path="/teacher" element={<RoomCreate />} />
            <Route path="/room/:roomId/" element={<Room />} />
            <Route path="/student/:roomId?/" element={<Student />} />
            <Route path="/gallery/:roomId?/" element={<Gallery />} />
            <Route path="/videos/:roomId?/" element={<Videos />} />
            <Route path="/page/:slug?/" element={<Page />} />
         </Routes>
         {/* <Consent /> */}
      </BrowserRouter>
   );
}

export default App;
