import { useState } from "react";
import { addKeyword } from "../../services/servicesCrud";
import { EnumTarget } from "../../types/types";

export default function KeywordFormAdd({ target }: { target: EnumTarget }) {
   const [keyword, setKeyword] = useState("");

   const handleSubmit = (e: any) => {
      e.preventDefault();
      console.log(e);
      addKeyword(target, keyword).then((room: any) => {
         setKeyword("");
      });
   };
   return (
      <form onSubmit={handleSubmit}>
         <input
            type="text"
            name="name"
            id="name"
            className="me-2 w-100 mb-3"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="zb: Spannend"
         />
         <input
            type="submit"
            value="Keyword hinzufügen"
            className="btn btn--1 w-100 bg-primary"
         />
      </form>
   );
}
