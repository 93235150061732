import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getDocument } from "../../services/servicesRead";
import { subscribeToRooms } from "../../services/subscriptions";
import ProgressBar from "../UI/ProgressBar";
import PageServiceNav from "../Page/PageServiceNav";
import { auth } from "../../firebase/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

interface RoomProps {
   name?: string;
   roomId?: string;
}

export default function Room({ name, roomId }: RoomProps) {
   const [students, setStudents] = useState([]);
   const roomIdFromProps = useParams().roomId;
   const [room, setRoom] = useState<any>(null);

   useEffect(() => {
      if (name && roomId) {
         setRoom({
            name: name,
            id: roomId,
         });
      }

      if (roomIdFromProps) {
         console.log(roomIdFromProps);
         getDocument({ collectionName: "Rooms", docId: roomIdFromProps }).then(
            (data: any) => {
               setRoom({
                  ...data,
                  id: roomIdFromProps,
               });
            }
         );
      }
   }, []);

   useEffect(() => {
      console.log("room:", room);
      if (room && room.id)
         subscribeToRooms({
            roomId: room.id,
            callback: setStudents,
         });
   }, [room]);

   return (
      <div className="bg-gradient pt-5 pb-5 mh-100vh">
         <div className="container">
            <div className="text-white">
               <p className="hsmall mb-0">Klassenraum</p>
               <h1 className="h1 mb-2 text-uppercase">
                  {room?.name} <br />
               </h1>
               <p>KlassenraumID: {room?.id}</p>
            </div>
            <div className="bg-greyLightBG p-4 border-rounded mb-5 bg-transparent">
               <div className="row">
                  <div className="col-md-3">
                     <Link
                        to={`/student/${room?.id}`}
                        className="btn btn--1 bg-purple w-100"
                     >
                        Link für die Schüler
                     </Link>

                     <Link
                        to={`/gallery/${room?.id}`}
                        className="btn btn--1 bg-primary w-100"
                     >
                        Galerie
                     </Link>

                     <Link
                        to={`/videos/${room?.id}`}
                        className="btn btn--1 bg-primary w-100"
                     >
                        Backstage Videos
                     </Link>
                  </div>
                  <div className="col-md-8 col-xl-7 mx-auto">
                     <h2 className="h4">
                        Wichtige Information für Lehrpersonal:
                     </h2>
                     <p>
                        Wir speichern die Einzelergebnisse für einen Zeitraum
                        von 3 Wochen anonym ab. Diese sind frei zugänglich
                        online abrufbar. Nach den 3 Wochen werden alle Inhalte
                        gelöscht. Bitte speichern Sie sich den Zugangslink sowie
                        den Link zur Gallery sicher ab, wenn Sie innerhalb
                        dieser Zeit noch einmal darauf zugreifen möchten.
                     </p>
                  </div>
               </div>
            </div>

            <h2 className="h4 text-uppercase text-white">
               Fortschritt Preview in Echtzeit
            </h2>
            <div className="bg-greyLightBG p-4 border-rounded mb-5 bg-transparent">
               {students.length === 0 && (
                  <div className="bg-greyLightBG p-5 border-rounded">
                     Es haben sich noch keine Schüler angemeldet
                  </div>
               )}
               {students.map((student: any) => (
                  <div className="mb-4">
                     <div className="row">
                        <div className="col-3">{student.name}</div>
                        <div className="col-9">
                           <ProgressBar progress={student.progress} />
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <PageServiceNav />
      </div>
   );
}
