import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";
import ArienConfigurator from "../../ArienConfigurator/ArienConfigurator";

export default function Slide17ArieQuestion1() {
   // transition effect
   //--------------------------------------------------------
   const [inProp, setInProp] = useState(false);
   const nodeRef = useRef(null);

   useEffect(() => {
      setInProp(true);
   }, []);

   return (
      <CSSTransition
         in={inProp}
         nodeRef={nodeRef}
         timeout={500}
         classNames="item"
         unmountOnExit
      >
         <div ref={nodeRef}>
            <ArienConfigurator index={1} />
         </div>
      </CSSTransition>
   );
}
