import { Link } from "react-router-dom";
import IconLogo from "../Icons/IconLogo";
import PageServiceNav from "../Page/PageServiceNav";
import KOB from "../../images/logos/KOB.png";
import KSB from "../../images/logos/KSB.png";
import BKM from "../../images/logos/BKM.png";
import BEEN from "../../images/logos/BEEN.png";

const Gate = () => {
   return (
      <div className="bg-gradient pt-5 pb-5 mh-100vh d-flex flex-column align-items-center justify-content-center">
         <div className="logo">
            <div className="container">
               <IconLogo color={"#fff"} />
            </div>
         </div>
         <div className="container mt-auto">
            <div className="row">
               <div className="col-12 col-md-10 col-lg-8 m-auto">
                  <div className="row">
                     <div className="col-md-6 mx-auto">
                        <p>
                           Willkommen zur Magic Black Box! Wir empfehlen euch,
                           Kopfhörer und eine aktualisierte Browser-Version zu
                           verwenden. Ihr könnt alleine oder als Gruppe spielen.{" "}
                           <br />
                           Either way: let’s play!
                        </p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-6">
                        <Link to="/student" className="btn btn--1 w-100">
                           Einzelperson
                        </Link>
                     </div>
                     <div className="col-6">
                        <Link to="/teacher" className="btn btn--1 w-100">
                           Schulklasse
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="logos mt-auto mb-3 container">
            <div className="row">
               <div className="col-lg-4">
                  <p className="hsmall">
                     Die MAGIC BLACK BOX wurde im Rahmen des Verbundprojektes
                     „SPIELRÄUME! Auf der Suche nach digitale Erlebniswelten“
                     zwischen der Komischen Oper Berlin und dem Berliner
                     Ensemble produziert.
                  </p>
                  <div className="row">
                     <div className="col-4 ms-auto d-flex align-items-center">
                        <img src={BEEN} alt="" />
                     </div>
                     <div className="col-4 d-flex align-items-center me-auto">
                        <img src={KOB} alt="" />
                     </div>
                  </div>
               </div>

               <div className="col-lg-4">
                  <p className="hsmall">
                     Gefördert im Programm Kultur Digital der Kulturstiftung des
                     Bundes.
                  </p>
                  <div className="row">
                     <div className="col-4 m-auto">
                        <img src={KSB} alt="" />
                     </div>
                  </div>
               </div>
               <div className="col-lg-4">
                  <p className="hsmall">
                     Gefördert von der Beauftragten der Bundesregierung für
                     Kultur und Medien.
                  </p>
                  <div className="row">
                     <div className="col-6 m-auto">
                        <img src={BKM} alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <PageServiceNav />
      </div>
   );
};

export default Gate;
