import { Link, useParams } from "react-router-dom";

export default function StickyNavigation() {
   const roomId = useParams().roomId;
   return (
      <div className="stickyNav py-3">
         <div className="container d-flex">
            <Link
               to={roomId ? `/videos/${roomId}` : "/videos"}
               className="ms-auto"
            >
               <span className="btn btn--3 bg-primary me-2 d-block">
                  Backstage Videos
               </span>
            </Link>
            <Link
               to={roomId ? `/gallery/${roomId}` : "/gallery"}
               className="ms-2"
            >
               <span className="btn btn--3 bg-primary d-block">Gallery</span>
            </Link>
         </div>
      </div>
   );
}
