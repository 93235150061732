import { EnumCameraZoom } from "../../../types/types";
import IconCamera from "../../Icons/IconCamera";
import IconMinus from "../../Icons/IconMinus";
import IconPlus from "../../Icons/IconPlus";

interface Props {
   onHandleCameraZoom: (zoom: EnumCameraZoom) => void;
}

export default function CameraSettingsUI({ onHandleCameraZoom }: Props) {
   return (
      <div className="slideEditor__cameraSettings">
         <button
            className="btn btn--icon mb-2"
            onClick={() => onHandleCameraZoom(EnumCameraZoom.zoomIN)}
         >
            <IconPlus />
         </button>
         <button className="btn btn--icon mb-2 p-1">
            <IconCamera />
         </button>

         <button
            className="btn btn--icon"
            onClick={() => onHandleCameraZoom(EnumCameraZoom.zoomOUT)}
         >
            <IconMinus />
         </button>
      </div>
   );
}
