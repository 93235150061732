import * as THREE from "three";

export const isGroupDraggable = (object) => {
   // console.log("isGroupDraggable: ", object.userData);
   if (object.userData.draggable) {
      return true;
   }
   if (!object.parent || object.parent.type === "Scene") return false;
   else return isGroupDraggable(object.parent);
};

export const loader = (enable = true) => {
   if (enable) state.wrapper.classList.add("isLoading");
   else state.wrapper.classList.remove("isLoading");
};

export const createColor = (color) => {
   return new THREE.Color(color);
};

export const slugify = (str) =>
   str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "_")
      .replace(/^-+|-+$/g, "");

export const randomIntFromInterval = (min, max) => {
   return Math.floor(Math.random() * (max - min + 1) + min);
};
