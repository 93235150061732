{
  "sheetsById": {
    "Animated scene": {
      "staticOverrides": {
        "byObject": {
          "Torus Knot": {
            "rotation": {
              "x": -100,
              "y": 0.9367088607594941,
              "z": 0.025316455696201858
            }
          },
          "Camera": {
            "position": {
              "x": 24.99999999999999,
              "y": 48.06329113924052,
              "z": 74.68354430379748
            }
          }
        }
      },
      "sequence": {
        "subUnitsPerUnit": 30,
        "length": 10,
        "type": "PositionalSequence",
        "tracksByObject": {
          "Camera": {
            "trackData": {
              "8wxIfn1Rgo": {
                "type": "BasicKeyframedTrack",
                "__debugName": "Camera:[\"position\",\"x\"]",
                "keyframes": [
                  {
                    "id": "L_90P8s91r",
                    "position": 0,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 0
                  },
                  {
                    "id": "uLcVbvjfZA",
                    "position": 2.167,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 40.20253164556961
                  }
                ]
              },
              "p2tRQO1-GV": {
                "type": "BasicKeyframedTrack",
                "__debugName": "Camera:[\"position\",\"y\"]",
                "keyframes": [
                  {
                    "id": "FrQP6dA25_",
                    "position": 0,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 13.797468354430377
                  },
                  {
                    "id": "jYHY-6WQoU",
                    "position": 2.167,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 25
                  }
                ]
              },
              "0OLtvzY00U": {
                "type": "BasicKeyframedTrack",
                "__debugName": "Camera:[\"position\",\"z\"]",
                "keyframes": [
                  {
                    "id": "3lAbxNKWn0",
                    "position": 0,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 27
                  },
                  {
                    "id": "m00SyTfwwY",
                    "position": 2.167,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 40
                  }
                ]
              }
            },
            "trackIdByPropPath": {
              "[\"position\",\"x\"]": "8wxIfn1Rgo",
              "[\"position\",\"y\"]": "p2tRQO1-GV",
              "[\"position\",\"z\"]": "0OLtvzY00U"
            }
          }
        }
      }
    },
    "Camera Animation": {
      "staticOverrides": {
        "byObject": {
          "Camera": {
            "position": {
              "y": 14.468354430379748,
              "z": 35.860759493670884
            }
          }
        }
      }
    },
    "Camera Zoom": {
      "staticOverrides": {
        "byObject": {
          "Camera": {
            "position": {
              "x": -18.987341772151872,
              "y": 1.8101265822784844
            }
          }
        }
      },
      "sequence": {
        "subUnitsPerUnit": 30,
        "length": 10,
        "type": "PositionalSequence",
        "tracksByObject": {
          "Camera": {
            "trackData": {
              "qYki5ot2ra": {
                "type": "BasicKeyframedTrack",
                "__debugName": "Camera:[\"position\",\"x\"]",
                "keyframes": [
                  {
                    "id": "BX9GkAZEcH",
                    "position": 0,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 0
                  },
                  {
                    "id": "oJsI0vxol0",
                    "position": 5,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 40
                  }
                ]
              },
              "rvv6fPgSVZ": {
                "type": "BasicKeyframedTrack",
                "__debugName": "Camera:[\"position\",\"y\"]",
                "keyframes": [
                  {
                    "id": "NwA2QMIraQ",
                    "position": 0,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 15
                  },
                  {
                    "id": "lyrGqpbW_c",
                    "position": 5,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 23
                  }
                ]
              },
              "_DOiYX4hgb": {
                "type": "BasicKeyframedTrack",
                "__debugName": "Camera:[\"position\",\"z\"]",
                "keyframes": [
                  {
                    "id": "9-1V0hShhs",
                    "position": 0,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 30
                  },
                  {
                    "id": "pNrNek6uP0",
                    "position": 5,
                    "connectedRight": true,
                    "handles": [
                      0.5,
                      1,
                      0.5,
                      0
                    ],
                    "value": 50
                  }
                ]
              }
            },
            "trackIdByPropPath": {
              "[\"position\",\"x\"]": "qYki5ot2ra",
              "[\"position\",\"y\"]": "rvv6fPgSVZ",
              "[\"position\",\"z\"]": "_DOiYX4hgb"
            }
          }
        }
      }
    }
  },
  "definitionVersion": "0.4.0",
  "revisionHistory": [
    "lr-zS8pj5yGVQ0z8",
    "_FqQ_T3qi1c0q07Y",
    "GAIPM-I9Yk9fHTLl",
    "Z4RxkJTOR1E1GYnv",
    "sSDPTeacIT4a0sAs"
  ]
}