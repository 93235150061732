import { ReactElement } from "react";

export interface AppStore {
   superUser: any;
   questions: String[];
   rooms: RoomInterface[];
   keywords: KeywordInterface[];
   keywordsOper: KeywordInterface[];
   keywordsVisited: KeywordInterface[];
   setRooms: (rooms: RoomInterface[]) => void;
   setQuestions: (questions: String[]) => void;
   setKeywords: (keywords: KeywordInterface[]) => void;
   setKeywordsOper: (keywords: KeywordInterface[]) => void;
   setKeywordsVisited: (keywords: KeywordInterface[]) => void;
   setSuperUser: (user: any) => void;
}

export interface StudentStore {
   animation: InterfaceAnimation;
   setAnimationState: (state: EnumAnimationState) => void;
   setAnimationVolume: (volume: number) => void;
   beStage: boolean;
   setBeStage: (show: boolean) => void;
   beStageMenu: boolean;
   setBeStageMenu: (show: boolean) => void;
   assets: InterfaceAssets;
   setAssets: (assets: any[]) => void;
   fundus: InterfaceFundus | null;
   setFundus: (fundus: InterfaceFundus | null) => void;
   student: StudentInterface | null;
   setStudent: (student: StudentInterface) => void;
   chosenScene: InterfaceScene | null;
   setChosenScene: (scene: InterfaceScene) => void;
   sliderIndex: number;
   setSliderIndex: (index: number) => void;
   incrementSliderIndex: () => void;
   decrementSliderIndex: () => void;
   consent: boolean;
   setConsent: (consent: boolean) => void;
   isModalActive: boolean;
   setIsModalActive: (state: boolean) => void;
   modalsInEditor: {
      description: boolean;
      objects3d: boolean;
      final: boolean;
   };
   setModalsInEditor: (state: any) => void;
}
export interface TeacherStore {
   room: RoomInterface | null;
   setRoom: (room: RoomInterface) => void;
}

export interface RoomInterface {
   id: string;
   name: string;
}

export interface InterfaceFundus {
   where?: EnumFundusWhere | null;
   when?: number;
   moon?: EnumFundusMoon | null;
   mood?: String | null;
   people?: number | null;
   special?: EnumFundusSpecial | null;
   camera?: EnumFundusCamera | null;
   costume?: EnumFundusCostume | null;
   light?: any[];
}

export interface KeywordInterface {
   name: string;
   count: number;
   id: string;
}

export interface StudentInterface {
   id: string;
   name: string;
   questions: InterfaceQuestion[];
   publish_with_name: boolean | null;
   result: string;
   progress: any;
}
export interface InterfaceScene {
   title: string;
   meta_title: string;
   type: string;
   audioPreview: any;
   audioFull: any;
   author: string;
   image: any;
   background: string;
   color: string;
   description: string;
   subtitles: string;
   meta: string;
}

export interface InterfaceQuestion {
   answer: string;
   question: string;
   questionIndex: number;
}

export interface InterfaceAssets {
   clones: any[];
   lights: any[];
   objects: IAssetsObjects[];
   effects: string;
   textures: any[];
}

export interface IAssetsObjects {
   label?: string;
   name?: string;
   thumbnail?: string;
   uuid?: string;
}

export interface InterfaceAnimation {
   state: EnumAnimationState;
   audioVolume: number;
}

// Enums
//--------------------------------------------------------

export enum EnumAnimationState {
   default = "DEFAULT",
   explosion = "EXPLOSION",
   implosion = "IMPLOSION",
   end = "END",
}

export enum EnumTarget {
   theather = "Keywords-Theather",
   oper = "Keywords-Oper",
   visited = "Keywords-Visited",
}

export enum EnumFundusWhere {
   nature = "nature",
   phantasy = "phantasy",
   city = "city",
}

export enum EnumFundusMoon {
   full = "moon_full",
   half = "moon_half",
   new = "moon_new",
   none = "moon_none",
}

export enum EnumFundusCostume {
   astronaut = "Astronaut",
   cook = "Cook",
   dancer = "Dancer",
}

export enum EnumFundusSpecial {
   none = "none",
   fog = "fog",
   snow = "snow",
}

export enum EnumFundusCamera {
   zoomOut = "Zoom-Out",
   zoomIn = "Zoom-In",
}

export enum EnumDirection {
   left = "left",
   right = "right",
   up = "top",
   down = "down",
   front = "front",
   back = "back",
}

export enum EnumScale {
   up = "up",
   down = "down",
}

export enum EnumCameraZoom {
   zoomIN = "Zoom-In",
   zoomOUT = "Zoom-Out",
   zoomRESET = "Zoom-Reset",
}

//Sidebar Accordion Data
export interface User {
   name: string;
   mediaItemUrl?: string;
}

export interface List {
   title: string;
   userData: User[];
   isOpen: boolean;
}

export interface InitalSidebarData {
   items: List[];
}

export interface Stage {
   scene: string;
   place: string;
   moon?: string | null;
   people?: number;
}

export interface IAccordionObjects {
   title: string;
   content?: IAssetsObjects[];
   isOpen: boolean;
}

export interface IAccordionEffects {
   title: string;
   content?: string;
   isOpen: boolean;
}
