import * as THREE from "three";
import { GUI } from "dat.gui";

export const createFog = (state, enable) => {
   if (enable) state.scene.fog = new THREE.Fog("0xFFFFFF", -59, 253);
   else state.scene.fog = null;

   // const gui = new GUI();
   // gui.add(state.scene.fog, "near", -100, 100);
   // gui.add(state.scene.fog, "far", -1000, 1000);
};
