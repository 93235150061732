import scene_preview_wir from "../../images/scenes/preview/placeholder.gif";
import wir_ihr_gif from "../../images/scenes/preview/230524_mbb_animatedgif_WirIhr_neon_w_hwmd.gif";
import lovesong_gif from "../../images/scenes/preview/230525_mbb_animatedgif_lovesong_feuer_hwmd.gif";
import lacage_gif from "../../images/scenes/preview/230524_mbb_animatedgif_LaCage_pfau_hwmd.gif";
import freischutz_gif from "../../images/scenes/preview/230523_mbb_animatedgif_freischuetz_mond_hwmd.gif";
import lenz_gif from "../../images/scenes/preview/230523_mbb_animatedgif_Lenz_blume_02_hwmd.gif";
import dongiovani_gif from "../../images/scenes/preview/230530_mbb_animatedgif_emoji_v2_hwmd.gif";

import wir_ihr_preview from "../../audio/scenes/preview/6_WIR_Master2_ FADE.mp3";
import wir_ihr from "../../audio/scenes/full/WIR_IHR-CUT.mp3";
import love_song_preview from "../../audio/scenes/preview/4_Love Song Master2_FADE.mp3";
import love_song from "../../audio/scenes/full/3_LoveSong_Master2.mp3";
import lenz_preview from "../../audio/scenes/preview/2_Lenz Master2_FADE.mp3";
import lenz from "../../audio/scenes/full/1_Lenz_Master2.mp3";
import freischuetz_preview from "../../audio/scenes/preview/Freischuetz_FADE.mp3";
import freischuetz from "../../audio/scenes/full/Freischuetz_CUT.mp3";
import don_giovanni_preview from "../../audio/scenes/preview/Don_Giovanni_FADE.mp3";
import don_giovanni from "../../audio/scenes/full/Don_Giovanni_CUT.mp3";
import cage_auf_folles_preview from "../../audio/scenes/preview/Ich_bin_was_ich_bin_FADE.mp3";
import cage_auf_folles from "../../audio/scenes/full/Ich_bin_was_ich_bin_CUT.mp3";
import { InterfaceScene } from "../../types/types";

export const scenes: InterfaceScene[] = [
   {
      type: "(Text)",
      title: "Wir Ihr",
      meta_title: "wir_ihr",
      audioPreview: wir_ihr_preview,
      audioFull: wir_ihr,
      author: "Antje Schupp",
      meta: "Antje Schupp",
      image: wir_ihr_gif,
      background: "bg-gradient-purple",
      color: "purple",
      description:
         "Wer ist eigentlich dieses ominöse WIR, von dem immer die Rede ist? In der Politik, im Fernsehen, in Talk Shows? „Wir müssen zusammenstehen“. „Wir müssen den Gürtel enger schnallen.“ „Wir müssen aufwachen.“ Es scheint sonnenklar zu sein, wer gemeint ist. Aber ist das klar? „Wir“ sind hier, und die anderen, das sind „ihr“. Aber sind „wir“ und „ihr“ bzw. „die da“ wirklich so unterschiedlich?",
      subtitles: "",
   },
   {
      type: "(Text)",
      title: "This is not a love song",
      meta_title: "love_song",
      audioPreview: love_song_preview,
      audioFull: love_song,
      author: "Antje Schupp",
      meta: "Antje Schupp",
      image: lovesong_gif,
      background: "bg-gradient-grape",
      color: "red",
      description:
         "Hier wurde jemandem das Herz gebrochen. Jemand ist wütend, traurig, verletzt. Hofft noch, aber will das Gefühl der Hoffnung und erst Recht nicht der Liebe mehr zulassen. Das kennen wir alle, dieses Gefühl, oder? Wir sind vor den Kopf gestoßen, wir wollen uns zurückziehen, die Decke über den Kopf ziehen und nichts mehr von der anderen Person hören. Und dieser anderen Person? Der geht es genauso. Und was machen die zwei jetzt?",
      subtitles: "",
   },
   {
      type: "(Musikstück)",
      title: "La Cage aux Folles",
      meta_title: "la_cage",
      audioPreview: cage_auf_folles_preview,
      audioFull: cage_auf_folles,
      author: "Jerry Herman",
      meta: "Jerry Herman",
      image: lacage_gif,
      background: "bg-gradient-red",
      color: "brown",
      description:
         "„I am what I am, I am my own special creation“ - „Ich bin, was ich bin. Ich bin meine eigene, besondere Kreation.“ Wer so etwas von sich sagen kann, muss schon ziemlich stark sein - oder etwa nicht? Oder spricht hier eine Person, die sich selbst bestätigen will, weil andere Menschen sie nicht so akzeptieren, wie sie ist?  In „La Cage aux Folles“ geht es um eine Familie, die vor der Herausforderung steht, ob sich alle als das, was sie sind und wie sie leben, zu erkennen geben, oder ob sie für die liebe Verwandtschaft ein Versteckspiel veranstalten.",
      subtitles:
         '<em>ALBIN</em><br><br>Ich bin, was ich bin.<br>Ich schuf mein ganz eigenes Dasein.<br>Drum kommt, schaut mir zu. <br>Könnt ja dann „buh“ oder „hurra“ schrei’n.<br><br>In meiner Welt will ich etwas Stolz und Würde haben.<br>Meine Welt. Doch ich will mich nicht in ihr vergraben.<br>Man lebt ohne Sinn, bis man dann sagt:<br>Hey Welt! Ich bin, was ich bin.<br><br>Ich bin, was ich bin.<br>Sagt jetzt nicht „toll“, sagt jetzt nicht „tragisch“.<br>Ich stoß’ in mein Horn, vorn’ nennen sie’s "Krach", <br>Ich nenn’ es „magisch“.<br><br>Denn wen juckt, ob ich Federn liebe, Glanz und Diven,<br>Ja, guckt! Aber guckt aus anderen Perspektiven.<br>Lebt falsch vor euch hin, bis ihr auch laut raushaut:<br>Ich bin, was ich bin!<br><br>Ich bin, was ich bin.<br>Kein „tut mir leid“, kein „nur im Rahmen“.<br>Ich spiele mein Blatt,<br>mal nur ne Zwo, mal die Herzdame.<br><br>Lebt einmal, es wird kein Zurück, kein Fangnetz geben.<br>Einmal! Also outet euch und raus ins Leben!<br>Man lebt ohne Sinn, bis man dann sagt:<br>Hey Welt! Ich bin, was ich bin.<br>',
   },
   {
      type: "(Musikstück)",
      title: "Don Giovanni",
      meta_title: "don_giovanni",
      audioPreview: don_giovanni_preview,
      audioFull: don_giovanni,
      author: "Wolfgang Amadeus Mozart",
      meta: "Wolfgang Amadeus Mozart",
      image: dongiovani_gif,
      background: "bg-gradient-green",
      color: "green",
      description:
         "„Don Giovanni“ bedeutet „Don Juan“ und er ist ein Aufreißer. In dieser Oper dreht sich alles um ihn. Es geht um Verführung, Sex und Betrug. Don Giovanni hat einen Diener und Wingman, das ist Leporello. Die zwei sind ein bisschen wie Kumpels, aber nicht auf Augenhöhe. Leporello muss die unliebsame Arbeit erledigen und hält Don Giovanni den Rücken frei, wenn dieser dem Zorn anderer entgehen oder sich amüsieren will.  Für die Frauen eine Achterbahnfahrt. #MeToo. Dem Typ müssen seine Grenzen gezeigt werden, denn die Abrechnung kommt ganz zum Schluss.",
      subtitles:
         "<em>LEPORELLO</em><br><br>Schöne Donna, dies genaue Register<br>Es enthält seine Liebesaffären<br>Der Verfasser des Werks kniet vor Ihnen<br>Wenn Sie wollen, so geh’n wir es durch.<br><br>In Italien sechshundertundvierzig,<br>Da in Deutschland zweihundertunddreißig,<br>Hundert in Frankreich, neunzig in Persien, <br>Aber in Spanien, ja in Spanien schon tausend und drei. <br><br>Hier ein schickes Kammerkätzchen, <br>Dort ein nettes Bürgerschätzchen, <br>Kammerzofen, Baronessen, <br>Hochgeborene Prinzessen, <br><br>Mädchen sind’s von jedem Stande, <br>Jeder Gattung und Gestalt, <br>Schön und hässlich, <br>Jung und alt. <br>",
   },
   {
      type: "(Musikstück)",
      title: "Der Freischütz",
      meta_title: "feischtz",
      audioPreview: freischuetz_preview,
      audioFull: freischuetz,
      author: "Carl Maria von Weber",
      meta: "C.M. von Weber (Musik) / Friedrich Kind (Text)",
      image: freischutz_gif,
      background: "bg-gradient-blueLight",
      color: "blue_ocean",
      description:
         "Wer hätte so etwas nicht gerne: Dinge, die einem genau das erfüllen, was man sich wünscht? Einen Freifahrtschein? Genau so etwas sind die „Freikugeln“ in „Der Freischütz“: die ersten sechs Kugeln treffen alles, was man treffen will, nur muss man dafür einen Pakt mit bösen Geistern eingehen. Und die siebte Kugel, die trifft dann, was die Geister treffen wollen. Es braucht wenig Fantasie um sich vorzustellen: das geht nicht gut aus. Im „Freischütz“ wird es dramatisch, als sich der Jäger Max aus Angst, bei einem Schießwettbewerb zu versagen, mit den falschen Leuten und auf den Deal mit den Freikugeln einlässt.",
      subtitles:
         "VIERTER AUFTRITT</br><em>Kaspar. Unsichtbare Geister von verschiedenen Seiten.</em><br><br>KASPAR</br><em>ohne Hut und Oberkleid, doch mit Jagdtasche und Hirschfänger, ist beschäftigt, mit schwarzen Feldsteinen einen Kreis zu legen, in dessen Mitte ein Totenkopf liegt; einige Schritte davon der abgehauene Adlerflügel, Giesskelle und Kugelform.</em><br><br><br><em>STIMMEN UNSICHTBARER GEISTER</em><br><br>Milch des Mondes fiel aufs Kraut!<br>Uhui! Uhui!<br>Spinnweb' ist mit Blut betaut!<br>Uhui! Uhui!<br>Eh' noch wieder Abend graut -<br>Uhui! Uhui!<br>Ist sie tot, die zarte Braut!<br>Uhui! Uhui!<br>Eh' noch wieder sinkt die Nacht,<br>Ist das Opfer dargebracht!<br>Uhui! Uhui! Uhui!<br>",
   },
   {
      type: "(Text)",
      title: "Lenz",
      meta_title: "lenz",
      audioPreview: lenz_preview,
      audioFull: lenz,
      author: "Georg Büchner",
      meta: "Georg Büchner",
      image: lenz_gif,
      background: "bg-gradient-blue",
      color: "blue",
      description:
         "Lenz ist auf Reisen und muss auf seinem Weg durch die Berge. Autos gibt es noch nicht, er ist zu Fuß unterwegs, bei Wind und Wetter. Und es geht ihm nicht sonderlich gut. Ist er deprimiert? Hat er Angst? Oder ist er betrunken? Man weiß es zunächst nicht ganz genau. Lenz’ Gefühle wechseln zwischen himmelhochjauchzend und zu Tode betrübt. Er sucht Ruhe und ist dabei rastlos. Lenz wandert und beobachtet seine Umwelt genau. Was sieht er?",
      subtitles: "",
   },
];
