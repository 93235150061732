export default function IconMinus() {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         width="12"
         height="2"
         viewBox="0 0 12 2"
      >
         <g
            id="Gruppe_92"
            data-name="Gruppe 92"
            transform="translate(-276.5 -166.5)"
         >
            <line
               id="Linie_2"
               data-name="Linie 2"
               x2="12"
               transform="translate(276.5 167.5)"
               fill="none"
               stroke="#101010"
               strokeWidth="1"
            />
         </g>
      </svg>
   );
}
