import { addSpotLight } from "../helpers/light";
import gsap from "gsap";

export const addListeners = (state) => {
   // const toggleObjects = [
   //    // state.scene.getObjectByName("wand_l"),
   //    // state.scene.getObjectByName("wand_r"),
   //    // state.scene.getObjectByName("decke");
   //    state.scene.getObjectByName("gruppe_buehne_frontal"),
   // ];

   // const wall_rear = state.scene.getObjectByName("wand_hinten");
   // const roof = state.scene.getObjectByName("decke");
   // const wall_l = state.scene.getObjectByName("wand_l");
   // const wall_r = state.scene.getObjectByName("wand_r");
   // const roofInit = { ...roof.position };
   // const wall_lInit = { ...wall_l.position };
   // const wall_rInit = { ...wall_r.position };
   // const wall_rearInit = { ...wall_rear.position };

   // document.querySelector("#edit_mode").onpointerdown = function () {
   //    gsap.to(roof.position, { ...roofInit, y: 50 });
   //    gsap.to(wall_l.position, { ...wall_lInit, x: -50 });
   //    gsap.to(wall_r.position, { ...wall_rInit, x: 50 });
   //    gsap.to(wall_rear.position, { ...wall_rearInit, z: -50 });
   //    toggleObjects.forEach((obj) => {
   //       console.log(obj);
   //       if (obj) {
   //          obj.visible = false;
   //       }
   //    });
   // };

   // document.querySelector("#viewer_mode").onpointerdown = function () {
   //    gsap.to(roof.position, { ...roofInit });
   //    gsap.to(wall_l.position, { ...wall_lInit });
   //    gsap.to(wall_r.position, { ...wall_rInit });
   //    gsap.to(wall_rear.position, { ...wall_rearInit });
   //    toggleObjects.forEach((obj) => {
   //       if (obj) {
   //          obj.visible = true;
   //       }
   //    });
   // };

   // document.querySelector("#spotlight_add").onpointerdown = function () {
   //    addSpotLight();
   // };

   let throttled = false;
   // state.wrapper.addEventListener("resize", function () {
   //    console.log(this.state.wrapper, " resized");
   //    // only run if we're not throttled
   //    if (!throttled) {
   //       // actual callback action
   //       state.camera.aspect =
   //          state.wrapper.offsetWidth / state.wrapper.offsetHeight;
   //       state.camera.updateProjectionMatrix();
   //       state.renderer.setSize(
   //          state.wrapper.offsetWidth,
   //          state.wrapper.offsetHeight
   //       );
   //       // we're throttled!
   //       throttled = true;
   //       // set a timeout to un-throttle
   //       setTimeout(function () {
   //          throttled = false;
   //       }, 500);
   //    }
   // });

   //*******************************************************
   // Resize
   //*******************************************************
   const resize_observe = new ResizeObserver(function (entries) {
      // since we are observing only a single element, so we access the first element in entries array
      let rect = entries[0].contentRect;

      // current width & height
      let width = rect.width;
      let height = rect.height;

      if (!throttled) {
         // actual callback action
         state.camera.aspect = width / height;
         state.camera.updateProjectionMatrix();
         state.renderer.setSize(width, height);
         // we're throttled!
         throttled = true;
         // set a timeout to un-throttle
         setTimeout(function () {
            throttled = false;
         }, 500);
      }
   });

   resize_observe.observe(state.wrapper);
};
