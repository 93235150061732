import { useState } from "react";
import { updateQuestions } from "../../services/servicesCrud";
import { useRoomsStore } from "../../store/appStore";

export default function QuestionForm({ questionIndex, question }: any) {
   const questions = useRoomsStore((state) => state.questions);
   const [questionLocal, setQuestionLocal] = useState(question);
   const [edit, setEdit] = useState(false);

   const handleSubmit = (e: any) => {
      e.preventDefault();
      const newQuestions = [...questions];
      newQuestions[questionIndex] = questionLocal;
      updateQuestions({
         feedback: newQuestions,
      });
      setEdit(false);
   };
   return (
      <div>
         <div className={edit ? "d-none" : "d-block"}>
            <div className="row mb-3">
               <div className="col-8">
                  <h2 className="h4">
                     {questionIndex} : {question}
                  </h2>
               </div>
               <div className="col-4">
                  {/* <button
                     onClick={() => setEdit(true)}
                     className="btn btn--2 bg-primary"
                  >
                     bearbeiten
                  </button> */}
               </div>
            </div>
         </div>
         <div className={edit ? "d-block" : "d-none"}>
            <div className="row">
               <div className="col-8">
                  <input
                     type="text"
                     name="name"
                     id="name"
                     className="me-2 w-100 mb-3"
                     value={questionLocal}
                     onChange={(e) => setQuestionLocal(e.target.value)}
                  />
               </div>
               <div className="col-4">
                  <input
                     type="submit"
                     value="Speichern"
                     className="btn btn--2 bg-primary me-2"
                     onClick={handleSubmit}
                  />
                  <button
                     onClick={() => setEdit(false)}
                     type="button"
                     className="btn btn--2 btn--transparent"
                  >
                     abbrechen
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
